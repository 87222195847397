import Stack from '@mui/material/Stack';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Card, CardMedia, FormHelperText, IconButton } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useImageUploadMutation } from '../../../services/imageUpload';
import TextInput from './TextInput';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import { useFormContext } from 'react-hook-form';
import useIsMobile from '../../../utils/customHooks/useIsMobile';

interface IProps {
  limit: number;
  name: string;
  labelName: string;
  showLabel: boolean;
  showLabelTextInput: boolean;
  edit?: string;
}

const ImageUploadV: React.FC<IProps> = ({
  limit,
  name,
  labelName,
  showLabel,
  showLabelTextInput,
  edit,
}) => {
  const isMobile = useIsMobile();

  const [uploadImage, { data, isLoading, isSuccess, isError, error }] =
    useImageUploadMutation();

  const { setValue, clearErrors } = useFormContext();

  const [singleFile, setSingleFile] = useState<File[]>([]);
  const [sizeError, setSizeError] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const onDragEnter = () => wrapperRef.current?.classList.add('dragover');
  const onDragLeave = () => wrapperRef.current?.classList.remove('dragover');

  const onFileDrop = useCallback(
    (e: React.SyntheticEvent<EventTarget>) => {
      const target = e.target as HTMLInputElement;
      if (!target.files) return;

      if (limit === 1) {
        const newFile = Object.values(target.files).map((file: File) => file);
        if (singleFile.length >= 1) return alert('Only a single image allowed');
        const updatedSingleList = [...singleFile, ...newFile];
        if (newFile[0].size > 5242880) {
          setSizeError(true);
        } else {
          setSizeError(false);
          setSingleFile(updatedSingleList);
        }
      }
    },
    [limit, singleFile],
  );

  useEffect(() => {
    if (singleFile.length > 0) {
      const formData = new FormData();
      formData.append('image_data', singleFile[0]);
      uploadImage(formData);
    }
  }, [singleFile]);

  useEffect(() => {
    if (data) {
      if (typeof data !== 'string') {
        const updatedList = [...singleFile];
        updatedList.splice(singleFile.indexOf(singleFile[0]), 1);
        setSingleFile(updatedList);
        setValue(name, '');
      } else {
        if (
          data.match(/^http[^?]*.(jpg|jpeg|gif|png|tiff|bmp)(\?(.*))?$/gim) !==
          null
        ) {
          setValue(name, data);
          clearErrors(name);
        }
      }
    }
  }, [isSuccess, isError]);

  const fileSingleRemove = (file: File) => {
    const updatedList = [...singleFile];
    updatedList.splice(singleFile.indexOf(file), 1);
    setSingleFile(updatedList);
    setValue(name, '');
  };

  const calcSize = (size: number) => {
    return size < 1000000
      ? `${Math.floor(size / 1000)} KB`
      : `${Math.floor(size / 1000000)} MB`;
  };

  return (
    <Box>
      <Stack flexDirection={'row'} flexWrap={'wrap'} spacing={1}>
        <Typography sx={{ width: '100%' }}>{showLabel && labelName}</Typography>
        {singleFile.length > 0 ? (
          <Card sx={{ display: 'flex', width: '50%' }} elevation={3}>
            <CardMedia
              component="img"
              sx={{ width: '40%', maxWidth: '40%' }}
              image={URL.createObjectURL(singleFile[0])}
              alt="image"
            />
            <CardContent sx={{ width: '45%' }}>
              <Typography
                gutterBottom
                noWrap={true}
                variant={isMobile ? 'body2' : 'body1'}
                component="div"
              >
                {singleFile[0].name}
              </Typography>
              <Typography variant="caption">
                {calcSize(singleFile[0].size)}
              </Typography>
            </CardContent>
            <CardActions sx={{ alignItems: 'flex-start', ml: 'auto' }}>
              <IconButton
                onClick={() => {
                  fileSingleRemove(singleFile[0]);
                }}
                size={'small'}
                color={'error'}
              >
                <HighlightOffIcon />
              </IconButton>
            </CardActions>
          </Card>
        ) : (
          <>
            <Box
              sx={{
                backgroundColor: '#D5D5D5',
                position: 'relative',
                padding: '20px 10px',
                width: '300px',
                border: '1px solid #707070',
              }}
              ref={wrapperRef}
              onDragEnter={onDragEnter}
              onDragLeave={onDragLeave}
              onDrop={onDragLeave}
            >
              <Typography variant="body2" component="span">
                ファイルをドロップして
                <br />
                アップロード。
                <br />
                アップロード完了後URLと画像を表示
              </Typography>
              <input
                type="file"
                onChange={onFileDrop}
                accept="image/jpg, image/png, image/jpeg"
                style={{
                  opacity: 0,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  cursor: 'pointer',
                }}
              />
            </Box>
            {sizeError && (
              <FormHelperText sx={{ width: '100%' }} error={sizeError}>
                10MB以下の画像をアップロードしてください。
              </FormHelperText>
            )}
          </>
        )}
      </Stack>
      <Box mt={'-10px'}>
        <TextInput
          inputProps={{ readOnly: true }}
          defaultValue={edit}
          name={name}
          labelName={labelName}
          showLabel={showLabelTextInput}
        />
      </Box>
    </Box>
  );
};

export default ImageUploadV;
