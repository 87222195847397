import React from 'react';
import { DialogActions, DialogContent } from '@mui/material';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import TextInput from '../../components/Form/TextInput';
import { useCommonSlice } from '../../../store/commonSlice';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCreatePointRuleMutation } from 'services/pointRuleApi';
import useMessage from '../../../utils/customHooks/useMessage';

export const validatePointRule = yup.object().shape({
  title: yup
    .string()
    .max(255, 'タイトルが無効です')
    .required('タイトルが必須です'),
  point_amount: yup
    .number()
    .typeError('ポイント数が無効です')
    .min(0, 'ポイント数が無効です')
    .required('ポイント数が必須です'),
  rebonus_terms: yup
    .number()
    .typeError('再配布間隔が無効です')
    .min(0, '再配布間隔が無効です')
    .nullable()
    .transform((_, val) => (val !== '' ? Number(val) : null)),
  limit_point_one_user_per_hour: yup
    .number()
    .typeError('最大付与ポイント（1ユーザー）が無効です')
    .min(0, '最大付与ポイント（1ユーザー）が無効です')
    .nullable()
    .transform((_, val) => (val !== '' ? Number(val) : null)),
  limit_point_all_user_per_hour: yup
    .number()
    .typeError('付与上限ポイント（全ユーザー）が無効です')
    .min(0, '付与上限ポイント（全ユーザー）が無効です')
    .nullable()
    .transform((_, val) => (val !== '' ? Number(val) : null)),
});

const AddForm = () => {
  const { actions } = useCommonSlice();
  const dispatch = useDispatch();
  const methods = useForm<any>({ resolver: yupResolver(validatePointRule) });
  const [createPointRule, { isLoading, isSuccess, isError, error, status }] =
    useCreatePointRuleMutation();

  useMessage(isLoading, isError, isSuccess, error, true);

  const onSubmit: SubmitHandler<any> = data => {
    if (data.rebonus_terms === null) {
      delete data.rebonus_terms;
    }
    if (data.limit_point_one_user_per_hour === null) {
      delete data.limit_point_one_user_per_hour;
    }
    if (data.limit_point_all_user_per_hour === null) {
      delete data.limit_point_all_user_per_hour;
    }
    createPointRule(JSON.stringify(data));
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <DialogContent dividers className="dialogContentModal">
          <TextInput name={'title'} labelName={'ポイントルール名'} showLabel />
          <TextInput
            name={'point_amount'}
            labelName={'付与ポイント数'}
            showLabel
            type={'number'}
            inputProps={{ min: 0 }}
          />
          <TextInput
            name={'rebonus_terms'}
            labelName={'再配布間隔（分）'}
            showLabel
            type={'number'}
            inputProps={{ min: 0 }}
          />
          <TextInput
            name={'limit_point_one_user_per_hour'}
            labelName={'1ユーザー、1時間あたりの最大付与ポイント数'}
            showLabel
            type={'number'}
            inputProps={{ min: 0 }}
          />
          <TextInput
            name={'limit_point_all_user_per_hour'}
            labelName={'全ユーザー、1時間あたりの最大付与ポイント数'}
            showLabel
            type={'number'}
            inputProps={{ min: 0 }}
          />
        </DialogContent>
        <DialogActions className="dialogActionModal">
          <Button
            autoFocus
            variant={'text'}
            onClick={() => dispatch(actions.closeModal())}
            className="btnModalCancel"
          >
            Cancel
          </Button>
          <Button variant={'text'} type={'submit'} className="btnModalSubmit">
            ADD
          </Button>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export default AddForm;
