import { TreeItem } from '@mui/lab';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const GetTreeItemsFromData = treeItems => {
  const route = useHistory();

  return treeItems.map(treeItemData => {
    let children = undefined;
    if (treeItemData.children && treeItemData.children.length > 0) {
      children = GetTreeItemsFromData(treeItemData.children);
    }
    return (
      <TreeItem
        onClick={
          treeItemData.route ? () => route.push(treeItemData.route) : undefined
        }
        key={treeItemData.id}
        nodeId={treeItemData.id}
        label={treeItemData.name}
        children={children}
        sx={{
          '& > div': {
            flexDirection: 'row-reverse',
          },
        }}
      />
    );
  });
};
