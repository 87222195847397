import { DataTemplate } from '../../../utils/types/dataTemplate';
import * as React from 'react';
import { Link } from '@mui/material';
import uuid from 'react-uuid/uuid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const SurveyRuleSample: DataTemplate[] = [
  {
    field: 'forms_id',
    headerName: 'ID',
    minWidth: { md: 100, xs: '35vw' },
    flex: 2,
    type: 'id',
    filterable: false,
  },
  {
    field: 'forms_title',
    headerName: 'タイトル',
    minWidth: { md: 150, xs: '40vw' },
    flex: 2,
    type: 'string',
    filterName: 'フォーム名',
    filterField: 'title',
  },
  {
    field: 'forms_top_image',
    headerName: 'フォームトップ画像',
    minWidth: { md: 200, xs: '55vw' },
    flex: 2,
    type: 'img',
    filterable: false,
  },
  {
    field: 'forms_bottom_image',
    headerName: 'フォームボトム画像',
    minWidth: { md: 250, xs: '65vw' },
    flex: 1,
    type: 'img',
    filterable: false,
  },
  {
    field: 'event',
    headerName: '配信開始日時',
    minWidth: { md: 200, xs: '55vw' },
    flex: 1,
    type: 'custom',
    filterDate: true,
    filterable: false,
    sort: true,
    sortName: 'events.period_start',
    renderProps: props => {
      return (
        props &&
        Array.isArray(props) &&
        props.map(item => (
          <Box key={uuid()}>
            <Typography>{item.events_period_start}</Typography>
          </Box>
        ))
      );
    },
  },
  {
    field: 'event',
    headerName: '配信終了日時',
    minWidth: { md: 200, xs: '55vw' },
    flex: 1,
    type: 'custom',
    filterable: false,
    sort: true,
    sortName: 'events.period_end',
    renderProps: props => {
      return (
        props &&
        Array.isArray(props) &&
        props.map(item => (
          <Box key={uuid()}>
            <Typography>{item.events_period_end}</Typography>
          </Box>
        ))
      );
    },
  },
  {
    field: 'event',
    headerName: 'イベントID',
    minWidth: { md: 180, xs: '45vw' },
    flex: 1,
    type: 'custom',
    renderProps: props => {
      return (
        props &&
        Array.isArray(props) &&
        props.map(item => (
          <Box key={uuid()}>
            <Link
              href={`/event?event_id=${item.events_id}`}
              sx={{
                color: '#0014FF',
                fontWeight: 'bold',
                textDecorationColor: '#0014FF',
                textUnderlinePosition: 'under',
                marginLeft: 1 / 2,
                marginRight: 1 / 2,
              }}
            >
              {item.events_id}
            </Link>
          </Box>
        ))
      );
    },
    filterable: false,
  },
  {
    field: 'answered_count',
    headerName: '回答数',
    minWidth: { md: 120, xs: '40vw' },
    flex: 1,
    type: 'string',
    filterable: false,
  },
  {
    field: 'forms_answer_limit',
    headerName: '回答上限',
    minWidth: { md: 150, xs: '45vw' },
    flex: 1,
    type: 'string',
    filterable: false,
  },
  {
    field: 'edit',
    headerName: '編集',
    minWidth: { md: 50, xs: '35vw' },
    flex: 1,
    type: 'Edit',
    filterable: false,
  },
];
export default SurveyRuleSample;
