import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './baseQuery';
import { eventApi } from './eventApi';

export const modalApi = createApi({
  reducerPath: 'modal_api',
  tagTypes: ['modals'],
  baseQuery: axiosBaseQuery(),
  endpoints: build => ({
    getAllModals: build.query<any, void>({
      query: () => ({ url: '/modals', method: 'GET' }),
      providesTags: ['modals'],
      transformResponse: (response: { modals_response: [] }, meta, arg) =>
        response?.modals_response,
    }),
    getModals: build.query({
      query: params => ({
        url: `/modals${params && '?' + params}`,
        method: 'GET',
      }),
      providesTags: ['modals'],
      transformResponse: (response: { modals_response: [] }) =>
        response?.modals_response,
    }),
    createModal: build.mutation({
      query: body => ({
        url: '/modals',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['modals'],
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            eventApi.endpoints.getFormModalBannerPoint.initiate(undefined, {
              forceRefetch: true,
            }),
          );
        } catch (err) {
          console.log(err);
        }
      },
    }),
    updateModal: build.mutation({
      query: ({ id, body }) => ({
        url: `/modals/${id}`,
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: ['modals'],
    }),
    deleteModal: build.mutation({
      query: id => ({
        url: `/modals/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['modals'],
    }),
  }),
});

export const {
  useUpdateModalMutation,
  useGetModalsQuery,
  useGetAllModalsQuery,
  useCreateModalMutation,
  useDeleteModalMutation,
} = modalApi;
