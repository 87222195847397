import * as React from 'react';
import { DataTemplate } from '../../../utils/types/dataTemplate';
import CellCustom from '../../components/DataTable/CellCustom';
import { useGetCategoriesQuery } from '../../../services/categoryApi';
import {
  presentApi,
  useGetPresentsQuery,
  useUpdatePresentMutation,
} from '../../../services/presentApi';

const template: DataTemplate[] = [
  {
    field: 'presents_id',
    headerName: 'presents_id',
    minWidth: { md: 250, xs: '60vw' },
    flex: 2,
    type: 'id',
    hide: true,
    filterable: false,
  },
  {
    field: 'presents_title',
    headerName: 'プレゼントタイトル',
    minWidth: { md: 250, xs: '60vw' },
    flex: 2,
    type: 'string',
    filterField: 'title',
  },
  {
    field: 'presents_category_id',
    headerName: 'カテゴリー',
    minWidth: { md: 180, xs: '45vw' },
    flex: 2,
    sort: true,
    sortName: 'presents.category_id',
    type: 'custom',
    renderProps: props => (
      <CellCustom
        query={useGetCategoriesQuery}
        field={props}
        fieldName={'categories_title'}
        hasLink={'/category?title='}
        linkField={'categories_title'}
        noApiSearch={'categories_id'}
      />
    ),
  },
  {
    field: 'presents_image_url',
    headerName: '画像',
    minWidth: { md: 120, xs: '30vw' },
    flex: 2,
    type: 'img',
    filterable: false,
  },
  {
    field: 'presents_exchange_limit',
    headerName: '上限',
    minWidth: { md: 120, xs: '30vw' },
    flex: 1,
    type: 'string',
    filterable: false,
  },
  {
    field: 'presents_start_at',
    headerName: '配信開始日時',
    minWidth: { md: 180, xs: '45vw' },
    flex: 1,
    type: 'date start',
    filterField: 'start_at',
    sort: true,
    sortName: 'presents.start_at',
  },
  {
    field: 'presents_end_at',
    headerName: '配信終了日時',
    minWidth: { md: 180, xs: '45vw' },
    flex: 1,
    type: 'date end',
    filterField: 'end_at',
    sort: true,
    sortName: 'presents.end_at',
  },
  {
    field: 'presents_point_amount',
    headerName: 'ポイント数',
    minWidth: { md: 180, xs: '45vw' },
    flex: 1,
    type: 'string',
    filterable: false,
    sort: true,
    sortName: 'presents.point_amount',
  },
  {
    field: 'presents_sort_number',
    headerName: '掲載順序',
    minWidth: { md: 180, xs: '45vw' },
    flex: 1,
    type: 'string',
    filterable: false,
    sort: true,
    sortName: 'presents.sort_number',
  },
  {
    field: 'presents_recommend_sort_number',
    headerName: 'おすすめ掲載順序',
    minWidth: { md: 220, xs: '55vw' },
    flex: 1,
    type: 'string',
    filterable: false,
    sort: true,
    sortName: 'presents.recommend_sort_number',
  },
  {
    field: 'presents_status',
    headerName: 'ステータス',
    minWidth: { md: 180, xs: '45vw' },
    flex: 1,
    type: 'string',
    filterField: 'status',
    sortName: 'presents.status',
    sort: true,
    renderOptions: [
      {
        value: 1,
        renderName: '配信中',
      },
      {
        value: 2,
        renderName: '終了済み',
      },
      {
        value: 3,
        renderName: '配信停止中',
      },
    ],
  },
  {
    field: 'presents_recommend_flag',
    headerName: 'おすすめフラグ',
    minWidth: { md: 180, xs: '45vw' },
    flex: 1,
    type: 'switch',
    filterField: 'recommend_flag',
    sort: true,
    sortName: 'presents.recommend_flag',
    switchFeature: {
      options: {
        type: 'num',
        id: 'presents_id',
        field: 'present_recommend_flag',
        convert: [
          {
            fieldGetName: 'presents_recommend_flag',
            fieldEditName: 'present_recommend_flag',
          },
        ],
      },
      funcUpdate: useUpdatePresentMutation,
      funcGet: presentApi.endpoints.getPresents,
    },
  },
  {
    field: 'present_hashtags_hashtag',
    headerName: 'ハッシュタグ',
    minWidth: { md: 180, xs: '45vw' },
    flex: 1,
    type: 'string',
    filterField: 'hashtag',
    hide: true,
  },
  {
    field: 'edit',
    headerName: '編集',
    minWidth: { md: 120, xs: '34vw' },
    flex: 1,
    type: 'Edit',
    filterable: false,
  },
  // {
  //   field: 'delete',
  //   headerName: '削除',
  //   minWidth: { md: 120, xs: '34vw' },
  //   flex: 1,
  //   type: 'Delete',
  //   filterable: false,
  // },
];
export default template;
