import React from 'react';
import TableSortLabel from '@mui/material/TableSortLabel';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import ArrowDropDownSharpIcon from '@mui/icons-material/ArrowDropDownSharp';

interface IProps {
  field: string;
  headerName: string | undefined;
  orderBy: string;
  order: 'asc' | 'desc' | undefined;
  minWidth?: { md: string | number; xs: string | number };
  sort?: boolean;
  sortName?: string;
  checkSortLocal?: boolean;
  createSortHandler(property: string | undefined): void;
}

const LTableHead: React.FC<IProps> = ({
  field,
  headerName,
  orderBy,
  order,
  minWidth,
  sort,
  sortName,
  checkSortLocal,
  createSortHandler,
}) => {
  return !checkSortLocal || sort ? (
    <TableCell
      sortDirection={orderBy === field ? order : false}
      sx={{ flex: 1, minWidth: minWidth }}
    >
      <TableSortLabel
        active={sortName ? sortName === orderBy : orderBy === field}
        direction={
          sortName
            ? orderBy === sortName
              ? order
              : 'asc'
            : orderBy === field
            ? order
            : 'asc'
        }
        onClick={() => createSortHandler(sortName ? sortName : field)}
        LinkComponent={ArrowDropDownSharpIcon}
      >
        <Typography variant={'body1'} fontWeight={'bold'}>
          {headerName}
        </Typography>
      </TableSortLabel>
    </TableCell>
  ) : (
    <TableCell sx={{ flex: 1, minWidth: minWidth }}>
      <Typography variant={'body1'} fontWeight={'bold'}>
        {headerName}
      </Typography>
    </TableCell>
  );
};

export default LTableHead;
