import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './baseQuery';

export interface IOfferwallCase {
  id: number;
  url: string;
  icon_url: string;
  title: string;
  requirements: string;
  point: number;
  os: number;
  expire_at: string;
  stages?: string;
  deleted_at?: string;
  flag?: number;
  threshold?: number;
}

export const offerwallApi = createApi({
  reducerPath: 'offerwallApi',
  tagTypes: ['offerwall'],
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    submit: builder.mutation<void, { url: string; icon_url: string; title: string; requirements: string; point: string; os: string; expire_at: string, notes?: string, stages?: string, event_id?: string, bonus_point?: string, max_point?: string}>({
      query: (body) => ({
        url: '/offerwall_ad_add',
        method: 'POST',
        data: {
          url: body.url,
          icon_url: body.icon_url,    
          title: body.title,
          requirements: body.requirements,
          point: parseInt(body.point, 10), // Convert to int
          os: parseInt(body.os, 10), // Convert to int
          expire_at: body.expire_at,
          notes: body.notes,
          stages: body.stages,
          event_id: body.event_id ? parseInt(body.event_id, 10) : null,
          bonus_point: body.bonus_point ? parseInt(body.bonus_point, 10) : null, 
          max_point: body.max_point ? parseInt(body.max_point, 10) : null, 
        }
      }),
    }),

    submitDeleteUrl: builder.mutation<void, { delete_url: string}>({
      query: (body) => ({
        url: '/offerwall_delete_ad',
        method: 'POST',
        data: {
          url: body.delete_url
        }
      }),
    }),

    submitSuspend: builder.mutation<void, {reportId: number, suspend: number }>({
      query: (data) => ({
        url: '/offerwall_suspend',
        method: 'POST',
        data: {
          reportId: data.reportId, // Convert to int
          suspend: data.suspend,
        },
      }),
    }),

    submitFlag: builder.mutation<void, {reportId: number, flag: number }>({
      query: (data) => ({
        url: '/offerwall_flag',
        method: 'POST',
        data: {
          reportId: data.reportId, // Convert to int
          flag: data.flag,
        },
      }),
    }),

    submitThreshold: builder.mutation<void, {reportId: number, threshold: number }>({
      query: (data) => ({
        url: '/offerwall_threshold',
        method: 'POST',
        data: {
          reportId: data.reportId, // Convert to int
          threshold: data.threshold,
        },
      }),
    }),

    // New endpoint for fetching ads
    getAds: builder.query<IOfferwallCase[], void>({
      query: params => ({
        url: '/offerwall_get_ads',
        method: 'GET',
      }),
      providesTags: ['offerwall'],
      transformResponse: (response: IOfferwallCase[]) => response,
    }),

  }),
});

// Export both mutation hooks
export const { useSubmitMutation, useSubmitDeleteUrlMutation, useSubmitSuspendMutation, useSubmitFlagMutation, useSubmitThresholdMutation, useGetAdsQuery} = offerwallApi;
