import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './baseQuery';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    submit: builder.mutation<void, { userId: string; count: string }>({
      query: (body) => ({
        url: '/tel_input_count_update',
        method: 'POST',
        data: {
          userId: parseInt(body.userId, 10), // Convert to int
          count: parseInt(body.count, 10)    // Convert to int
        }
      }),
    }),
    submitTel: builder.mutation<void, { userId: string; telNumber: string }>({
      query: (body) => ({
        url: '/tel_update',
        method: 'POST',
        data: {
          userId: parseInt(body.userId, 10), // Convert to int
          tel: body.telNumber
        }
      }),
    }),
  }),
});

// Export both mutation hooks
export const { useSubmitMutation, useSubmitTelMutation } = api;
