import React, { useContext, useEffect } from 'react';
import {
  Box35,
  GridContainer,
  GridItem4,
  GridItem8,
  LBox,
  TitlePink,
} from '../../../components/Common/common';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import QuickAddButton from '../../../components/Button/QuickAddButton';
import TableContainer from '@mui/material/TableContainer';
import InputLabel from '@mui/material/InputLabel';
import SelectInput from '../../../components/Form/SelectInput';
import TextInput from '../../../components/Form/TextInput';
import InputAdornment from '@mui/material/InputAdornment';
import { EventContext } from './index';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import UndoIcon from '@mui/icons-material/Undo';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';

const InvitationForm = ({ handleOpenModal, handleEditModal, editData }) => {
  const data = useContext(EventContext);
  const { invitationForm, setInvitationForm } = data;

  useEffect(() => {
    setInvitationForm(editData.invitation_details);
  }, []);

  const deleteInvitation = id => {
    let temp = [...invitationForm];
    const index = temp.findIndex((item, key) => key === id);
    if (index !== -1) {
      if (temp[index]?.invitation_detail_id) {
        temp[index] = {
          ...temp[index],
          trash_flag: temp[index]?.trash_flag ? false : true,
        };
      } else {
        temp.splice(index, 1);
      }
    }
    setInvitationForm(temp);
  };

  return (
    <LBox mt={5}>
      <TitlePink>招待詳細設定</TitlePink>
      <Stack mt={4} px={4} pb={4} pt={2} rowGap={{ md: 2, xs: 5 }}>
        <Typography>招待ポイント一覧</Typography>
        <TableContainer sx={{ mt: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>招待達成順</TableCell>
                <TableCell>ポイント</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invitationForm.length > 0 &&
                invitationForm.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {item['invitation_no']}
                    </TableCell>
                    <TableCell>{item['inv_point_amount']}</TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => handleEditModal(2, index, item)}
                        disabled={
                          !(
                            !item?.hasOwnProperty('trash_flag') ||
                            item?.trash_flag !== true
                          )
                        }
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton onClick={() => deleteInvitation(index)}>
                        {!item?.hasOwnProperty('trash_flag') ||
                        item?.trash_flag !== true ? (
                          <DeleteIcon />
                        ) : (
                          <UndoIcon />
                        )}
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              <TableRow>
                <TableCell colSpan={3} sx={{ border: 'unset' }}>
                  <QuickAddButton onClick={() => handleOpenModal(5)} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {/* unknown11 */}
        <GridContainer>
          <GridItem4>
            <InputLabel htmlFor={'inv_repeat_flag'}>
              招待人数が上限に達した後
            </InputLabel>
          </GridItem4>
          <GridItem8>
            <Box35>
              <SelectInput
                name={'inv_repeat_flag'}
                labelName={''}
                showLabel={false}
                defaultValue={0}
                options={[
                  { name: '循環する', value: 0 },
                  { name: '循環しない', value: 1 },
                ]}
              />
            </Box35>
          </GridItem8>
        </GridContainer>
        {/* unknown12 */}
        <GridContainer>
          <GridItem4>
            <InputLabel htmlFor={'inv_need_days'}>
              招待完了までの連続ログイン日数
            </InputLabel>
          </GridItem4>
          <GridItem8>
            <Box35>
              <TextInput
                name={'inv_need_days'}
                labelName={''}
                showLabel={false}
                type={'number'}
                defaultValue={editData['invitations_inv_need_days']}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">日</InputAdornment>
                  ),
                }}
              />
            </Box35>
          </GridItem8>
        </GridContainer>
        {/* unknown13 */}
        <GridContainer>
          <GridItem4>
            <InputLabel htmlFor={'invited_user_point'}>
              招待された側の取得ポイント
            </InputLabel>
          </GridItem4>
          <GridItem8>
            <Box35>
              <TextInput
                name={'invited_user_point'}
                defaultValue={editData['invitations_invited_user_point']}
                labelName={''}
                showLabel={false}
                type={'number'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">ポイント</InputAdornment>
                  ),
                }}
              />
            </Box35>
          </GridItem8>
        </GridContainer>
        {/* unknown14 */}
        <GridContainer>
          <GridItem4>
            <InputLabel htmlFor={'inv_number'}>同時招待中人数</InputLabel>
          </GridItem4>
          <GridItem8>
            <Box35>
              <TextInput
                name={'inv_number'}
                defaultValue={editData['invitations_inv_number']}
                labelName={''}
                showLabel={false}
                type={'number'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">人</InputAdornment>
                  ),
                }}
              />
              <Box sx={{ display: 'none' }}>
                <TextInput
                  name={'invitation_id'}
                  defaultValue={editData['invitations_id']}
                  labelName={'invitation_id'}
                  showLabel={false}
                />
              </Box>
            </Box35>
          </GridItem8>
        </GridContainer>
      </Stack>
    </LBox>
  );
};

export default InvitationForm;
