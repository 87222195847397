import * as yup from 'yup';

yup.setLocale({
  mixed: {
    required: '必須項目です。',
  },
  number: {
    positive: '正の整数を入力してください',
    integer: '正の整数を入力してください',
  },
});

export default yup;

export const errorNumber = '数を入力です。';
