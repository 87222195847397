import React, { useEffect } from 'react';
import { Box, DialogActions, DialogContent, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { useCommonSlice } from '../../../../store/commonSlice';
import { useDispatch, useSelector } from 'react-redux';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import TextInput from '../../../components/Form/TextInput';
import SelectInput from '../../../components/Form/SelectInput';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { survey_question } from '../interface';
import { SelectCommonStateDataAttach } from 'store/commonSlice/selectors';
import { QUESTION_FORMAT_OPTIONS } from './AddFormQuestion';
import { CheckNull } from '../../../../utils/funcs/CheckNull';

interface FormQuestionsProps {
  handleSubmit: (data: any) => void;
  questions: Array<any>;
}

const validateForm = yup.object().shape({
  question_content: yup.string().required('質問内容が必須です'),
  question_flag: yup
    .number()
    .typeError('必須フラグが無効です')
    .required('必須フラグが必須です'),
  question_format: yup
    .string()
    .max(32, '回答形式が無効です')
    .required('回答形式が必須です'),
  question_hidden_flag: yup
    .number()
    .typeError('非表示フラグが必須です')
    .required('非表示フラグが必須です'),
});

const EditFormQuestion = (props: FormQuestionsProps) => {
  const { actions } = useCommonSlice();
  const dispatch = useDispatch();
  const dataEdit = useSelector(SelectCommonStateDataAttach);
  const methods = useForm<any>({
    resolver: yupResolver(validateForm),
    defaultValues: dataEdit,
  });

  const onSubmit: SubmitHandler<any> = (data: survey_question) => {
    props.handleSubmit(data);
    dispatch(actions.closeModal());
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <DialogContent dividers className="dialogContentModal">
          <Box sx={{ flexDirection: 'row', display: 'flex' }}>
            <Typography sx={{ marginRight: 1 }}>{'表示番号   '}</Typography>
            <Typography sx={{ fontWeight: 'bold' }}>
              {dataEdit?.question_no}
            </Typography>
          </Box>
          <TextInput
            name={'question_content'}
            labelName={'質問内容'}
            showLabel
            defaultValue={dataEdit.question_content}
          />
          <SelectInput
            name={'question_format'}
            labelName={'回答形式'}
            showLabel
            options={QUESTION_FORMAT_OPTIONS}
            defaultValue={dataEdit.question_format}
          />
          <TextInput
            name={'question_choices'}
            labelName={'回答選択肢(カンマ区切り)'}
            showLabel
            defaultValue={dataEdit.question_choices}
          />
          <SelectInput
            name={'question_flag'}
            labelName={'必須フラグ'}
            showLabel
            defaultValue={CheckNull(dataEdit.question_flag)}
            options={[
              { name: 'オン', value: 1 },
              { name: 'オフ', value: 0 },
            ]}
          />
          <SelectInput
            name={'question_hidden_flag'}
            labelName={'非表示フラグ'}
            showLabel
            defaultValue={CheckNull(dataEdit.question_hidden_flag)}
            options={[
              { name: 'オン', value: 1 },
              { name: 'オフ', value: 0 },
            ]}
          />
          <Typography
            variant={'body1'}
            fontWeight={'bold'}
            color={'#0000FF'}
            mt={3}
          >
            {'回答形式に関して'}
          </Typography>
          <Typography
            variant={'body1'}
            fontWeight={'bold'}
            color={'#0000FF'}
            mb={4}
          >
            {'プルダウン/チェックボックス/テキストフィールド'}
          </Typography>
          <Typography variant={'body1'} fontWeight={'bold'} color={'#0000FF'}>
            {'必須フラグ'}
          </Typography>
          <Typography
            variant={'body1'}
            fontWeight={'bold'}
            color={'#0000FF'}
            mb={2}
          >
            {'有効/無効'}
          </Typography>
        </DialogContent>
        <DialogActions className="dialogActionModal">
          <Button
            autoFocus
            variant={'text'}
            onClick={() => dispatch(actions.closeModal())}
            className="btnModalCancel"
          >
            Cancel
          </Button>
          <Button variant={'text'} type={'submit'} className="btnModalSubmit">
            Edit
          </Button>
        </DialogActions>
      </form>
    </FormProvider>
  );
};

export default EditFormQuestion;
