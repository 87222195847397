/**
 *
 * Asynchronously loads the component for PresentPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const PresentPage = lazyLoad(
  () => import('./index'),
  module => module.PresentPage,
);
