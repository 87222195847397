// api.ts
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './baseQuery';

export interface Report {
    id: number;
    user_id: string;
    url: string;
    image?: string;
    image_2?: string;
    created_at: string;
    approved: number;
    os: number;
    note?: string;
    title: string;
    duplicate: number
}


export const wallReportApi = createApi({
    reducerPath: 'wallReportApi',
    tagTypes: ['wallReport'],
    baseQuery: axiosBaseQuery(),
    endpoints: (builder) => ({
      getReports: builder.query<Report[], void>({
        query: () => ({
          url: '/offerwall_get_work_report',
          method: 'GET',
        }),
        providesTags: ['wallReport'],
        transformResponse: (response: Report[]) => response,
      }),
      // ... other endpoints ...
      submitReport: builder.mutation<void, {reportId: number, text: string; number: number }>({
        query: (data) => ({
          url: '/approve_wall_work',
          method: 'POST',
          data: {
            reportId: data.reportId, // Convert to int
            approved: data.number,
            note: data.text
          },
        }),
      }),
    }),
    
  });
  
  export const { useGetReportsQuery , useSubmitReportMutation} = wallReportApi;
