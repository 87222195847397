import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './baseQuery';

export interface IStatistics {
    whole_user: number;
    registered_user: number;
    whole_user_ios: number;
    registered_user_ios: number;
    whole_user_android: number;
    registered_user_android: number;
    sms_user: number;
    sms_user_ios: number;
    sms_user_android: number;
    deleted_suspended_user: number;
}


export const statisticsApi = createApi({
    reducerPath: 'statisticsApi',
    tagTypes: ['statistics'],
    baseQuery: axiosBaseQuery(),
    endpoints: (builder) => ({
      getStatistics: builder.mutation<void, { startDate: string; endDate: string }>({
        query: (data) => ({
          url: '/get_statistics',
          method: 'POST',
          data: {
            startDate: data.startDate,
            endDate: data.endDate,
          },
          providesTags: ['statistics'],
          transformResponse: (response: IStatistics) => response,
        }),
      }),
      // ... other endpoints ...
    }),
  });
  
  export const { useGetStatisticsMutation } = statisticsApi;
