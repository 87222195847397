/**
 *
 * Asynchronously loads the component for BannerPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const BannerPage = lazyLoad(
  () => import('./index'),
  module => module.BannerPage,
);
