/**
 *
 * Asynchronously loads the component for UserPointPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const UserPointPage = lazyLoad(
  () => import('./index'),
  module => module.UserPointPage,
);
