import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import TextInput from '../../../components/Form/TextInput';
import SelectInput from '../../../components/Form/SelectInput';
import Button from '@mui/material/Button';
import { DialogContent } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useCommonSlice } from '../../../../store/commonSlice';
import { useDispatch } from 'react-redux';
import { EventContext } from './index';

const valid = yup.object().shape({
  inv_point_amount: yup
    .number()
    .required('招待ポイント数が必須です')
    .min(0, '招待ポイント数が無効です')
    .typeError('招待ポイント数が無効です'),
  invitation_no: yup
    .number()
    .required('招待達成順が必須です')
    .min(0, '招待達成順が無効です')
    .typeError('招待達成順が無効です'),
});
const AddInvitation = props => {
  const { actions } = useCommonSlice();
  const dispatch = useDispatch();
  const data = useContext(EventContext);
  const { invitationForm, setInvitationForm } = data;
  const methods = useForm<any>({ resolver: yupResolver(valid) });
  const onSubmit: SubmitHandler<any> = data => {
    const temp = [...invitationForm];
    temp.push(data);
    setInvitationForm(temp);
    dispatch(actions.closeModal());
  };
  return (
    <DialogContent>
      <FormProvider {...methods}>
        <Box component={'form'} onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <TextInput
              name={'invitation_no'}
              type={'number'}
              labelName={'招待達成順'}
              defaultValue={''}
              showLabel={true}
            />
            <TextInput
              name={'inv_point_amount'}
              type={'number'}
              labelName={'ポイント'}
              defaultValue={''}
              showLabel={true}
            />
          </Stack>
          <Stack
            flexDirection={'row'}
            justifyContent={'flex-end'}
            columnGap={2}
            mt={4}
          >
            <Button
              type={'button'}
              sx={{ color: '#5B5A5A' }}
              onClick={() => dispatch(actions.closeModal())}
            >
              Cancel
            </Button>
            <Button type={'submit'}>ADD</Button>
          </Stack>
        </Box>
      </FormProvider>
    </DialogContent>
  );
};

export default AddInvitation;
