import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useCommonSlice } from '../../store/commonSlice';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const useMessage = (
  loading: boolean,
  isError: boolean,
  isSuccess: boolean,
  error: unknown,
  modal?: boolean,
  hasRoute?: string,
) => {
  const { actions } = useCommonSlice();
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    let modalTimeOut;
    let hasRouteTimeOut;
    if (isSuccess) {
      toast.success('Successfully', {
        position: 'bottom-left',
        autoClose: 1800,
      });
      if (modal) {
        modalTimeOut = setTimeout(() => {
          dispatch(actions.closeModal());
        }, 2000);
      }
      if (hasRoute) {
        hasRouteTimeOut = setTimeout(() => {
          history.push(hasRoute);
        }, 2000);
      }
    }
    if (isError) {
      if (error) {
        toast.error((error as any).data, {
          position: 'bottom-left',
        });
      }
    }

    return () => {
      clearTimeout(hasRouteTimeOut);
      clearTimeout(modalTimeOut);
    };
  }, [loading, isError, isSuccess]);
};

export default useMessage;
