import React from 'react';
import { useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ActionsBtn from '../../../utils/types/ActionsBtn';
import { useCommonSlice } from '../../../store/commonSlice';
import { useHistory } from 'react-router-dom';

interface Props extends ActionsBtn {
  data: any;
  routeEdit?: string;
}

const ActionButton = ({ data, type, routeEdit }: Props) => {
  const dispatch = useDispatch();
  const { actions: commonActions } = useCommonSlice();
  const route = useHistory();

  const toggleModal = payload => e => {
    if (routeEdit) {
      const routeEditToAr = routeEdit.split('/');
      const editUrl = routeEdit.replace(
        routeEditToAr[routeEditToAr.length - 1],
        payload.dataAttach[routeEditToAr[routeEditToAr.length - 1]],
      );
      route.push(editUrl);
      dispatch(commonActions.setDataEdit(payload));
    } else {
      dispatch(commonActions.toggleModal(payload));
    }
  };
  let Icon;
  switch (type) {
    case 'Edit':
      Icon = <EditIcon />;
      break;
    case 'Delete':
      Icon = <DeleteIcon />;
      break;
  }

  return (
    <IconButton
      aria-label={type}
      onClick={toggleModal({
        actionModal: type,
        dataAttach: data,
      })}
    >
      {Icon}
    </IconButton>
  );
};

export default ActionButton;
