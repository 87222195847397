import { DataTemplate } from '../../../../utils/types/dataTemplate';

const SurveyAddSample: DataTemplate[] = [
  {
    field: 'question_no',
    headerName: '表示番号',
    minWidth: { md: 130, xs: '40vw' },
    flex: 1,
    type: 'string',
  },
  {
    field: 'question_content',
    headerName: '質問内容',
    minWidth: { md: 150, xs: '45vw' },
    flex: 1,
    type: 'string',
  },
  {
    field: 'question_format',
    headerName: '回答形式',
    minWidth: { md: 150, xs: '45vw' },
    flex: 1,
    type: 'string',
  },
  {
    field: 'question_choices',
    headerName: '回答選択肢(カンマ区切り)',
    minWidth: { md: 150, xs: '45vw' },
    flex: 1,
    type: 'string',
  },
  {
    field: 'question_flag',
    headerName: '必須フラグ',
    minWidth: { md: 150, xs: '45vw' },
    flex: 1,
    type: 'switch',
  },
  {
    field: 'question_hidden_flag',
    headerName: '非表示フラグ',
    minWidth: { md: 150, xs: '45vw' },
    flex: 1,
    type: 'switch',
  },
  {
    field: 'edit',
    headerName: '',
    minWidth: { md: 50, xs: '30vw' },
    flex: 1,
    type: 'Edit',
  },
];
export default SurveyAddSample;
