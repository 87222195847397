import React, { useMemo } from 'react';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { CircularProgress, Link } from '@mui/material';

interface Interface {
  field: any;
  fieldSearch: string;
  fieldLink: string | number;
  fieldRender: string;
  linkRefer: string;
  query: UseQuery<any>;
}

const CellCustomID: React.FC<Interface> = ({
  field,
  fieldSearch,
  fieldLink,
  fieldRender,
  linkRefer,
  query,
}) => {
  const { data = [], isFetching, isError } = query('');

  const result = useMemo<{} | string>(() => {
    if (Array.isArray(data) && data.length > 0) {
      const find = data.find(item => item[fieldSearch] === field);
      if (find) {
        return find;
      }
    }
    return '';
  }, [isFetching, data]);

  if (field === null) {
    return <></>;
  }

  if (isFetching) {
    return <CircularProgress />;
  }

  if (isError || result === '') {
    return <span></span>;
  }

  return (
    <Link display={'block'} href={linkRefer + result[fieldLink]}>
      {result[fieldRender]}
    </Link>
  );
};

export default CellCustomID;
