/**
 *
 * EventAddPage
 *
 */
import { Layout } from '../../../components/Layout';
import { ToolBar } from '../../../components/ToolBar';
import Box from '@mui/material/Box';
import { DivWrap } from '../../../components/DivWrap';
import React, { useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { useCommonSlice } from '../../../../store/commonSlice';
import { useDispatch } from 'react-redux';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  Box35,
  Box70,
  LBox,
  TitlePink,
} from '../../../components/Common/common';
import {
  debounce,
  Grid,
  LinearProgress,
  Switch,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import TextInput from '../../../components/Form/TextInput';
import SelectInput from '../../../components/Form/SelectInput';
import LTableHead from 'app/components/DataTable/LTableHead';
import SurveyAddSample from '../SurveyAddPage/DataAddTemplate';
import {
  ISurvey,
  useGetAllSurveyQuery,
  useUpdateSurveyMutation,
} from 'services/surveyApi';
import { yupResolver } from '@hookform/resolvers/yup';
import { RouteModal } from 'app/components/RouteModal';
import AddFormQuestion, {
  QUESTION_FORMAT_OPTIONS,
} from '../SurveyAddPage/AddFormQuestion';
import EditFormQuestion from '../SurveyAddPage/EditFormQuestion';
import { survey_question } from '../interface';
import * as yup from 'yup';
import useMessage from '../../../../utils/customHooks/useMessage';
import ImageUploadV from '../../../components/Form/ImageUploadV';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import QuickAddButton from '../../../components/Button/QuickAddButton';
import TableContainer from '@mui/material/TableContainer';
import useFocusError from '../../../../utils/customHooks/useFocusError';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import ActionButton from '../../../components/Button/ActionButton';

const validateQuestions = yup.object().shape({
  answer_limit: yup
    .number()
    .min(0, '回答上限が無効です')
    .typeError('回答上限が無効です')
    .required('回答上限がが必須です'),
  form_hidden_flag: yup
    .number()
    .typeError('必須フラグが無効です')
    .required('必須フラグが必須です'),
  title: yup
    .string()
    .max(255, 'タイトルが無効です')
    .required('タイトルが必須です'),
});

export function SurveyEditPage() {
  const { survey_id } = useParams<{ survey_id: string }>();
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<any>(null);
  const [questions, setQuestions] = useState<Array<survey_question>>([]);
  const { actions: commonActions } = useCommonSlice();
  const dispatch = useDispatch();

  const [dataSelect, setDataSelect] = useState<ISurvey>();

  const [updateSurvey, { isLoading, isSuccess, isError, error }] =
    useUpdateSurveyMutation();
  const [switchUpdateSurvey] = useUpdateSurveyMutation();

  const { data: surveyData, isFetching: surveyIsFetching } =
    useGetAllSurveyQuery('');

  useEffect(() => {
    if (surveyData && surveyData.length > 0) {
      const temp = surveyData.find(
        item => item.forms_id === parseInt(survey_id),
      );
      if (temp) {
        setDataSelect(temp);
        setQuestions(temp.form_questions ? temp.form_questions : []);
      }
    }
  }, [surveyData]);

  const methods = useForm<any>({
    mode: 'all',
    resolver: yupResolver(validateQuestions),
  });

  const {
    setFocus,
    formState: { errors },
  } = methods;

  useFocusError(errors, setFocus);

  useMessage(isLoading, isError, isSuccess, error, false, '/survey');

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const result: Array<any> = React.useMemo(() => {
    let temp = [...questions];

    if (orderBy !== null) {
      temp.sort((a, b) => {
        if (b[orderBy] < a[orderBy]) {
          return order === 'asc' ? -1 : 1;
        }
        if (b[orderBy] > a[orderBy]) {
          return order === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }

    return questions;
  }, [questions, orderBy, order]);

  const openModal = () => {
    dispatch(
      commonActions.toggleModal({
        actionModal: 'Add',
        dataAttach: null,
      }),
    );
  };

  const onSubmit: SubmitHandler<any> = (data: any) => {
    data.form_hidden_flag = data.form_hidden_flag === 1;
    let clonedArray = JSON.parse(JSON.stringify(questions));
    if (clonedArray.length > 0) {
      clonedArray.map(item => {
        item.question_flag =
          typeof item.question_flag === 'boolean'
            ? item.question_flag
            : item.question_flag === 1;
        item.question_hidden_flag =
          typeof item.question_hidden_flag === 'boolean'
            ? item.question_hidden_flag
            : item.question_hidden_flag === 1;
        const index = QUESTION_FORMAT_OPTIONS.findIndex(
          i => item.question_format === i.value,
        );
        if (index > -1) {
          item.question_format = QUESTION_FORMAT_OPTIONS[index].value;
        }
      });
      data.questions = clonedArray;
      updateSurvey({ id: survey_id, body: JSON.stringify(data) });
    } else {
      toast.error('フォーム質問内容が必須です', {
        position: 'bottom-left',
      });
    }
  };

  const onSubmitQuestionForm = (dataQuestions: any) => {
    const nData: Array<survey_question> = [...questions, dataQuestions];
    setQuestions(nData);
  };

  const onSubmitEditQuestionForm = (dataQuestions: survey_question) => {
    const nQuestions = [...questions];
    if (nQuestions.length > 0) {
      nQuestions.map((item: survey_question, index: number) => {
        if (item.question_no === dataQuestions.question_no) {
          nQuestions[index] = dataQuestions;
        }
      });
    }
    setQuestions(nQuestions);
  };

  const deleteQuestion = id => {
    const nQuestions = [...questions];
    if (nQuestions.length > 0) {
      const findIndex = nQuestions.findIndex(item => item.question_no === id);
      if (findIndex !== -1) {
        nQuestions.splice(findIndex, 1);
        setQuestions(nQuestions);
      }
    }
  };

  /*
   * wait to last result then call api
   * time out 2s
   * */
  const debouncedSave = useCallback(
    debounce(
      body =>
        (function () {
          switchUpdateSurvey({
            id: survey_id,
            body: JSON.stringify({
              questions: body,
            }),
          });
        })(),
      2000,
    ),
    [],
  );

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>, index) => {
    const temp = questions.map((item, key) =>
      index === key ? { ...item, question_flag: event.target.checked } : item,
    );
    setQuestions(temp);
    debouncedSave(handleQuestionList(temp));
  };

  const handleSwitch2 = (event: React.ChangeEvent<HTMLInputElement>, index) => {
    const temp = questions.map((item, key) =>
      index === key
        ? { ...item, question_hidden_flag: event.target.checked }
        : item,
    );
    setQuestions(temp);
    debouncedSave(handleQuestionList(temp));
  };

  return (
    <Layout title={'フォーム管理'}>
      <Box sx={{ overflow: 'hidden' }}>
        <ToolBar
          title={'フォーム管理'}
          noSearchPanel={true}
          noFilterPanel={true}
          btnBack={true}
          hasRoute={'/survey'}
        />
        {surveyIsFetching ? (
          <LinearProgress />
        ) : dataSelect ? (
          <>
            <DivWrap>
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <LBox>
                    <TitlePink>フォーム基本</TitlePink>
                    <Box mt={4} pl={4} pb={4}>
                      <Grid container alignItems={'flex-end'}>
                        <Grid item md={4}>
                          <InputLabel htmlFor={'title'}>タイトル</InputLabel>
                        </Grid>
                        <Grid item md={8}>
                          <Box35>
                            <TextInput
                              name={'title'}
                              labelName={'イベント名'}
                              showLabel={false}
                              defaultValue={dataSelect.forms_title}
                            />
                          </Box35>
                        </Grid>
                      </Grid>
                      <Grid container alignItems={'flex-top'} mt={2}>
                        <Grid item md={4}>
                          <InputLabel htmlFor={'top_url'}>
                            フォームトップ画像
                          </InputLabel>
                          <InputLabel htmlFor={'top_url'}>画像URL</InputLabel>
                        </Grid>
                        <Grid item md={8}>
                          <Box70>
                            <ImageUploadV
                              limit={1}
                              name={'top_url'}
                              showLabel={true}
                              labelName={'フォームトップ画像'}
                              showLabelTextInput={false}
                              edit={dataSelect.forms_top_image}
                            />
                          </Box70>
                        </Grid>
                      </Grid>

                      <Grid container alignItems={'flex-top'} mt={2}>
                        <Grid item md={4}>
                          <InputLabel htmlFor={'bottom_url'}>
                            フォームボトム画像
                          </InputLabel>
                          <InputLabel htmlFor={'bottom_url'}>
                            画像URL
                          </InputLabel>
                        </Grid>
                        <Grid item md={8}>
                          <Box70>
                            <ImageUploadV
                              limit={1}
                              name={'bottom_url'}
                              labelName={'フォームボトム画像'}
                              showLabel={true}
                              showLabelTextInput={false}
                              edit={dataSelect.forms_bottom_image}
                            />
                          </Box70>
                        </Grid>
                      </Grid>

                      <Grid container alignItems={'flex-end'}>
                        <Grid item md={4}>
                          <InputLabel htmlFor={'description'}>
                            フォーム概要説明
                          </InputLabel>
                        </Grid>
                        <Grid item md={8}>
                          <Box70>
                            <TextInput
                              name={'description'}
                              labelName={'イベント詳細'}
                              showLabel={false}
                              defaultValue={dataSelect.forms_description}
                            />
                          </Box70>
                        </Grid>
                      </Grid>

                      <Grid container alignItems={'flex-end'}>
                        <Grid item md={4}>
                          <InputLabel htmlFor={'answer_limit'}>
                            回答上限
                          </InputLabel>
                        </Grid>
                        <Grid item md={8}>
                          <Box70>
                            <TextInput
                              name={'answer_limit'}
                              labelName={'イベント詳細'}
                              showLabel={false}
                              defaultValue={dataSelect.forms_answer_limit}
                            />
                          </Box70>
                        </Grid>
                      </Grid>

                      <Grid container alignItems={'flex-end'}>
                        <Grid item md={4}>
                          <InputLabel htmlFor={'form_hidden_flag'}>
                            非表示フラグ
                          </InputLabel>
                        </Grid>
                        <Grid item md={2}>
                          <SelectInput
                            name={'form_hidden_flag'}
                            labelName={'非表示フラグ'}
                            showLabel={false}
                            options={[
                              { name: 'オン', value: 1 },
                              { name: 'オフ', value: 0 },
                            ]}
                            defaultValue={dataSelect.forms_hidden_flag}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </LBox>
                  <LBox sx={{ mt: 5 }}>
                    <TitlePink>フォーム質問内容</TitlePink>
                    <Box sx={{ padding: 3 }}>
                      <TableContainer
                        sx={{
                          paddingBottom: 1,
                          maxHeight: 700,
                          overflowX: 'scroll',
                          '&::-webkit-scrollbar': {
                            height: '7px',
                          },
                          '&::-webkit-scrollbar-track': {
                            WebkitBoxShadow: 'inset 0 0 3px rgba(0,0,0,0.00)',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            borderRadius: '5px',
                            backgroundColor: 'rgba(0,0,0,.5)',
                            WebkitBoxShadow: '0 0 1px rgba(255,255,255,.5)',
                          },
                        }}
                      >
                        <Table stickyHeader={true} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              {SurveyAddSample.map((item, index) => (
                                <LTableHead
                                  key={index}
                                  field={item.field}
                                  headerName={item.headerName}
                                  createSortHandler={handleRequestSort}
                                  orderBy={''}
                                  order={'desc'}
                                  minWidth={{ md: 200, xs: '55vw' }}
                                />
                              ))}
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody sx={{ width: '100%' }}>
                            {result.map((row, key) => (
                              <TableRow key={key}>
                                <TableCell component="th" scope="row">
                                  {row.question_no}
                                </TableCell>
                                <TableCell>{row.question_content}</TableCell>
                                <TableCell>{row.question_format}</TableCell>
                                <TableCell>{row.question_choices}</TableCell>
                                <TableCell>
                                  <Switch
                                    onChange={e => handleSwitch(e, key)}
                                    checked={row.question_flag}
                                  />
                                </TableCell>
                                <TableCell>
                                  <Switch
                                    onChange={e => handleSwitch2(e, key)}
                                    checked={row.question_hidden_flag}
                                  />
                                </TableCell>
                                <TableCell>
                                  <ActionButton data={row} type={'Edit'} />
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    onClick={() =>
                                      deleteQuestion(row.question_no)
                                    }
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                        <Box mt={2} ml={2}>
                          <QuickAddButton onClick={openModal} />
                        </Box>
                      </TableContainer>
                    </Box>
                  </LBox>
                  <Box
                    sx={{
                      marginTop: 4,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    <Button
                      variant={'contained'}
                      type={'submit'}
                      className="btnSubmit"
                      sx={{
                        backgroundColor: '#233ED3',
                        alignSelf: 'center',
                        minWidth: 300,
                      }}
                    >
                      設定完了
                    </Button>
                  </Box>
                </form>
              </FormProvider>
            </DivWrap>
            <RouteModal
              addForm={
                <AddFormQuestion
                  handleSubmit={onSubmitQuestionForm}
                  questions={questions}
                />
              }
              editForm={
                <EditFormQuestion
                  handleSubmit={onSubmitEditQuestionForm}
                  questions={questions}
                />
              }
            />
          </>
        ) : (
          <Typography>データがありません。</Typography>
        )}
      </Box>
    </Layout>
  );
}

//func handle question list
function handleQuestionList(questions) {
  let clonedArray = JSON.parse(JSON.stringify(questions));
  if (clonedArray.length > 0) {
    clonedArray.map(item => {
      item.question_flag =
        typeof item.question_flag === 'boolean'
          ? item.question_flag
          : item.question_flag === 1;
      item.question_hidden_flag =
        typeof item.question_hidden_flag === 'boolean'
          ? item.question_hidden_flag
          : item.question_hidden_flag === 1;
      const index = QUESTION_FORMAT_OPTIONS.findIndex(
        i => item.question_format === i.value,
      );
      if (index > -1) {
        item.question_format = QUESTION_FORMAT_OPTIONS[index].value;
      }
    });
  }
  return clonedArray;
}
