import React, { memo } from 'react';
import styled from '@emotion/styled';
import { cubic1 } from '../../../styles/media';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { GetTreeItemsFromData } from './MenuItem';
import { v4 as uuidv4 } from 'uuid';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { useCommonSlice } from '../../../store/commonSlice';
import {
  SelectMenu,
  SelectMenuExpanded,
} from '../../../store/commonSlice/selectors';

interface Props {
  show: boolean;
  clickShow: any;
}

export interface RenderTree {
  id: string;
  route?: string;
  name: string;
  children?: readonly RenderTree[];
}

const data: Array<RenderTree> = [
  {
    id: uuidv4(),
    name: "トップビュー",
    route: "/",
  },
  {
    id: uuidv4(),
    name: 'ポイント関係',
    children: [
      {
        id: uuidv4(),
        name: 'ユーザー一覧',
        children: [
          {
            id: uuidv4(),
            name: 'ユーザーごとポイント付与履歴',
            route: '/user-point',
          },
          /*
          {
            id: uuidv4(),
            name: '招待履歴',
            route: '/invitation-history',
          },
          */
        ],
      },
      /*
      {
        id: uuidv4(),
        name: 'イベント一覧',
        route: '/event',
      },
      {
        id: uuidv4(),
        name: 'ポイントルール一覧',
        route: '/point-rule',
      },
      {
        id: uuidv4(),
        name: 'バナー一覧',
        route: '/banner',
      },
      {
        id: uuidv4(),
        name: 'モーダル一覧',
        route: '/modal',
      },
      */
    ],
  },
  /*
  {
    id: uuidv4(),
    name: 'プレゼント',
    children: [
      {
        id: uuidv4(),
        name: 'プレゼント一覧',
        route: '/present',
      },
      {
        id: uuidv4(),
        name: 'カテゴリ一覧',
        route: '/category',
      },
      {
        id: uuidv4(),
        name: '受付プレゼント一覧',
        route: '/present-request',
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'フォーム',
    children: [
      {
        id: uuidv4(),
        name: 'フォーム一覧',
        route: '/survey',
      },
    ],
  },
  */
  {
    id: uuidv4(),
    name: 'レポート',
    children: [
      {
        id: uuidv4(),
        name: '日次レポート',
        route: '/daily_summaries',
      },
    ],
  },
  {
    id: uuidv4(),
    name: '管理ユーザー管理',
    children: [
      {
        id: uuidv4(),
        name: 'ユーザー管理',
        route: '/users',
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'エクスポート',
    route: '/export',
  },
  /*
  {
    id: uuidv4(),
    name: '強制アップデート&メンテナンス',
    route: '/ForceUpdateMaintenance',
  },
  {
    id: uuidv4(),
    name: 'SMS認証関連',
    route: '/SmsAuthPage',
  },
  {
    id: uuidv4(),
    name: 'オファーウォール',
    children: [
      {
        id: uuidv4(),
        name: '一覧',
        route: '/OfferwallPage',
      },
      {
        id: uuidv4(),
        name: '案件追加削除',
        route: '/OfferwallModifyPage',
      },
    ],
  },
  {
    id: uuidv4(),
    name: 'お仕事報告',
    children: [
      {
        id: uuidv4(),
        name: 'お仕事報告',
        route: '/WallReportPage',
      },
    ],
  },
  {
    id: uuidv4(),
    name: '統計データ',
    children: [
      {
        id: uuidv4(),
        name: '統計データ',
        route: '/StatisticsPage',
      },
    ],
  },
  */
  {
    id: uuidv4(),
    name: 'インポート',
    children: [
      {
        id: uuidv4(),
        name: '付与履歴',
        route: '/ImportHistoryPage',
      },
      {
        id: uuidv4(),
        name: 'インポート',
        route: '/ImportPage',
      },
    ],
  },
];

export const Menu = memo((props: Props) => {
  const { actions } = useCommonSlice();
  const dispatch = useDispatch();
  const expanded = useSelector(SelectMenuExpanded);
  const selected = useSelector(SelectMenu);
  const handleTree = (event, nodes) => {
    dispatch(actions.setMenuActive(nodes));
  };
  return (
    <>
      <Mask onClick={props.clickShow} show={props.show} />
      <NavMenu
        show={props.show}
        sx={{
          width: { xs: '70%', md: '300px' },
          left: { xs: '-70%', md: '-300px' },
        }}
      >
        <TreeView
          aria-label="rich object"
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          onNodeSelect={handleTree}
          expanded={expanded}
          selected={selected}
          sx={{
            overflowY: 'auto',
            color: '#333',
            paddingTop: '20px',
          }}
        >
          {GetTreeItemsFromData(data)}
        </TreeView>
      </NavMenu>
    </>
  );
});
const NavMenu = styled(Box)`
  ${(props: { show: boolean }) =>
    props.show ? `transform: translateX(100%)` : `transform: translateX(0)`};
  position: fixed;
  background-color: #fff;
  height: 100vh;
  z-index: 101;
  top: 0;
  bottom: 0;
  transition: transform 300ms;
  transition-timing-function: ${cubic1};
`;

const Mask = styled.div`
  ${(props: { show: boolean }) =>
    props.show ? `transform: translateX(100%)` : `transform: translateX(0)`};
  position: fixed;
  left: -100%;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100vh;
  z-index: 100;
  top: 0;
  bottom: 0;
  transition: transform 300ms;
  transition-timing-function: ${cubic1};
`;
