import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { useCommonSlice } from '../../../store/commonSlice';

interface IProps extends ButtonProps {}

const ImportButton: React.FC<IProps> = props => {
  const dispatch = useDispatch();
  const { actions: commonActions } = useCommonSlice();
  return (
    <Button
      sx={{ marginLeft: { md: 2, xs: 0 } }}
      variant={'contained'}
      color={'info'}
      onClick={() =>
        dispatch(
          commonActions.toggleModal({
            actionModal: 'Import',
            dataAttach: null,
          }),
        )
      }
      {...props}
    >
      インポート
    </Button>
  );
};

export default ImportButton;
