/**
 *
 * LoginPage
 *
 */
import * as React from 'react';
import { useLayoutEffect, useState } from 'react';
import { Layout } from '../../components/Layout';
import { Dialog, DialogTitle, FormControl, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Controller, useForm } from 'react-hook-form';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import isAuth from '../../../utils/auth/IsAuth';

interface Props {}
interface FormValues {
  user_mail: string;
  user_password: string;
}

interface LocationState {
  from: {
    pathname: string;
  };
}

const baseURL = process.env.REACT_APP_BASE_URL;

export function LoginPage(props: Props) {
  const [loginError, setLoginError] = useState<string>('');

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormValues>();

  const location = useLocation<LocationState>();
  const history = useHistory();

  if (isAuth() !== null) {
    history.push('/');
  }

  const submitHandle = body => {
    axios({
      url: baseURL + 'login',
      method: 'POST',
      data: body,
      headers: {
        'x-csrf-token': 'test',
      },
    })
      .then(res => {
        if (res.data.jwt_token !== '') {
          localStorage.setItem(
            'accessToken',
            'LmVT7lnwX3heUxURZpLeYjMB7qCTCRf4o4Pc0DCrkNtIoFnJZZ70QKvQADdQwCoTs1+fdw==',
          );
          if (localStorage.getItem('accessToken')) {
            window.location.href = '/';
          }
        } else {
          setLoginError('User email or password is wrong');
        }
      })
      .catch(errors => {
        setLoginError('User email or password is wrong');
      });
  };
  return (
    <>
      <Layout title={'ログイン'}>
        <Dialog open={true} PaperProps={{ style: { borderRadius: '5px' } }}>
          <Box component={'div'} sx={{ padding: '30px', width: '300px' }}>
            <DialogTitle
              variant={'h4'}
              align={'left'}
              sx={{ padding: '0', marginBottom: '20px' }}
            >
              Sign In
            </DialogTitle>
            <FormControl
              fullWidth={true}
              component={'form'}
              onSubmit={handleSubmit(submitHandle)}
            >
              <Box sx={inputText} component={'div'}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      fullWidth={true}
                      label="User Name"
                      variant="standard"
                      inputProps={{ type: 'username' }}
                      error={Boolean(errors.user_mail)}
                      helperText={
                        errors.user_mail ? 'User Name is required' : ''
                      }
                      {...field}
                    />
                  )}
                  name="user_mail"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                />
              </Box>
              <Box sx={inputText} component={'div'}>
                <Controller
                  render={({ field }) => (
                    <TextField
                      fullWidth={true}
                      label="Password"
                      variant="standard"
                      inputProps={{ type: 'password' }}
                      error={Boolean(errors.user_password)}
                      helperText={
                        errors.user_password ? 'password is required' : ''
                      }
                      {...field}
                    />
                  )}
                  name="user_password"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: true,
                  }}
                />
              </Box>
              {loginError !== '' && (
                <Typography color={'red'}>{loginError}</Typography>
              )}
              <Box
                sx={{
                  marginTop: '20px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button type="reset">
                  <Typography>Cancel</Typography>
                </Button>
                <Button type={'submit'} color={'primary'}>
                  <Typography>SIGN IN</Typography>
                </Button>
              </Box>
            </FormControl>
          </Box>
        </Dialog>
      </Layout>
    </>
  );
}

const inputText = {
  display: 'flex',
  alignItems: 'flex-end',
  marginBottom: '20px',
  width: '100%',
};
