import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './baseQuery';

export interface IUserPointHistory {
  point_id: number;
  title: string;
  app_user_id: number;
  device_id: number;
  point_amount: number;
  point_is_used: string;
  point_used: number;
  point_rest: number;
  point_get_at: string;
  point_expire: string;
  point_disable_flag: number;
  point_edited_admin: number;
}

export const userPointHistoryApi = createApi({
  reducerPath: 'userPointHistory_api',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['userPointHistory'],
  endpoints: build => ({
    getUserPointHistory: build.query<
      IUserPointHistory[],
      { id: any; member_type: any; query: string }
    >({
      query: params => ({
        url: `/point_get_rows/${params.member_type}/${params.id}${
          params.query && '?' + params.query
        }`,
        method: 'GET',
      }),
      providesTags: ['userPointHistory'],
      transformResponse: (response: { data_response: IUserPointHistory[] }) =>
        response?.data_response,
    }),
    createUserPointHistory: build.mutation({
      query: body => ({
        url: '/point_get_rows',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['userPointHistory'],
    }),
    updateUserPointHistory: build.mutation({
      query: ({ id, body }) => ({
        url: `/point_get_rows/${id}`,
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: ['userPointHistory'],
    }),
  }),
});

export const {
  useGetUserPointHistoryQuery,
  useCreateUserPointHistoryMutation,
  useUpdateUserPointHistoryMutation,
} = userPointHistoryApi;
