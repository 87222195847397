import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './baseQuery';

export interface IUserPoint {
  id: string;
  member_type: string;
  app_user_id: string;
  device_id: string;
  name: string;
  birthday: string;
  gender: number;
  address: number;
  job: number;
  hobby: string;
  active_at: string;
  rest_point: number;
  users_activate_flag: string;
  suspended_flag: number;
  point_get_rows_point_get_at: string;
}

export interface TotalPoint {
  users_total_number: number;
}

export const userPointApi = createApi({
  reducerPath: 'userPoint_api',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['userPoints', 'total', 'userStatus'],
  endpoints: build => ({
    getUserPoints: build.query<IUserPoint[], string>({
      query: params => (
        {
        url: `/point_get_rows${params && '?' + params}`,
        method: 'GET',
      }),
      providesTags: ['userPoints'],
      transformResponse: (response: {
        points_held_by_each_user: IUserPoint[];
      }) => response.points_held_by_each_user,
    }),
    getTotal: build.query<TotalPoint, string>({
      query: () => ({
        url: `/point_get_rows`,
        method: 'GET',
      }),
      providesTags: ['total'],
      transformResponse: (response: { users_total_number: TotalPoint }) =>
        response.users_total_number,
    }),
    changeUserStatus: build.mutation({
      query: ({id, body}) => ({
        url: `/point_get_rows/user/${id}`,
        method: 'PUT',
        data: body,
      }),
      
    })
  }),
});

export const { useGetUserPointsQuery, useGetTotalQuery, useChangeUserStatusMutation } = userPointApi;
