/**
 * Create the store with dynamic reducers
 */

import {
  configureStore,
  getDefaultMiddleware,
  StoreEnhancer,
} from '@reduxjs/toolkit';
import { createInjectorsEnhancer } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';

import { createReducer } from './reducers';
import { topViewApi } from '../services/topViewApi';
import { bannerApi } from '../services/bannerApi';
import { surveyApi } from '../services/surveyApi';
import { categoryApi } from '../services/categoryApi';
import { modalApi } from '../services/modalApi';
import { presentApi } from '../services/presentApi';
import { pointRuleApi } from '../services/pointRuleApi';
import { invitationHistoryApi } from '../services/invitationHistoryApi';
import { forceUpdateMaintenanceApi } from '../services/forceUpdateMaintenance';
import { presentRequestApi } from '../services/presentRequestApi';
import { eventApi } from '../services/eventApi';
import { userPointApi } from '../services/userPointApi';
import { userPointHistoryApi } from '../services/userPointHistoryApi';
import { imageUpload } from '../services/imageUpload';
import { dailySummariesApi } from '../services/dailySummariesApi';
import { adminUserApi } from '../services/adminUserApi';
import { wallReportApi } from '../services/wallReportApi';
import { statisticsApi } from 'services/statisticsApi';
import { offerwallApi } from 'services/gmoOfferwallApi';
import { importApi } from 'services/importApi';

export function configureAppStore() {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;

  // Create the store with saga middleware
  const middlewares = [sagaMiddleware];

  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ] as StoreEnhancer[];

  return configureStore({
    reducer: createReducer(),
    // @ts-ignore
    middleware: [
      ...getDefaultMiddleware(),
      adminUserApi.middleware,
      wallReportApi.middleware,
      statisticsApi.middleware,
      offerwallApi.middleware,
      importApi.middleware,
      topViewApi.middleware,
      bannerApi.middleware,
      surveyApi.middleware,
      categoryApi.middleware,
      modalApi.middleware,
      presentApi.middleware,
      pointRuleApi.middleware,
      invitationHistoryApi.middleware,
      forceUpdateMaintenanceApi.middleware,
      presentRequestApi.middleware,
      userPointApi.middleware,
      userPointHistoryApi.middleware,
      eventApi.middleware,
      imageUpload.middleware,
      dailySummariesApi.middleware,
      ...middlewares,
    ],
    devTools: process.env.NODE_ENV !== 'production',
    enhancers,
  });
}
