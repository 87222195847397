import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import Fab, { FabProps } from '@mui/material/Fab';

interface IProps extends FabProps {}

const QuickAddButton: React.FC<IProps> = ({ ...props }) => {
  return (
    <Fab
      {...props}
      sx={{
        width: '30px',
        height: '30px',
        minHeight: 'unset',
        boxShadow: 'unset',
        zIndex: 99,
        '.sum-icon': { fontSize: '1.2rem' },
      }}
      color={'inherit'}
    >
      <AddIcon className={'sum-icon'} />
    </Fab>
  );
};

export default QuickAddButton;
