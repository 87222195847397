/**
 *
 * EventAddPage
 *
 */
import { Layout } from '../../../components/Layout';
import { ToolBar } from '../../../components/ToolBar';
import Box from '@mui/material/Box';
import { DivWrap } from '../../../components/DivWrap';
import React, { useState } from 'react';
import Button from '@mui/material/Button';
import { useCommonSlice } from '../../../../store/commonSlice';
import { useDispatch } from 'react-redux';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  Box35,
  Box70,
  GridContainer,
  GridItem4,
  GridItem8,
  LBox,
  TitlePink,
} from '../../../components/Common/common';
import { Switch, Table, TableBody, TableHead, TableRow } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import TextInput from '../../../components/Form/TextInput';
import SelectInput from '../../../components/Form/SelectInput';
import LTableHead from 'app/components/DataTable/LTableHead';
import SurveyAddSample from './DataAddTemplate';
import { useCreateSurveyMutation } from 'services/surveyApi';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { RouteModal } from 'app/components/RouteModal';
import AddFormQuestion, { QUESTION_FORMAT_OPTIONS } from './AddFormQuestion';
import EditFormQuestion from './EditFormQuestion';
import { survey_question } from '../interface';
import QuickAddButton from '../../../components/Button/QuickAddButton';
import ImageUploadV from '../../../components/Form/ImageUploadV';
import useMessage from '../../../../utils/customHooks/useMessage';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import useIsMobile from '../../../../utils/customHooks/useIsMobile';
import useFocusError from '../../../../utils/customHooks/useFocusError';
import { toast } from 'react-toastify';
import uuid from 'react-uuid/uuid';
import ActionButton from '../../../components/Button/ActionButton';

interface Props {}

const validateQuestions = yup.object().shape({
  answer_limit: yup
    .number()
    .min(0, '回答上限が無効です')
    .typeError('回答上限が無効です')
    .required('回答上限がが必須です'),
  form_hidden_flag: yup
    .number()
    .typeError('必須フラグが無効です')
    .required('必須フラグが必須です'),
  title: yup
    .string()
    .max(255, 'タイトルが無効です')
    .required('タイトルが必須です'),
});

export function SurveyAddPage(props: Props) {
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState<any>(null);
  const [questions, setQuestions] = useState<Array<survey_question>>([]);

  const isMobile = useIsMobile();

  const { actions: commonActions } = useCommonSlice();
  const dispatch = useDispatch();
  const [createSurvey, { isLoading, isSuccess, isError, error }] =
    useCreateSurveyMutation();

  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validateQuestions),
  });
  const {
    setFocus,
    formState: { errors },
  } = methods;

  useMessage(isLoading, isError, isSuccess, error, false, '/survey');

  useFocusError(errors, setFocus);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  //table function
  const result = React.useMemo(() => {
    let temp = [...questions];

    if (orderBy !== null) {
      temp.sort((a, b) => {
        if (b[orderBy] < a[orderBy]) {
          return order === 'asc' ? -1 : 1;
        }
        if (b[orderBy] > a[orderBy]) {
          return order === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }

    return questions;
  }, [questions, orderBy, order]);

  const openModal = () => {
    dispatch(
      commonActions.toggleModal({
        actionModal: 'Add',
        dataAttach: null,
      }),
    );
  };

  const onSubmit: SubmitHandler<any> = data => {
    data.form_hidden_flag = data.form_hidden_flag === 1;

    let clonedArray = JSON.parse(JSON.stringify(questions));
    if (clonedArray.length > 0) {
      clonedArray.map(item => {
        item.question_flag = item.question_flag === 1;
        item.question_hidden_flag = item.question_hidden_flag === 1;
        const index = QUESTION_FORMAT_OPTIONS.findIndex(
          i => item.question_format === i.value,
        );
        if (index > -1) {
          item.question_format = QUESTION_FORMAT_OPTIONS[index].value;
        }
      });
      data.questions = clonedArray;
      createSurvey(JSON.stringify(data));
    } else {
      toast.error('フォーム質問内容が必須です', {
        position: 'bottom-left',
      });
    }
  };

  const onSubmitQuestionForm = (dataQuestions: any) => {
    const nData: Array<survey_question> = [...questions, dataQuestions];
    setQuestions(nData);
  };

  const onSubmitEditQuestionForm = (dataQuestions: survey_question) => {
    const nQuestions = [...questions];
    if (nQuestions.length > 0) {
      nQuestions.map((item: survey_question, index: number) => {
        if (item.question_no === dataQuestions.question_no) {
          nQuestions[index] = dataQuestions;
        }
      });
    }
    setQuestions(nQuestions);
  };

  const deleteQuestion = id => {
    const nQuestions = [...questions];
    if (nQuestions.length > 0) {
      const findIndex = nQuestions.findIndex(item => item.question_no === id);
      if (findIndex !== -1) {
        nQuestions.splice(findIndex, 1);
        setQuestions(nQuestions);
      }
    }
  };

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>, index) => {
    const temp = questions.map((item, key) =>
      index === key
        ? { ...item, question_flag: event.target.checked ? 1 : 0 }
        : item,
    );
    setQuestions(temp);
  };

  const handleSwitch2 = (event: React.ChangeEvent<HTMLInputElement>, index) => {
    const temp = questions.map((item, key) =>
      index === key
        ? { ...item, question_hidden_flag: event.target.checked ? 1 : 0 }
        : item,
    );
    setQuestions(temp);
  };

  return (
    <Layout title={'フォーム管理'}>
      <Box sx={{ overflow: 'hidden' }}>
        <ToolBar
          title={'フォーム管理'}
          noSearchPanel={true}
          noFilterPanel={true}
          btnBack={true}
          hasRoute={'/survey'}
        />
        <DivWrap>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <LBox>
                <TitlePink>フォーム基本</TitlePink>
                <Stack
                  mt={4}
                  pl={4}
                  pb={4}
                  pr={{ md: 0, xs: 4 }}
                  rowGap={{ md: 2, xs: 5 }}
                >
                  <GridContainer>
                    <GridItem4>
                      <InputLabel htmlFor={'title'}>タイトル</InputLabel>
                    </GridItem4>
                    <GridItem8>
                      <Box35>
                        <TextInput
                          name={'title'}
                          labelName={'イベント名'}
                          showLabel={false}
                        />
                      </Box35>
                    </GridItem8>
                  </GridContainer>

                  <GridContainer mt={5}>
                    <GridItem4>
                      <Stack
                        flexDirection={'column'}
                        justifyContent={'space-between'}
                        height={'100%'}
                      >
                        <InputLabel htmlFor={'top_url'}>
                          フォームトップ画像
                        </InputLabel>
                        {!isMobile && (
                          <InputLabel htmlFor={'top_url'}>画像URL</InputLabel>
                        )}
                      </Stack>
                    </GridItem4>
                    <GridItem8>
                      <Box70>
                        <ImageUploadV
                          limit={1}
                          name={'top_url'}
                          showLabel={false}
                          labelName={'フォームトップ画像'}
                          showLabelTextInput={false}
                        />
                      </Box70>
                    </GridItem8>
                  </GridContainer>

                  <GridContainer mt={5}>
                    <GridItem4>
                      <Stack
                        flexDirection={'column'}
                        justifyContent={'space-between'}
                        height={'100%'}
                      >
                        <InputLabel htmlFor={'bottom_url'}>
                          フォームボトム画像
                        </InputLabel>
                        {!isMobile && (
                          <InputLabel htmlFor={'bottom_url'}>
                            画像URL
                          </InputLabel>
                        )}
                      </Stack>
                    </GridItem4>
                    <GridItem8>
                      <Box70>
                        <ImageUploadV
                          limit={1}
                          name={'bottom_url'}
                          labelName={'フォームボトム画像'}
                          showLabel={false}
                          showLabelTextInput={false}
                        />
                      </Box70>
                    </GridItem8>
                  </GridContainer>

                  <GridContainer>
                    <GridItem4>
                      <InputLabel htmlFor={'description'}>
                        フォーム概要説明
                      </InputLabel>
                    </GridItem4>
                    <GridItem8>
                      <Box70>
                        <TextInput
                          name={'description'}
                          labelName={'イベント詳細'}
                          showLabel={false}
                          multiline={true}
                        />
                      </Box70>
                    </GridItem8>
                  </GridContainer>

                  <GridContainer>
                    <GridItem4>
                      <InputLabel htmlFor={'answer_limit'}>回答上限</InputLabel>
                    </GridItem4>
                    <GridItem8>
                      <Box70>
                        <TextInput
                          name={'answer_limit'}
                          labelName={'イベント詳細'}
                          showLabel={false}
                        />
                      </Box70>
                    </GridItem8>
                  </GridContainer>

                  <GridContainer>
                    <GridItem4>
                      <InputLabel htmlFor={'form_hidden_flag'}>
                        非表示フラグ
                      </InputLabel>
                    </GridItem4>
                    <GridItem8>
                      <Box35>
                        <SelectInput
                          name={'form_hidden_flag'}
                          labelName={'非表示フラグ'}
                          showLabel={false}
                          options={[
                            { name: 'オン', value: 1 },
                            { name: 'オフ', value: 0 },
                          ]}
                        />
                      </Box35>
                    </GridItem8>
                  </GridContainer>
                </Stack>
              </LBox>
              <LBox sx={{ mt: 5 }}>
                <TitlePink pl={5}>フォーム質問内容</TitlePink>
                <Box sx={{ padding: 3 }}>
                  <TableContainer
                    sx={{
                      paddingBottom: 1,
                      maxHeight: 700,
                      overflowX: 'scroll',
                      '&::-webkit-scrollbar': {
                        height: '7px',
                      },
                      '&::-webkit-scrollbar-track': {
                        WebkitBoxShadow: 'inset 0 0 3px rgba(0,0,0,0.00)',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        borderRadius: '5px',
                        backgroundColor: 'rgba(0,0,0,.5)',
                        WebkitBoxShadow: '0 0 1px rgba(255,255,255,.5)',
                      },
                    }}
                  >
                    <Table
                      stickyHeader={true}
                      aria-sort={'none'}
                      aria-label="フォーム質問内容"
                    >
                      <TableHead>
                        <TableRow>
                          {SurveyAddSample.map(item => (
                            <LTableHead
                              key={uuid()}
                              field={item.field}
                              headerName={item.headerName}
                              createSortHandler={handleRequestSort}
                              orderBy={''}
                              order={'desc'}
                            />
                          ))}
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {result.map((row, index) => (
                          <TableRow key={uuid()}>
                            <TableCell component="th" scope="row">
                              {row.question_no}
                            </TableCell>
                            <TableCell>{row.question_content}</TableCell>
                            <TableCell>{row.question_format}</TableCell>
                            <TableCell>{row.question_choices}</TableCell>
                            <TableCell>
                              <Switch
                                onChange={e => handleSwitch(e, index)}
                                checked={row.question_flag === 1}
                              />
                            </TableCell>
                            <TableCell>
                              <Switch
                                onChange={e => handleSwitch2(e, index)}
                                checked={row.question_hidden_flag === 1}
                              />
                            </TableCell>
                            <TableCell>
                              <ActionButton data={row} type={'Edit'} />
                            </TableCell>
                            <TableCell>
                              <IconButton
                                onClick={() => deleteQuestion(row.question_no)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                    <Box mt={2} ml={2}>
                      <QuickAddButton onClick={openModal} />
                    </Box>
                  </TableContainer>
                </Box>
              </LBox>

              <Box
                sx={{ marginTop: 4, display: 'flex', justifyContent: 'center' }}
              >
                <Button
                  variant={'contained'}
                  type={'submit'}
                  className="btnSubmit"
                  sx={{
                    backgroundColor: '#233ED3',
                    alignSelf: 'center',
                    minWidth: 300,
                  }}
                >
                  設定完了
                </Button>
              </Box>
            </form>
          </FormProvider>
        </DivWrap>
        <RouteModal
          addForm={
            <AddFormQuestion
              handleSubmit={onSubmitQuestionForm}
              questions={questions}
            />
          }
          editForm={
            <EditFormQuestion
              handleSubmit={onSubmitEditQuestionForm}
              questions={questions}
            />
          }
        />
      </Box>
    </Layout>
  );
}
