export function CheckNull(data) {
  if (typeof data !== 'undefined' && data !== null) {
    if (typeof data === 'boolean') {
      return data ? 1 : 0;
    } else {
      return data;
    }
  } else {
    return undefined;
  }
}
