import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { Controller, useFormContext } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';

interface options {
  value: string | number;
  name: string;
}

interface IProps {
  name: string;
  labelName: string;
  showLabel: boolean;
  options: Array<options>;
  defaultValue?: any;
  disable?: boolean;
}

const SelectInput: React.FC<IProps> = ({
  labelName,
  showLabel,
  name,
  options,
  defaultValue,
  disable = false,
  ...otherProps
}) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();
  useEffect(() => {
    if (defaultValue !== '' && defaultValue !== undefined) {
      setValue(name, defaultValue);
    }
  }, []);
  return (
    <Controller
      name={name}
      defaultValue={defaultValue ? defaultValue : ''}
      control={control}
      render={({ field: { ref, ...field } }) => (
        <TextField
          {...field}
          {...otherProps}
          inputRef={ref}
          select
          variant={'standard'}
          fullWidth
          label={showLabel && labelName}
          error={!!errors[name]}
          helperText={errors[name]?.message}
          sx={{ marginTop: 2 }}
        >
          {options.map((item, index) => (
            <MenuItem key={index} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
};

export default SelectInput;
