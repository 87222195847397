/**
 *
 * LogoutPage
 *
 */
import * as React from 'react';
import { useHistory } from 'react-router-dom';

interface Props {}

export function LogoutPage(props: Props) {
  const history = useHistory();
  localStorage.removeItem('accessToken');
  history.push('/login');
  return <p>Logout</p>;
}
