import Stack from '@mui/material/Stack';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import { useController, useForm } from 'react-hook-form';
import Box from '@mui/material/Box';
import { FormHelperText, IconButton } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

interface IProps {
  limit: number;
  name: string;
  labelName: string;
  showLabel: boolean;
  edit?: string;
  actionUpload: any;
}

const FileUpload: React.FC<IProps> = ({
  limit,
  name,
  labelName,
  showLabel,
  edit,
  actionUpload,
}) => {
  const {
    register,
    watch,
    control,
    formState: { errors, isSubmitting },
  } = useForm();

  React.useEffect(() => {
    const subscription = watch(value => {
      if (value) {
        const formData = new FormData();
        formData.append('file', value.file);
        actionUpload(formData);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const [singleFile, setSingleFile] = useState<File[]>([]);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { field } = useController({ control, name });

  const onDragEnter = () => wrapperRef.current?.classList.add('dragover');
  const onDragLeave = () => wrapperRef.current?.classList.remove('dragover');

  const onFileDrop = useCallback(
    (e: React.SyntheticEvent<EventTarget>) => {
      const target = e.target as HTMLInputElement;
      if (!target.files) return;

      if (limit === 1) {
        const newFile = Object.values(target.files).map((file: File) => file);
        if (singleFile.length >= 1) return alert('Only a single image allowed');
        const updatedSingleList = [...singleFile, ...newFile];
        setSingleFile(updatedSingleList);
        field.onChange(updatedSingleList[0]);
      }
    },
    [field, limit, singleFile],
  );

  const fileSingleRemove = (file: File) => {
    const updatedList = [...singleFile];
    updatedList.splice(singleFile.indexOf(file), 1);
    setSingleFile(updatedList);
  };

  const calcSize = (size: number) => {
    return size < 1000000
      ? `${Math.floor(size / 1000)} KB`
      : `${Math.floor(size / 1000000)} MB`;
  };

  useEffect(() => {
    if (isSubmitting) {
      setSingleFile([]);
    }
  }, [isSubmitting]);
  return (
    <Stack flexDirection={'row'} flexWrap={'wrap'} spacing={1}>
      <Typography sx={{ width: '100%' }}>{showLabel && labelName}</Typography>
      <Box
        sx={{
          backgroundColor: '#fff',
          position: 'relative',
          padding: '20px 10px',
          width: '100%',
          border: '1px solid #707070',
        }}
        ref={wrapperRef}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDragLeave}
      >
        <Typography variant="body2" component="p" color={'primary'}>
          行フォーマット
        </Typography>
        <Typography variant={'body2'}>
          タイトル\tカテゴリ\tGifteeトークン\t画像URL\t詳細説明\tポイント\t注意事項\t配信開始日\t配信終了日\t交換コード\t交換上限\t特集フラグ\t掲載順序
        </Typography>
        <Typography variant={'body2'} mt={3}>
          ※ 詳細説明、注意事項は任意
        </Typography>
        <input
          {...register(name)}
          type="file"
          onChange={onFileDrop}
          accept=".xlsx, .xls, .csv, .tsv"
          style={{
            opacity: 0,
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            cursor: 'pointer',
          }}
        />
      </Box>
      {singleFile.length > 0 &&
        singleFile.map((item, index) => {
          return (
            <Box
              key={index}
              sx={{
                position: 'relative',
                flexGrow: '1',
              }}
            >
              <Box display={'flex'} alignItems={'center'}>
                <InsertDriveFileIcon fontSize={'large'} />
                <Box sx={{ ml: 1 }}>
                  <Typography>{item.name}</Typography>
                  <Typography variant="body2">{calcSize(item.size)}</Typography>
                </Box>
              </Box>
              <IconButton
                onClick={() => {
                  fileSingleRemove(item);
                }}
                sx={{
                  color: '#df2c0e',
                  position: 'absolute',
                  right: '0',
                  top: '-10px',
                }}
              >
                <HighlightOffIcon />
              </IconButton>
            </Box>
          );
        })}
      <FormHelperText sx={{ my: 1, width: '100%' }} error={!!errors[name]}>
        {errors[name] ? errors[name].message : ''}
      </FormHelperText>
    </Stack>
  );
};

export default FileUpload;
