import { CommonState } from './types';
import { createSlice } from '../../utils/@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from '../../utils/redux-injectors';
import { commonSliceSaga } from './saga';

export const initialState: CommonState = {
  isOpen: false,
  actionModal: '',
  dataAttach: null,
  dataAttachTemp: null,
  queryFilter: '',
  dataSearch: [],
  menuExpanded: [],
  menuSelected: '',
};

const slice = createSlice({
  name: 'commonSlice',
  initialState,
  reducers: {
    toggleModal(state, action: PayloadAction<any>) {
      state.isOpen = !state.isOpen;
      state.actionModal = action.payload.actionModal;
      state.dataAttach = action.payload.dataAttach;
    },
    closeModal(state) {
      state.isOpen = false;
      state.actionModal = '';
      state.dataAttach = null;
    },
    setQueryFilter(state, action: PayloadAction<any>) {
      state.queryFilter = action.payload;
    },
    setDataSearch(state, action: PayloadAction<any>) {
      state.dataSearch = action.payload;
    },
    setMenuActive(state, action: PayloadAction<string>) {
      state.menuSelected = action.payload;
      if (state.menuExpanded.indexOf(action.payload) === -1) {
        state.menuExpanded.push(action.payload);
      } else {
        const index = state.menuExpanded.findIndex(
          item => item === action.payload,
        );
        state.menuExpanded.splice(index, 1);
      }
    },
    setDataEdit(state, action: PayloadAction<any>) {
      state.dataAttach = action.payload.dataAttach;
      state.dataAttachTemp = action.payload.dataAttach;
    },
  },
});

export const { actions: commonSliceActions } = slice;

export const useCommonSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: commonSliceSaga });
  return { actions: slice.actions };
};
