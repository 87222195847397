import * as yup from 'yup';
import { useMemo } from 'react';

export function useValidation(eventType) {
  return useMemo(() => {
    let valid = yup.object().shape({
      event_title: yup
        .string()
        .max(255, 'イベント名が無効です')
        .required('イベント名が必須です'),
      event_member_type: yup
        .number()
        .min(1)
        .max(3)
        .typeError('メンバー種類が無効です')
        .required('メンバー種類が必須です'),
      target_birthday_from: yup
        .number()
        .typeError('誕生日対象者が無効です')
        .integer('誕生日対象者が無効です')
        .required('誕生日対象者が必須です'),
      target_birthday_to: yup
        .number()
        .typeError('誕生日対象者が無効です')
        .integer('誕生日対象者が無効です')
        .required('誕生日対象者が必須です')
        .test('birthday', '誕生日対象者が無効です', function (value) {
          // @ts-ignore
          return this.parent.target_birthday_from < value;
        }),
      target_gender: yup
        .number()
        .typeError('性別 対象者が無効です')
        .integer('性別 対象者が無効です')
        .required('性別 対象者が必須です'),
      target_address: yup
        .number()
        .typeError('居住地 対象者が無効です')
        .integer('居住地 対象者が無効です')
        .required('居住地 対象者が必須です'),
      event_hashtag: yup.string().required('ハッシュタグが必須です'),
      event_period_start: yup
        .string()
        .nullable()
        .required('イベント開始日が必須です'),
      event_period_end: yup
        .string()
        .required('イベント終了日が必須です')
        .nullable()
        .test(
          'イベント終了日',
          'イベント終了日が無効です',
          function (value, context) {
            if (value) {
              const time1 = new Date(value);
              const time2 = new Date(context.parent.event_period_start);
              return time2 < time1;
            }
            return true;
          },
        ),
      event_daily_time_start: yup
        .string()
        .nullable()
        .required('イベント間隔開始時間が必須です'),
      event_daily_time_end: yup
        .string()
        .nullable()
        .required('イベント間隔終了時間が必須です')
        .test(
          'イベント間隔終了',
          'イベント間隔終了が無効です',
          function (value, context) {
            if (value) {
              const time1 = new Date(value);
              const time2 = new Date(context.parent.event_daily_time_start);
              return time2 < time1;
            }
            return true;
          },
        ),
      event_action_interval: yup
        .number()
        .min(0)
        .max(4)
        .typeError('イベント間隔が無効です')
        .required('イベント間隔が必須です'),
      event_suspended: yup
        .number()
        .min(0)
        .max(1)
        .typeError('イベント有効フラグ 無効です')
        .required('イベント有効フラグ が必須です'),
      event_banner_prob: yup
        .number()
        .min(0, 'フィルター前表示確率が無効です')
        .max(100, 'フフィルター前表示確率が無効です')
        .typeError('フィルター前表示確率が無効です')
        .required('フィルター前表示確率が必須です'),
      event_show_cost: yup
        .number()
        .min(0, 'イベント表示コストが無効です')
        .typeError('イベント表示コストが無効です')
        .required('イベント表示コストが必須です'),
      expire_type: yup
        .number()
        .min(0, 'ポイント有効期限種類が無効です')
        .typeError('ポイント有効期限種類が無効です')
        .required('ポイント有効期限種類が必須です'),
      event_type: yup
        .number()
        .integer('イベント種類が無効です')
        .typeError('イベント種類が無効です')
        .required('イベント種類が必須です'),
    });
    if (eventType === 1) {
      valid = valid.concat(
        yup.object().shape({
          point_rule_id: yup
            .number()
            .min(0, 'ポイントルールIDが無効です')
            .typeError('ポイントルールIDが必須です')
            .required('ポイントルールIDが必須です'),
          modal_id: yup
            .number()
            .min(0, 'モーダルIDが無効です')
            .typeError('モーダルIDが必須です')
            .required('モーダルIDが必須です'),
        }),
      );
    }
    if (eventType === 2) {
      valid = valid.concat(
        yup.object().shape({
          limit_lottery_all_user_per_hour: yup
            .number()
            .min(0, '一人当たりの抽選上限回数が無効です')
            .typeError('一人当たりの抽選上限回数が無効です')
            .required('一人当たりの抽選上限回数が必須です'),
          limit_lottery_point_one_user_per_hour: yup
            .number()
            .min(0, 'ユーザー全体のの抽選上限回数が無効です')
            .typeError('ユーザー全体のの抽選上限回数が必須です')
            .required('ユーザー全体のの抽選上限回数が必須です'),
          point_rule_id: yup
            .number()
            .min(0, 'ポイントルールIDが無効です')
            .typeError('ポイントルールIDが必須です')
            .required('ポイントルールIDが必須です'),
          banner_id: yup
            .number()
            .min(0, 'バナーIDが無効です')
            .typeError('バナーIDが必須です')
            .required('バナーIDが必須です'),
          modal_id: yup
            .number()
            .min(0, 'モーダルIDが無効です')
            .typeError('モーダルIDが必須です')
            .required('モーダルIDが必須です'),
        }),
      );
    }
    if (eventType === 3) {
      valid = valid.concat(
        yup.object().shape({
          form_id: yup
            .number()
            .min(0, 'フォームIDが無効です')
            .typeError('フォームIDが無効です')
            .required('フォームIDが必須です'),
          point_rule_id: yup
            .number()
            .min(0, 'ポイントルールIDが無効です')
            .typeError('ポイントルールIDが必須です')
            .required('ポイントルールIDが必須です'),
          modal_id: yup
            .number()
            .min(0, 'モーダルIDが無効です')
            .typeError('モーダルIDが必須です')
            .required('モーダルIDが必須です'),
        }),
      );
    }
    if (eventType === 4) {
      valid = valid.concat(
        yup.object().shape({
          inv_need_days: yup
            .number()
            .min(0, '招待完了までの連続ログイン日数が無効です')
            .typeError('招待完了までの連続ログイン日数が無効です')
            .required('招待完了までの連続ログイン日数が必須です'),
          invited_user_point: yup
            .number()
            .min(0, '招待された側の取得ポイントが無効です')
            .typeError('招待された側の取得ポイントが無効です')
            .required('招待された側の取得ポイントが必須です'),
          inv_number: yup
            .number()
            .min(0, '同時招待中人数が無効です')
            .typeError('同時招待中人数が無効です')
            .required('同時招待中人数が必須です'),
          inv_repeat_flag: yup
            .number()
            .min(0, '循環フラグが無効です')
            .max(1, '循環フラグが無効です')
            .typeError('循環フラグが無効です')
            .required('循環フラグが必須です'),
        }),
      );
    }
    if (eventType === 5) {
      valid = valid.concat(
        yup.object().shape({
          banner_id: yup
            .number()
            .min(0, 'バナーIDが無効です')
            .typeError('バナーIDが必須です')
            .required('バナーIDが必須です'),
        }),
      );
    }
    if (eventType === 6) {
      valid = valid.concat(
        yup.object().shape({
          modal_id: yup
            .number()
            .min(0, 'モーダルIDが無効です')
            .typeError('モーダルIDが必須です')
            .required('モーダルIDが必須です'),
        }),
      );
    }

    return valid;
  }, [eventType]);
}
