/**
 *
 * Layout
 *
 */
import * as React from 'react';
import { Header } from '../Header';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import BottomNavigation from '@mui/material/BottomNavigation';
import { Helmet } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const themeOptions = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#3f51b5',
    },
    secondary: {
      main: '#f50057',
    },
    error: {
      main: '#f44336',
    },
    warning: {
      main: '#ff9800',
    },
    info: {
      main: '#d7d7d7',
    },
    success: {
      main: '#4caf50',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

interface Props {
  children: React.ReactNode;
  title: string;
}

export const Layout: React.FC<Props> = ({ children, title }) => {
  return (
    <ThemeProvider theme={themeOptions}>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content="A Boilerplate application homepage" />
      </Helmet>
      <Box>
        <Header />
        <main>{children}</main>
        <BottomNavigation component={'footer'}>&copy;2022</BottomNavigation>
      </Box>
      <ToastContainer />
    </ThemeProvider>
  );
};
