/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { HomePage } from './pages/HomePage/Loadable';
import { NotFoundPage } from './components/NotFoundPage/Loadable';
import { useTranslation } from 'react-i18next';
import { LoginPage } from './pages/LoginPage';
import { UserPage } from './pages/UserPage/Loadable';
import { BannerPage } from './pages/BannerPage/Loadable';
import { ModalPage } from './pages/ModalPage/Loadable';
import { CategoryPage } from './pages/CategoryPage/Loadable';
import { PresentPage } from './pages/PresentPage/Loadable';
import { PresentAddPage } from './pages/PresentPage/PresentAddPage/Loadable';
import { PresentRequestPage } from './pages/PresentRequestPage/Loadable';
import { PointRulePage } from './pages/PointRulePage/Loadable';
import { EventPage } from './pages/EventPage';
import { DailyReportPage } from './pages/DailyReportPage/Loadable';
import { UserPointPage } from './pages/UserPointPage/Loadable';
import { UserPointHistoryPage } from './pages/UserPointHistoryPage/Loadable';
import { InvitationHistoryPage } from './pages/InvitationHistoryPage/Loadable';
import { SurveyPage } from './pages/SurveyPage';
import { ExportPage } from './pages/ExportPage/Loadable';
import { EventAddPage } from './pages/EventPage/EventAddPage/Loadable';
import { SurveyAddPage } from './pages/SurveyPage/SurveyAddPage';
import { useCommonSlice } from '../store/commonSlice';
import { SurveyEditPage } from './pages/SurveyPage/SurveyEditPage';
import { PresentEditPage } from './pages/PresentPage/PresentEditPage/Loadable';
import PrivateRoute from '../utils/auth/PrivateRoute';
import { LogoutPage } from './pages/LogoutPage';
import { EventEditPage } from './pages/EventPage/EventEditPage';
import isAuth from '../utils/auth/IsAuth';
import { TestPage } from './pages/TestPage';
import { ForceUpdateMaintenance } from './pages/ForceUpdateMaintenancePage';
import { RedirectPage } from './pages/RedirectPage';
import { WallReportPage } from './pages/WallReportPage';
import { ModifyTelInputCountPage } from './pages/SmsAuthPage';
import { OfferwallPage } from './pages/OfferwallPage';
import { OfferwallModifyPage } from './pages/OfferwallModifyPage';
import { StatisticsPage } from './pages/StatisticsPage';
import { ImportPage } from './pages/ImportPage';
import { ImportHistoryPage } from './pages/ImportHistoryPage';


export function App() {
  const { i18n } = useTranslation();
  const { actions } = useCommonSlice();
  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - Voice 管理ツール"
        defaultTitle="Voice 管理ツール"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="Voice 管理ツール" />
        <meta name="robots" content='noindex,nofollow,noarchive' />
      </Helmet>
      <Switch>
        <Route exact path="/login" component={LoginPage} />

        <PrivateRoute path={'/banner'}>
          <BannerPage />
        </PrivateRoute>

        <PrivateRoute path={'/top-view'}>
          <HomePage />
        </PrivateRoute>

        <PrivateRoute path={'/users'}>
          <UserPage />
        </PrivateRoute>

        <PrivateRoute path={'/modal'}>
          <ModalPage />
        </PrivateRoute>

        <PrivateRoute path={'/category'}>
          <CategoryPage />
        </PrivateRoute>

        <PrivateRoute path={'/present/add'}>
          <PresentAddPage />
        </PrivateRoute>

        <PrivateRoute path={'/present/edit/:present_id'}>
          <PresentEditPage />
        </PrivateRoute>

        <PrivateRoute path={'/present'}>
          <PresentPage />
        </PrivateRoute>

        <PrivateRoute path={'/present-request'}>
          <PresentRequestPage />
        </PrivateRoute>

        <PrivateRoute path={'/point-rule'}>
          <PointRulePage />
        </PrivateRoute>

        <PrivateRoute path={'/invitation-history'}>
          <InvitationHistoryPage />
        </PrivateRoute>

        <PrivateRoute path={'/event/edit/:event_id'}>
          <EventEditPage />
        </PrivateRoute>

        <PrivateRoute path={'/event/add'}>
          <EventAddPage />
        </PrivateRoute>

        <PrivateRoute path={'/event'}>
          <EventPage />
        </PrivateRoute>

        <PrivateRoute path={'/daily_summaries'}>
          <DailyReportPage />
        </PrivateRoute>

        <PrivateRoute path={'/user-point-history/:member_type/:id'}>
          <UserPointHistoryPage />
        </PrivateRoute>

        <PrivateRoute path={'/user-point'}>
          <UserPointPage />
        </PrivateRoute>

        <PrivateRoute path={'/survey/add'}>
          <SurveyAddPage />
        </PrivateRoute>

        <PrivateRoute path={'/survey/edit/:survey_id'}>
          <SurveyEditPage />
        </PrivateRoute>

        <PrivateRoute path={'/survey'}>
          <SurveyPage />
        </PrivateRoute>

        <PrivateRoute path={'/export'}>
          <ExportPage />
        </PrivateRoute>

        <PrivateRoute path={'/ForceUpdateMaintenance'}>
          <ForceUpdateMaintenance />
        </PrivateRoute>

        <PrivateRoute path={'/SmsAuthPage'}>
          <ModifyTelInputCountPage />
        </PrivateRoute>

        <PrivateRoute path={'/OfferwallPage'}>
          <OfferwallPage />
        </PrivateRoute>

        <PrivateRoute path={'/OfferwallModifyPage'}>
          <OfferwallModifyPage />
        </PrivateRoute>

        <PrivateRoute path={'/WallReportPage'}>
          <WallReportPage />
        </PrivateRoute>

        <PrivateRoute path={'/StatisticsPage'}>
          <StatisticsPage />
        </PrivateRoute>

        <PrivateRoute path={'/ImportPage'}>
          <ImportPage />
        </PrivateRoute>

        <PrivateRoute path={'/ImportHistoryPage'}>
          <ImportHistoryPage />
        </PrivateRoute>
        
        <PrivateRoute path={'/test'}>
          <TestPage />
        </PrivateRoute>

        <PrivateRoute path={'/logout'}>
          <LogoutPage />
        </PrivateRoute>

        <Route exact={true} path={'/'}>
          {isAuth() ? <HomePage /> : <RedirectPage />}
        </Route>

        <Route exact={true} path={'*'} component={NotFoundPage} />
      </Switch>
    </BrowserRouter>
  );
}
