/**
 *
 * ToolBar
 *
 */
import * as React from 'react';
import { ChangeEvent, useState } from 'react';
import Toolbar from '@mui/material/Toolbar';
import { SelectChangeEvent } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FloatButton from '../Button/FloatButton';
import Stack from '@mui/material/Stack';
import IncreaseButton from '../Button/IncreaseButton';
import { styled } from '@mui/material/styles';
import useIsMobile from '../../../utils/customHooks/useIsMobile';
import FilterListIcon from '@mui/icons-material/FilterList';
import Button from '@mui/material/Button';
import { cubic1 } from '../../../styles/media';
import DateRangeFilter from '../DateRangeFilter/DateRangeFilter';
import useHasDate from '../../../utils/customHooks/useHasDate';
import ImportButton from '../Button/ImportButton';
import { DataTemplate } from '../../../utils/types/dataTemplate';

interface Props {
  title?: string;
  template?: DataTemplate[];
  totalPage?: number;
  currentPage?: number;
  rowPerPage?: number;
  fieldFilter?: string;
  search?: string;
  setCurrentPage?: (page: number) => void;
  handleSelectField?: (field: string) => void;
  handleSearch?: (search: string) => void;
  setTimeStart?: (value: number | null) => void;
  setTimeEnd?: (value: number | null) => void;
  hasImport?: boolean;
  hasRoute?: string;
  noAddButton?: boolean;
  noSearchPanel?: boolean;
  noFilterPanel?: boolean;
  btnBack?: boolean;
  totalNumber?: React.ReactNode;
}

export function ToolBar({
  title,
  hasRoute,
  hasImport,
  noSearchPanel,
  noFilterPanel,
  noAddButton,
  btnBack,
  totalPage,
  currentPage,
  setCurrentPage,
  rowPerPage,
  template,
  handleSearch,
  handleSelectField,
  fieldFilter,
  search,
  setTimeEnd,
  setTimeStart,
  totalNumber,
}: Props) {
  const [filterShow, setFilterShow] = useState<Boolean>(false);
  const isMobile = useIsMobile();

  const hasDate = useHasDate(template ? template : []).start !== '';

  let pagination = 0;
  if (totalPage && rowPerPage) {
    pagination = Math.ceil(totalPage / rowPerPage);
  }

  const handleTimeStart = (newValue: number | null) => {
    if (setTimeStart) {
      setTimeStart(newValue);
    }
  };

  const handleTimeEnd = (newValue: number | null) => {
    if (setTimeEnd) {
      setTimeEnd(newValue);
    }
  };

  const handleChangePage = (event: SelectChangeEvent<number>) => {
    if (setCurrentPage) {
      setCurrentPage(+event.target.value);
    }
  };

  const handleSelectFieldTool = (event: SelectChangeEvent<any>) => {
    if (handleSelectField) {
      handleSelectField(event.target.value);
    }
  };

  const handleSearchTool = (event: ChangeEvent<any>) => {
    if (handleSearch) {
      handleSearch(event.target.value);
    }
  };

  return (
    <CustomToolbar
      elevation={6}
      sx={{ position: 'fixed', top: { xs: '60px', md: '64px' }, width: '100%' }}
    >
      <Toolbar
        sx={{
          flexWrap: { xs: 'wrap', md: 'no-wrap' },
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant={'body1'}
          component={'h2'}
          fontSize={{ xs: '1rem' }}
          fontWeight={'bold'}
        >
          {title &&
            title.split('\n').map(function (item, idx) {
              return (
                <span key={idx}>
                  {item}
                  <br />
                </span>
              );
            })}
        </Typography>
        {isMobile && !noFilterPanel && (
          <Button
            variant={'text'}
            color={filterShow ? 'secondary' : 'primary'}
            size={'large'}
            onClick={() => setFilterShow(!filterShow)}
            startIcon={<FilterListIcon />}
          >
            Filter
          </Button>
        )}
        <FilterPanel
          className={filterShow ? 'show' : ''}
          flexGrow={1}
          mx={{ md: 2 }}
          direction={'row'}
        >
          {hasDate && (
            <DateRangeFilter
              handleTimeStart={handleTimeStart}
              handleTimeEnd={handleTimeEnd}
            />
          )}
          {/* Pagination */}
          {currentPage && setCurrentPage && (
            <Stack width={{ md: 150, xs: '40%' }} direction={'row'}>
              <IncreaseButton
                type={'de'}
                max={pagination}
                current={currentPage}
                handlerChange={setCurrentPage}
              />
              <FormControl fullWidth variant={'standard'}>
                <InputLabel id="fea_pagination">
                  <Typography variant={'body2'}>
                    {'Max Page: ' + pagination}
                  </Typography>
                </InputLabel>
                <Select
                  value={currentPage}
                  labelId="fea_pagination"
                  onChange={handleChangePage}
                >
                  {[...Array(pagination === 0 ? 1 : pagination)].map(
                    (item, index) => (
                      <MenuItem
                        key={index + 1}
                        value={index + 1}
                        style={{
                          justifyContent: 'center',
                          backgroundColor: 'white',
                          paddingTop: '10px',
                          paddingBottom: '10px',
                        }}
                      >
                        {index + 1}
                      </MenuItem>
                    ),
                  )}
                </Select>
              </FormControl>
              <IncreaseButton
                type={'in'}
                max={pagination}
                current={currentPage}
                handlerChange={setCurrentPage}
              />
            </Stack>
          )}
          {/* user point page */ totalNumber && totalNumber}
          {/* Filter search */}
          {!noSearchPanel && (
            <Stack
              width={{ md: 300, xs: '60%' }}
              ml={{ md: 'auto', xs: 'unset' }}
              direction={'row'}
              alignItems={'flex-end'}
              gap={'0 10px'}
            >
              <FormControl sx={{ width: '60%' }} fullWidth variant={'standard'}>
                <Select value={fieldFilter} onChange={handleSelectFieldTool}>
                  {template &&
                    template.map(
                      (item, index) =>
                        (item.filterable === undefined || item.filterable) &&
                        !item.type?.includes('date') && (
                          <MenuItem
                            key={index}
                            value={
                              item.filterField ? item.filterField : item.field
                            }
                          >
                            {item.filterName
                              ? item.filterName
                              : item.headerName}
                          </MenuItem>
                        ),
                    )}
                </Select>
              </FormControl>
              <TextField
                sx={{ width: '40%' }}
                value={search}
                onChange={handleSearchTool}
                variant={'standard'}
                label={'絞込テキスト'}
              />
            </Stack>
          )}
        </FilterPanel>
        {!noAddButton && <FloatButton hasRoute={hasRoute} btnBack={btnBack} />}
        {hasImport && <ImportButton />}
      </Toolbar>
    </CustomToolbar>
  );
}

const CustomToolbar = styled(Paper)`
  background-color: #f5f5f5;
  color: #333;
  z-index: 100;
`;

const FilterPanel = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    position: 'fixed',
    bottom: '-50%',
    left: 0,
    background: '#ddd',
    padding: '.8vw',
    width: '100%',
    gap: '3vw 0',
    flexWrap: 'wrap',
    transition: `bottom 300ms ${cubic1}`,
    '&.show': {
      bottom: '0',
    },
  },
}));
