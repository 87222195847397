import React, { useMemo } from 'react';
import { useGetCategoriesQuery } from '../../../services/categoryApi';
import { CircularProgress, Link } from '@mui/material';
import Typography from '@mui/material/Typography';

interface IProps {
  id: number;
  isLink?: boolean;
}

const CellCategory: React.FC<IProps> = ({ id, isLink, ...props }) => {
  const { data = [], isFetching, isError } = useGetCategoriesQuery('');
  const result = useMemo(() => {
    return data.find(item => item.categories_id === id);
  }, [isFetching, data]);

  if (!result) {
    return <CircularProgress />;
  }
  return (
    <>
      {isLink ? (
        <Link href={`/category?title=${result.categories_title}`}>
          {result.categories_title}
        </Link>
      ) : (
        <Typography>{result.categories_title}</Typography>
      )}
    </>
  );
};

export default CellCategory;
