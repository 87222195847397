import React from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { DialogContent } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextInput from '../../components/Form/TextInput';
import { useCommonSlice } from '../../../store/commonSlice';
import yup from '../../../utils/types/helpText';
import { useUpdateModalMutation } from '../../../services/modalApi';
import { SelectCommonStateDataAttach } from '../../../store/commonSlice/selectors';
import useMessage from '../../../utils/customHooks/useMessage';
import ImageUploadV from '../../components/Form/ImageUploadV';

const valid = yup.object().shape({
  title: yup
    .string()
    .max(255, 'タイトルが無効です')
    .required('タイトルが必須です'),
  image_url: yup
    .string()
    .max(1000, '画像URLが無効です')
    .required('画像URLが必須です'),
});

function EditForm() {
  const [updateModal, { isLoading, isSuccess, isError, error }] =
    useUpdateModalMutation();
  const dataAttach = useSelector(SelectCommonStateDataAttach);
  const { actions } = useCommonSlice();
  const dispatch = useDispatch();

  const methods = useForm<any>({ resolver: yupResolver(valid) });

  useMessage(isLoading, isError, isSuccess, error, true);

  const onSubmit: SubmitHandler<any> = data => {
    updateModal({ id: dataAttach.modals_id, body: JSON.stringify(data) });
  };
  return (
    <DialogContent>
      <Box component={'form'} onSubmit={methods.handleSubmit(onSubmit)}>
        <FormProvider {...methods}>
          <Stack spacing={3}>
            <TextInput
              name={'title'}
              labelName={'モーダル名'}
              defaultValue={dataAttach.modals_title}
              showLabel={true}
            />
            <TextInput
              name={'description'}
              labelName={'モーダル詳細'}
              defaultValue={dataAttach.modals_description}
              showLabel={true}
            />
            <TextInput
              name={'transition_url'}
              labelName={'遷移URL'}
              defaultValue={dataAttach.modals_transition_url}
              showLabel={true}
            />
            <ImageUploadV
              limit={1}
              name={'image_url'}
              labelName={'画像URL'}
              showLabel={true}
              showLabelTextInput={false}
              edit={dataAttach.modals_image_url}
            />
            <ImageUploadV
              limit={1}
              name={'gacha_image_url'}
              labelName={'ガチャアニメーション画像'}
              showLabel={true}
              showLabelTextInput={false}
              edit={dataAttach.modals_gacha_image_url}
            />
            <Stack flexDirection={'row'} columnGap={3}>
              <TextInput
                name={'button_ok'}
                labelName={'OKボタン文言'}
                defaultValue={dataAttach.modals_button_ok}
                showLabel={true}
              />
              <TextInput
                name={'button_ng'}
                labelName={'NGボタン文言'}
                defaultValue={dataAttach.modals_button_ng}
                showLabel={true}
              />
            </Stack>
          </Stack>
          <Stack
            flexDirection={'row'}
            justifyContent={'flex-end'}
            columnGap={2}
            mt={4}
          >
            <Button
              type={'button'}
              sx={{ color: '#5B5A5A' }}
              onClick={() => dispatch(actions.closeModal())}
            >
              Cancel
            </Button>
            <Button type={'submit'}>Edit</Button>
          </Stack>
        </FormProvider>
      </Box>
    </DialogContent>
  );
}

export default EditForm;
