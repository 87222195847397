import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './baseQuery';

export interface IImportResponse {
    success: boolean;
}

export interface IAssignHistory {
    id: number;
    time: string;
    num: number;
    failed: number;
    name: string
}

export interface IImportHistoryByTime {
    user_id: number;
    mail: string;
    point: number;
    success: number;
    reason: string;
}

export const importApi = createApi({
    reducerPath: 'importApi',
    tagTypes: ['import'],
    baseQuery: axiosBaseQuery(),
    endpoints: (builder) => ({
        importCsv: builder.mutation<void, FormData>({
            query: (formData) => ({
                url: '/import',
                method: 'POST',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                providesTags: ['import'],
                transformResponse: (response: IImportResponse) => response,
            }),
        }),
        getAssignHistory: builder.query<IAssignHistory[], void>({
            query: params => ({
                url: '/import_history',
                method: 'GET',
            }),
            providesTags: ['import'],
            transformResponse: (response: IAssignHistory[]) => response,
        }),
        getHistoryByTime: builder.mutation<IImportHistoryByTime[], string>({
            query: (time) => ({
                url: '/import_history_by_time',
                method: 'POST',
                data: { time },
            }),
            invalidatesTags: ['import'],
            transformResponse: (response: IImportHistoryByTime[]) => response,
        }),
    }),
});

export const { useImportCsvMutation, useGetAssignHistoryQuery, useGetHistoryByTimeMutation } = importApi;
