import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './baseQuery';

export interface IPresentRequestResponse {
  presents_get_logs_create_at: string;
  presents_get_logs_user_id: number;
  presents_title: string;
  point_used_logs_point_used: number;
  user_email: string;
  presents_get_logs_status: number;
  user_delete_flag: number;
}
export const presentRequestApi = createApi({
  reducerPath: 'presentRequest_api',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['presentRequest'],
  endpoints: build => ({
    getPresentRequests: build.query<IPresentRequestResponse[], string>({
      query: params => ({
        url: `/present_get_logs${params && '?' + params}`,
        method: 'GET',
      }),
      transformResponse: (response: {
        data_response: IPresentRequestResponse[];
      }) => response.data_response,
      providesTags: ['presentRequest'],
    }),
  }),
});

export const { useGetPresentRequestsQuery } = presentRequestApi;
