import DialogContent from '@mui/material/DialogContent';
import React from 'react';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FileUpload from '../../components/Form/FileUpload';
import { useCommonSlice } from '../../../store/commonSlice';
import { useImportPresentMutation } from '../../../services/presentApi';
import { LinearProgress } from '@mui/material';

const ImportForm = props => {
  const [uploadFile, { isLoading, isSuccess, isError, error }] =
    useImportPresentMutation();
  const { actions } = useCommonSlice();
  const dispatch = useDispatch();
  return (
    <>
      <DialogContent>
        <Typography>
          Shift_JISのTSV(タブ区切りテキスト)ファイルを選択してください。
        </Typography>
        <FileUpload
          limit={1}
          name={'file'}
          labelName={''}
          showLabel={false}
          actionUpload={uploadFile}
        />
        <Stack
          flexDirection={'row'}
          justifyContent={'flex-end'}
          columnGap={2}
          mt={4}
        >
          <Button
            type={'button'}
            sx={{ color: '#5B5A5A' }}
            onClick={() => dispatch(actions.closeModal())}
          >
            Cancel
          </Button>
          <Button type={'submit'}>Import</Button>
        </Stack>
        {isLoading ? (
          <LinearProgress />
        ) : isError ? (
          <Typography>ファイルが無効です</Typography>
        ) : (
          isSuccess && (
            <Typography maxWidth={1}>ファイルアップロードは成功。</Typography>
          )
        )}
      </DialogContent>
    </>
  );
};

export default ImportForm;
