/**
 *
 * TestPage
 *
 */
import * as React from 'react';
import { Layout } from '../../components/Layout';
import { DivWrap } from '../../components/DivWrap';
import { RouteModal } from '../../components/RouteModal';
import AddForm from '../ModalPage/AddForm';
import EditForm from '../ModalPage/EditForm';
import { useGetEventListQuery } from '../../../services/eventApi';
import { useEffect } from 'react';

interface Props {}

export function TestPage(props: Props) {
  const { data } = useGetEventListQuery({ id: '3', params: '' });
  useEffect(() => {
    console.log(data);
  }, [data]);
  return (
    <>
      <Layout title={'モーダル設定'}>
        <DivWrap style={{ height: '500px', width: '100%' }}></DivWrap>
      </Layout>
      <RouteModal addForm={<AddForm />} editForm={<EditForm />} />
    </>
  );
}
/*
event_action_interval: 1
event_banner_prob: 1
event_daily_time_end: "23:59:59"
event_daily_time_start: "00:00:00"
event_description: "des4"
event_hashtag: "tag1, tag2"
event_member_type: 1
event_period_end: "2023/02/26"
event_period_start: "2019/02/26"
event_show_cost: 1
event_suspended: true
event_title: "titleDes4"
event_type: 4
expire_type: 1
inv_need_days: 1
inv_number: 1
inv_repeat_flag: true
invitation_no: 1
invited_user_point: 2
point_amount: 20
point_expire_date: 10
target_address: 1
target_birthday_from: 10
target_birthday_to: 50
target_gender: 1
target_hobby: 1
target_job: 1

 "event_action_interval": 1,
  "event_banner_prob": 1,
  "event_daily_time_end": "23:59:59",
  "event_daily_time_start": "00:00:00",
  "event_description": "des4",
  "event_member_type": 1,
  "event_period_end": "2023-02-26",
  "event_period_start": "2019-02-26",
  "event_show_cost": 1,
  "event_suspended": false,
  "event_title": "titleDes4",
  "event_type": 4,
  "expire_type": 1,
  "inv_need_days": 1,
  "inv_number": 1,
  "inv_point_amount": 100,
  "inv_repeat_flag": true,
  "invitation_id": 2,
  "invitation_no": 2,
  "invited_user_point": 50,
  "limit_lottery_all_user_per_hour":10,
  "limit_lottery_point_one_user_per_hour": 20,
  "point_expire_date": 10,
  "target_address": 1,
  "target_birthday_from": 10,
  "target_birthday_to": 50,
  "target_gender": 1,
  "target_hobby": 1,
  "target_job": 1,
  "trash_flag": true
 */
