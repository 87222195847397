/**
 *
 * 強制アップデート & メンテナンス画面
 *
 */
import { Container, Switch, Button, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import * as React from 'react';
import {
  IForceUpdateMaintenanceApi,
  useGetForceUpdateMaintenanceQuery,
  useUpdateForceUpdateMaintenanceMutation,
} from '../../../services/forceUpdateMaintenance';
import { DivWrap } from '../../components/DivWrap';
import { Layout } from '../../components/Layout';
import { ToolBar } from '../../components/ToolBar';

interface Props {}

export function ForceUpdateMaintenance(props: Props) {
  const title = '強制アップデート & メンテナンス';

  const { data, isFetching, isError, error } =
    useGetForceUpdateMaintenanceQuery('');
  const result = React.useMemo(() => {
    return data;
  }, [isFetching, data]);

  const [updateForceUpdateMaintenance, { data: updateData }] =
    useUpdateForceUpdateMaintenanceMutation();

  // ダイアログ用
  const [open, setOpen] = React.useState(false);
  const [androidOpen, setAndroidOpen] = React.useState(false);
  const [finalOpen, setFinalOpen] = React.useState(false);

  const testmessage = `ただいまメンテナンスのため一時サービスを停止しております。
  大変ご不便をおかけいたしますが、今しばらくお待ちください。
  
  【メンテナンス作業予定日時】
  　0000年00月00日（○）00：00
  　※メンテナンス作業が終了次第公開いたします。`;

  const [resData, setResData] = React.useState<IForceUpdateMaintenanceApi>({
    ios_min_version: '',
    ios_maintenance_flg: false,
    ios_maintenance_message: '',
    ios_is_for_review: false,
    ios_review_version: '',
    android_min_version: '',
    android_maintenance_flg: false,
    android_maintenance_message: '',
    android_is_for_review: false,
    android_review_version: '',
  });

  const [reqestData, setRequestData] =
    React.useState<IForceUpdateMaintenanceApi>({
      ios_min_version: '',
      ios_maintenance_flg: false,
      ios_maintenance_message: '',
      ios_is_for_review: false,
      ios_review_version: '',
      android_min_version: '',
      android_maintenance_flg: false,
      android_maintenance_message: '',
      android_is_for_review: false,
      android_review_version: '',
    });

  React.useEffect(() => {
    if (!result) return;
    console.log(result);
    setResData({
      ios_min_version: result.ios_min_version,
      ios_maintenance_flg: result.ios_maintenance_flg,
      ios_maintenance_message: result.ios_maintenance_message,
      ios_is_for_review: result.ios_is_for_review,
      ios_review_version: result.ios_review_version,
      android_min_version: result.android_min_version,
      android_maintenance_flg: result.android_maintenance_flg,
      android_maintenance_message: result.android_maintenance_message,
      android_is_for_review: result.android_is_for_review,
      android_review_version: result.android_review_version,
    });
    setRequestData({
      ios_min_version: result.ios_min_version,
      ios_maintenance_flg: result.ios_maintenance_flg,
      ios_maintenance_message: result.ios_maintenance_message,
      ios_is_for_review: result.ios_is_for_review,
      ios_review_version: result.ios_review_version,
      android_min_version: result.android_min_version,
      android_maintenance_flg: result.android_maintenance_flg,
      android_maintenance_message: result.android_maintenance_message,
      android_is_for_review: result.android_is_for_review,
      android_review_version: result.android_review_version
    });
  }, [result]);

  const edit = () => {
    setOpen(false);
    setAndroidOpen(false);
    setFinalOpen(false);
    console.log(reqestData);
    updateForceUpdateMaintenance(reqestData);
  };

  return (
    <>
      <Layout title={title}>
        <ToolBar
          title={title}
          noSearchPanel={true}
          noAddButton={true}
          noFilterPanel={true}
        />
        <DivWrap>
          <div style={{ width: '100%', display: 'flex' }}>
            {/* iOS */}
            <Container
              style={{
                width: '50%',
              }}
            >
              <div style={{ marginTop: 50 }}>
                <text
                  style={{
                    fontSize: 40,
                    borderBottom: '1px solid black',
                    color: '#006ee6',
                  }}
                >
                  iOS
                </text>
              </div>
              {/* 強制アップデート */}
              <div style={{ marginTop: 20 }}>
                <text style={{ fontSize: 25 }}>・強制アップデート</text>
                <div style={{ marginTop: 20 }}>
                  <TextField
                    id="filled-basic"
                    value={resData.ios_min_version}
                    label="最低バージョン"
                    disabled
                  />
                </div>
              </div>
              {/* メンテナンス */}
              <div style={{ marginTop: 20 }}>
                <text style={{ fontSize: 25 }}>・メンテナンスモード</text>
                <div style={{ marginTop: 20 }}>
                  <text style={{ marginLeft: 10, marginRight: 10 }}>
                    off/on
                  </text>
                  <Switch
                    checked={resData.ios_maintenance_flg}
                    inputProps={{ 'aria-label': 'controlled' }}
                    disabled
                  />
                </div>
                <div style={{ marginTop: 20 }}>
                  <div style={{ marginTop: 20 }}>
                    <TextField
                      id="filled-multiline-flexible"
                      label="メンテナンスメッセージ"
                      multiline
                      rows={10}
                      style={{ width: 500 }}
                      value={resData.ios_maintenance_message}
                      disabled
                    />
                  </div>
                </div>
              </div>
              {/*  審査中用モードバージョン */}
              <div style={{ marginTop: 20 }}>
                <text style={{ fontSize: 25 }}>・審査中用モードバージョン指定</text>
                <div style={{ marginTop: 20 }}>
                  <TextField
                    id="filled-basic"
                    value={resData.ios_review_version}
                    label="最低バージョン"
                    disabled
                  />
                </div>
              </div>
              {/* 審査用 */}
              <div style={{ marginTop: 20 }}>
                {/* <text style={{ fontSize: 25 }}>・審査中用モード</text>
                <div style={{ marginTop: 20 }}>
                  <text style={{ marginLeft: 10, marginRight: 10 }}>
                    off/on
                  </text>
                  <Switch
                    checked={resData.ios_is_for_review}
                    inputProps={{ 'aria-label': 'controlled' }}
                    disabled
                  />
                </div> */}
                <div
                  style={{
                    marginTop: 20,
                  }}
                >
                  <Button
                    style={{ backgroundColor: '#006ee6' }}
                    onClick={() => setOpen(true)}
                    variant={'contained'}
                    type={'submit'}
                  >
                    変更
                  </Button>
                </div>
              </div>
            </Container>

            {/* Android */}
            <Container style={{ width: '50%' }}>
              <div style={{ marginTop: 50 }}>
                <text
                  style={{
                    fontSize: 40,
                    borderBottom: '1px solid black',
                    color: '#a4c639',
                  }}
                >
                  Android
                </text>
              </div>
              {/* 強制アップデート */}
              <div style={{ marginTop: 20 }}>
                <text style={{ marginTop: 20, fontSize: 25 }}>
                  ・強制アップデート
                </text>
                <div style={{ marginTop: 20 }}>
                  <TextField
                    id="filled-basic"
                    value={resData.android_min_version}
                    label="最低バージョン"
                    disabled
                  />
                </div>
              </div>
              {/* メンテナンス */}
              <div style={{ marginTop: 20 }}>
                <text style={{ fontSize: 25 }}>・メンテナンスモード</text>
                <div style={{ marginTop: 20 }}>
                  <text style={{ marginLeft: 10, marginRight: 10 }}>
                    off/on
                  </text>
                  <Switch
                    checked={resData.android_maintenance_flg}
                    inputProps={{ 'aria-label': 'controlled' }}
                    disabled
                  />
                </div>
                <div style={{ marginTop: 20 }}>
                  <TextField
                    id="filled-multiline-flexible"
                    label="メンテナンスメッセージ"
                    multiline
                    rows={10}
                    style={{ width: 500 }}
                    value={resData.android_maintenance_message}
                    disabled
                  />
                </div>
                {/*  審査中用モードバージョン */}
              <div style={{ marginTop: 20 }}>
                <text style={{ fontSize: 25 }}>・審査中用モードバージョン指定</text>
                <div style={{ marginTop: 20 }}>
                  <TextField
                    id="filled-basic"
                    value={resData.android_review_version}
                    label="最低バージョン"
                    disabled
                  />
                </div>
              </div>
              {/*  審査用 */}
                <div style={{ marginTop: 20 }}>
                {/* <text style={{ fontSize: 25 }}>・審査中用モード</text> */}
                {/* <div style={{ marginTop: 20 }}>
                  <text style={{ marginLeft: 10, marginRight: 10 }}>
                    off/on
                  </text>
                  <Switch
                    checked={resData.android_is_for_review}
                    inputProps={{ 'aria-label': 'controlled' }}
                    disabled
                  />
                </div> */}
                  <Button
                    style={{ backgroundColor: '#a4c639' }}
                    onClick={() => setAndroidOpen(true)}
                    variant={'contained'}
                    type={'submit'}
                  >
                    変更
                  </Button>
                </div>
              </div>
            </Container>
          </div>
        </DivWrap>

        {/* iOS用のダイアログ */}
        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>iOSの強制アップデート/メンテナンス変更</DialogTitle>
          <DialogContent>
            <DialogContentText style={{ marginTop: 20 }}>
              ・強制アップデートは設定されている最低バージョンより進んだバージョンを必ず設定してください。
            </DialogContentText>
            <DialogContentText>
              ・アプリの場合は画面が表示されるのみでそれ以降のものは何も表示、操作ができなくなります。
            </DialogContentText>
            <DialogContentText>
              ・キーボードの場合はバナーが表示される部分に強制アップデートを促す文言を表示されます。（広告やキーボードの機能自体は使えます）
            </DialogContentText>
            <DialogContentText>
              ・表示の優先順位は強制アップデートが優先して表示されます。
            </DialogContentText>
            <DialogContentText style={{ marginBottom: 20 }}>
              ・審査中用モードをオンにすると、最低バージョン以上を満たす場合メンテナンスモードが適用されなくなります。
            </DialogContentText>

            <TextField
              id="filled-basic"
              value={reqestData.ios_min_version}
              onChange={event =>
                setRequestData({
                  ...reqestData,
                  ios_min_version: event.target.value,
                })
              }
              label="最低バージョン"
              style={{ marginTop: 20 }}
              fullWidth
              variant="standard"
            />
            <div style={{ marginTop: 20 }}>
              <text style={{ marginLeft: 10, marginRight: 10 }}>off/on</text>
              <Switch
                checked={reqestData.ios_maintenance_flg}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setRequestData({
                    ...reqestData,
                    ios_maintenance_flg: event.target.checked,
                  })
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </div>
            <TextField
              id="filled-multiline-flexible"
              label="メンテナンスメッセージ"
              multiline
              style={{ marginTop: 20 }}
              value={reqestData.ios_maintenance_message}
              onChange={e =>
                setRequestData({
                  ...reqestData,
                  ios_maintenance_message: e.target.value,
                })
              }
              fullWidth
              variant="standard"
            />
            <text style={{ fontSize: 25,}}>審査中用モードバージョン指定</text>
            <DialogContentText style={{ marginTop: 20, color: 'red' }}>
              指定されたバージョンをメンテナンスモード＆強制アップデートの対象外にします。
            </DialogContentText>
            <TextField
              id="filled-basic"
              value={reqestData.ios_review_version}
              onChange={event =>
                setRequestData({
                  ...reqestData,
                  ios_review_version: event.target.value,
                })
              }
              label="審査中用モードバージョン指定"
              style={{ marginTop: 20 }}
              fullWidth
              variant="standard"
            />
            {/* <div style={{ marginTop: 8 }}>
              <text style={{ marginLeft: 10, marginRight: 10 }}>off/on</text>
              <Switch
                checked={reqestData.ios_is_for_review}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setRequestData({
                    ...reqestData,
                    ios_is_for_review: event.target.checked,
                  })
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </div> */}

            <DialogContentText style={{ marginTop: 20, color: 'red' }}>
              ※最後にもう一度変更内容を確認し決定ボタンを押してください
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>キャンセル</Button>
            <Button onClick={() => setFinalOpen(true)}>決定</Button>
          </DialogActions>
        </Dialog>

        {/* Android用のダイアログ */}
        <Dialog open={androidOpen} onClose={() => setAndroidOpen(false)}>
          <DialogTitle>Androidの強制アップデート/メンテナンス変更</DialogTitle>
          <DialogContent>
            <DialogContentText style={{ marginTop: 20 }}>
              ・強制アップデートは設定されている最低バージョンより進んだバージョンを必ず設定してください。
            </DialogContentText>
            <DialogContentText>
              ・アプリの場合は画面が表示されるのみでそれ以降のものは何も表示、操作ができなくなります。
            </DialogContentText>
            <DialogContentText>
              ・キーボードの場合はバナーが表示される部分に強制アップデートを促す文言を表示されます。（広告やキーボードの機能自体は使えます）
            </DialogContentText>
            <DialogContentText style={{ marginBottom: 20 }}>
              ・表示の優先順位は強制アップデートが優先して表示されます。
            </DialogContentText>

            <TextField
              id="filled-basic"
              value={reqestData.android_min_version}
              onChange={event =>
                setRequestData({
                  ...reqestData,
                  android_min_version: event.target.value,
                })
              }
              label="最低バージョン"
              style={{ marginTop: 20 }}
              fullWidth
              variant="standard"
            />
            <div style={{ marginTop: 20 }}>
              <text style={{ marginLeft: 10, marginRight: 10 }}>off/on</text>
              <Switch
                checked={reqestData.android_maintenance_flg}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setRequestData({
                    ...reqestData,
                    android_maintenance_flg: event.target.checked,
                  })
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </div>
            <TextField
              id="filled-multiline-flexible"
              label="メンテナンスメッセージ"
              multiline
              style={{ marginTop: 20 }}
              value={reqestData.android_maintenance_message}
              onChange={e =>
                setRequestData({
                  ...reqestData,
                  android_maintenance_message: e.target.value,
                })
              }
              fullWidth
              variant="standard"
            />
            <text style={{ fontSize: 25}}>審査中用モード</text>
            <TextField
              id="filled-basic"
              value={reqestData.android_review_version}
              onChange={event =>
                setRequestData({
                  ...reqestData,
                  android_review_version: event.target.value,
                })
              }
              label="審査中用モードバージョン指定"
              style={{ marginTop: 20 }}
              fullWidth
              variant="standard"
            />
            {/* <div style={{ marginTop: 8 }}>
              <text style={{ marginLeft: 10, marginRight: 10 }}>off/on</text>
              <Switch
                checked={reqestData.android_is_for_review}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setRequestData({
                    ...reqestData,
                    android_is_for_review: event.target.checked,
                  })
                }
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </div> */}
            <DialogContentText style={{ marginTop: 20, color: 'red' }}>
              ※最後にもう一度変更内容を確認し決定ボタンを押してください
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setAndroidOpen(false)}>キャンセル</Button>
            <Button onClick={() => setFinalOpen(true)}>決定</Button>
          </DialogActions>
        </Dialog>

        {/* 再確認モーダル */}
        <Dialog
          open={finalOpen}
          onClose={() => setFinalOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" style={{ color: 'red' }}>
            本当に変更しますか？
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              一度変更すると全てのユーザーに影響が出ます。
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setFinalOpen(false)}>キャンセル</Button>
            <Button onClick={edit} autoFocus>
              変更
            </Button>
          </DialogActions>
        </Dialog>
      </Layout>
    </>
  );
}
