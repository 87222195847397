/**
 *
 * EventPage
 *
 */
import * as React from 'react';
import { Layout } from '../../components/Layout';
import EventSample from './DataTemplate';
import { DataTable } from 'app/components/DataTable';
import { useGetAllEventQuery, useDeleteEventMutation } from 'services/eventApi';
import { RouteModal } from 'app/components/RouteModal';
import DeleteForm from 'app/components/Form/DeleteForm';
import useFilterQuery from '../../../utils/customHooks/useFilterQuery';

export function EventPage() {
  const {
    fieldFilter,
    setFieldFilter,
    search,
    setSearch,
    timeStart,
    setTimeStart,
    timeEnd,
    setTimeEnd,
    query,
    order,
    setOrder,
    orderBy,
    setOrderBy,
  } = useFilterQuery(
    EventSample,
    ['birthday_from', 'period_start'],
    ['birthday_to', 'period_end'],
  );
  const { data = [], isFetching, error, isError } = useGetAllEventQuery(query);

  return (
    <Layout title={''}>
      <DataTable
        data={data}
        loading={isFetching}
        error={error}
        isError={isError}
        template={EventSample}
        title={'イベント一覧'}
        hasRoute={'/event/add'}
        routeEdit={'/event/edit/events_id'}
        fieldFilter={fieldFilter}
        search={search}
        setFieldFilter={setFieldFilter}
        setSearch={setSearch}
        timeStart={timeStart}
        setTimeStart={setTimeStart}
        timeEnd={timeEnd}
        setTimeEnd={setTimeEnd}
        order={order}
        setOrder={setOrder}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
      />
      <RouteModal deleteForm={<DeleteForm action={useDeleteEventMutation} />} />
    </Layout>
  );
}
