import Stack from '@mui/material/Stack';
import React from 'react';
import {
  GridContainer,
  GridItem4,
  GridItem8,
  LBox,
  TitlePink,
} from '../../../components/Common/common';
import InputLabel from '@mui/material/InputLabel';
import SelectImage from '../../../components/Form/SelectImage';

const ModalForm = ({ modalOptions, handleOpenModal, editData }) => {
  return (
    <LBox mt={5}>
      <TitlePink>モーダルのみの表示</TitlePink>
      <Stack mt={4} p={4} rowGap={{ md: 2, xs: 5 }}>
        {/* modal */}
        <GridContainer>
          <GridItem4 flexCenter={true}>
            <InputLabel htmlFor={'modal_id'}>使用モーダル</InputLabel>
          </GridItem4>
          <GridItem8>
            <SelectImage
              name={'modal_id'}
              labelName={''}
              showLabel={false}
              options={modalOptions}
              defaultValue={editData.modals_id}
              numOfAddForm={3}
              handleOpenModal={handleOpenModal}
            />
          </GridItem8>
        </GridContainer>
      </Stack>
    </LBox>
  );
};

export default ModalForm;
