/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit';

import { InjectedReducersType } from 'utils/types/injector-typings';
import { topViewApi } from '../services/topViewApi';
import { bannerApi } from '../services/bannerApi';
import { surveyApi } from '../services/surveyApi';
import { categoryApi } from '../services/categoryApi';
import { modalApi } from '../services/modalApi';
import { presentApi } from '../services/presentApi';
import { pointRuleApi } from '../services/pointRuleApi';
import { invitationHistoryApi } from '../services/invitationHistoryApi';
import { forceUpdateMaintenanceApi } from '../services/forceUpdateMaintenance';
import { presentRequestApi } from '../services/presentRequestApi';
import { userPointApi } from '../services/userPointApi';
import { userPointHistoryApi } from '../services/userPointHistoryApi';
import { eventApi } from '../services/eventApi';
import { imageUpload } from '../services/imageUpload';
import { dailySummariesApi } from '../services/dailySummariesApi';
import { adminUserApi } from '../services/adminUserApi';
import { wallReportApi } from '../services/wallReportApi';
import { statisticsApi } from 'services/statisticsApi';
import { offerwallApi } from 'services/gmoOfferwallApi';
import { importApi } from 'services/importApi';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers: InjectedReducersType = {}) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error
  if (Object.keys(injectedReducers).length === 0) {
    return state => state;
  } else {
    return combineReducers({
      ...injectedReducers,
      [adminUserApi.reducerPath]: adminUserApi.reducer,
      [wallReportApi.reducerPath]: wallReportApi.reducer,
      [statisticsApi.reducerPath]: statisticsApi.reducer,
      [offerwallApi.reducerPath]: offerwallApi.reducer,
      [importApi.reducerPath]: importApi.reducer,
      [topViewApi.reducerPath]: topViewApi.reducer,
      [bannerApi.reducerPath]: bannerApi.reducer,
      [surveyApi.reducerPath]: surveyApi.reducer,
      [categoryApi.reducerPath]: categoryApi.reducer,
      [modalApi.reducerPath]: modalApi.reducer,
      [presentApi.reducerPath]: presentApi.reducer,
      [pointRuleApi.reducerPath]: pointRuleApi.reducer,
      [invitationHistoryApi.reducerPath]: invitationHistoryApi.reducer,
      [forceUpdateMaintenanceApi.reducerPath]:
        forceUpdateMaintenanceApi.reducer,
      [presentRequestApi.reducerPath]: presentRequestApi.reducer,
      [userPointApi.reducerPath]: userPointApi.reducer,
      [userPointHistoryApi.reducerPath]: userPointHistoryApi.reducer,
      [eventApi.reducerPath]: eventApi.reducer,
      [imageUpload.reducerPath]: imageUpload.reducer,
      [dailySummariesApi.reducerPath]: dailySummariesApi.reducer,
    });
  }
}
