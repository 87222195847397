import React from 'react';
import IconButton from '@mui/material/IconButton';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface Iprops {
  type: 'in' | 'de';
  max: number;
  current: number;
  handlerChange: (page: number) => void;
}

const IncreaseButton: React.FC<Iprops> = ({
  max,
  type,
  handlerChange,
  current,
}) => {
  const handlerClick = () => {
    if (type === 'de') {
      if (current === 1) {
        return;
      } else {
        handlerChange(current - 1);
      }
    }
    if (type === 'in') {
      if (current === max) {
        return;
      } else {
        handlerChange(current + 1);
      }
    }
  };
  return (
    <IconButton onClick={handlerClick}>
      {type === 'in' ? (
        <ArrowForwardIosIcon style={{ fontSize: '10px' }} fontSize={'small'} />
      ) : (
        <ArrowBackIosNewIcon style={{ fontSize: '10px' }} fontSize={'small'} />
      )}
    </IconButton>
  );
};

export default IncreaseButton;
