import React from 'react';
import { cubic1 } from '../../../styles/media';
import AddIcon from '@mui/icons-material/Add';
import Fab, { FabProps } from '@mui/material/Fab';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useCommonSlice } from '../../../store/commonSlice';

interface IProps extends FabProps {
  hasRoute?: string;
  btnBack?: boolean;
}

const FloatButton: React.FC<IProps> = ({ hasRoute, btnBack, ...other }) => {
  const { actions: commonActions } = useCommonSlice();
  const dispatch = useDispatch();
  const route = useHistory();

  const openModal = type => () => {
    if (hasRoute) {
      route.push(hasRoute);
    } else {
      dispatch(commonActions.toggleModal(type));
    }
  };
  return (
    <Fab
      {...other}
      sx={{
        '.sum-icon': {
          transition: `transform 200ms ${cubic1}`,
        },
        '&:hover': {
          '.sum-icon': {
            transform: btnBack ? 'translateX(-5px)' : 'rotate(45deg)',
          },
        },
      }}
      color={'inherit'}
      aria-label="add"
      onClick={openModal({
        actionModal: 'Add',
        dataAttach: null,
      })}
    >
      {btnBack ? (
        <ArrowBackIosNewIcon className={'sum-icon'} />
      ) : (
        <AddIcon className={'sum-icon'} />
      )}
    </Fab>
  );
};

export default FloatButton;
