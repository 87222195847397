import React, { CSSProperties, useState } from 'react';
import { useGetReportsQuery, useSubmitReportMutation } from '../../../services/wallReportApi';
import { Layout } from '../../components/Layout';
import { ToolBar } from '../../components/ToolBar';

const tableStyle: CSSProperties = {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '20px'
};

const thTdStyle: CSSProperties = {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'left'
};

const imageStyle: CSSProperties = {
    maxWidth: '150px',
    maxHeight: '300px'
};


export function WallReportPage(props) {
    const { data } = useGetReportsQuery();
    const [submitReport] = useSubmitReportMutation();

    // Ensure reports is always an array
    const reports = data || [];

    // State to hold the input values for the current report
    const [inputStates, setInputStates] = useState({});

    const handleInputChange = (reportId, field, value) => {
        setInputStates(prev => ({ ...prev, [reportId]: { ...prev[reportId], [field]: value } }));
    };

    const handleSubmit = async (reportId) => {
        // Get the input values for the current report
        const { text = '', number = 1 } = inputStates[reportId] || {};
    
        // Ask for confirmation
        const isConfirmed = window.confirm(`再度確認してください：\n事由: ${text}\n承認状況: ${number}`);
        
        if (isConfirmed) {
            // Construct the payload and send the request
            const payload = { reportId, text, number };
            await submitReport(payload);
        }
    };
    

    const tableStyle: CSSProperties = {
        width: '100%',
        borderCollapse: 'collapse', // 'collapse' is a valid value for BorderCollapse
        marginBottom: '20px'
    };
    
    const thTdStyle: CSSProperties = {
        border: '1px solid #ddd',
        padding: '8px',
        textAlign: 'left' // 'left' is a valid value for TextAlign
    };
    
    const imageStyle: CSSProperties = {
        maxWidth: '200px',
        maxHeight: '200px'
    };
    

    return (
        <Layout title="お仕事報告">
            <ToolBar title="お仕事報告" noSearchPanel={true} noAddButton={true} noFilterPanel={true} />
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            {reports.length > 0 ? (
            <table style={tableStyle}>
                <thead>
                    <tr>
                        <th style={thTdStyle}>ID</th>
                        <th style={thTdStyle}>User ID</th>
                        <th style={thTdStyle}>URL</th>
                        <th style={thTdStyle}>Title</th>
                        <th style={thTdStyle}>Created At</th>
                        <th style={thTdStyle}>OS</th>
                        <th style={thTdStyle}>Note</th>
                        <th style={thTdStyle}>Image</th>
                        <th style={thTdStyle}>Image 2</th>
                        <th style={thTdStyle}>アラート</th>
                        <th style={thTdStyle}>事由と承認状況入力</th>
                        <th style={thTdStyle}>送信</th>
                    </tr>
                </thead>
                <tbody>
                    {reports.map((report) => (
                        <tr key={report.id}>
                            <td style={thTdStyle}>{report.id}</td>
                            <td style={thTdStyle}>{report.user_id}</td>
                            <td style={thTdStyle}>{report.url}</td>
                            <td style={thTdStyle}>{report.title}</td>
                            <td style={thTdStyle}>{report.created_at}</td>
                            <td style={thTdStyle}>{report.os}</td>
                            <td style={thTdStyle}>{report.note}</td>
                            <td style={thTdStyle}><img src={report.image} alt="Image" style={imageStyle} /></td>
                            <td style={thTdStyle}><img src={report.image_2} alt="Image 2" style={imageStyle} /></td>
                            <td style={thTdStyle}>{report.duplicate}</td>
                            <td style={thTdStyle}>
                                <input type="text" placeholder="Enter text" value={inputStates[report.id]?.text || ''} onChange={(e) => handleInputChange(report.id, 'text', e.target.value)} />
                                <input type="number" min="1" max="2" placeholder="Enter number" value={inputStates[report.id]?.number || 1} onChange={(e) => handleInputChange(report.id, 'number', parseInt(e.target.value, 10))} />
                            </td>
                            <td style={thTdStyle}>
                                <button onClick={() => handleSubmit(report.id)}>Submit</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
                        ) : (
                            <div>No records found.</div> // Message when there are no records
                        )}
        </Layout>
    );
};
