import Stack from '@mui/material/Stack';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
  Box35,
  GridContainer,
  GridItem4,
  GridItem8,
  LBox,
  TitlePink,
} from '../../../components/Common/common';
import InputLabel from '@mui/material/InputLabel';
import TextInput from '../../../components/Form/TextInput';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Box, Chip, debounce, Switch } from '@mui/material';
import Typography from '@mui/material/Typography';
import QuickAddButton from '../../../components/Button/QuickAddButton';
import SelectImage from '../../../components/Form/SelectImage';
import SelectInput from '../../../components/Form/SelectInput';
import { EventContext } from './index';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import UndoIcon from '@mui/icons-material/Undo';
import EditIcon from '@mui/icons-material/Edit';
import { useFormContext } from 'react-hook-form';
import { useUpdateEventMutation } from '../../../../services/eventApi';
import { max } from 'date-fns';

const GachaForm = ({
  handleOpenModal,
  handleEditModal,
  modalOptions,
  pointRuleOptions,
  bannerOptions,
  editData,
}) => {
  const useGachaContext = useContext(EventContext);
  useEffect(() => {
    useGachaContext.setGachaForm(editData.gacha_details_list);
  }, []);
  return (
    <LBox mt={5}>
      <TitlePink>
        ガチャ詳細設定（イベント種類がガチャポイントの時のみ）
      </TitlePink>
      <Stack mt={4} p={4} rowGap={{ md: 2, xs: 5 }}>
        {/* gacha_unknown */}
        <GridContainer>
          <GridItem4>
            <InputLabel htmlFor={'limit_lottery_point_one_user_per_hour'}>
              一人当たりの抽選上限回数（1時間毎）
            </InputLabel>
          </GridItem4>
          <GridItem8>
            <Box35>
              <TextInput
                type={'number'}
                name={'limit_lottery_point_one_user_per_hour'}
                labelName={'一人当たりの抽選上限回数（1時間毎）'}
                defaultValue={
                  editData.events_limit_lottery_point_one_user_per_hour
                }
                showLabel={false}
              />
            </Box35>
          </GridItem8>
        </GridContainer>
        {/* gacha_unknown2 */}
        <GridContainer>
          <GridItem4>
            <InputLabel htmlFor={'limit_lottery_all_user_per_hour'}>
              ユーザー全体のの抽選上限回数（1時間毎）
            </InputLabel>
          </GridItem4>
          <GridItem8>
            <Box35>
              <TextInput
                type={'number'}
                name={'limit_lottery_all_user_per_hour'}
                labelName={'ユーザー全体のの抽選上限回数（1時間毎）'}
                defaultValue={editData.events_limit_lottery_all_user_per_hour}
                showLabel={false}
              />
            </Box35>
          </GridItem8>
        </GridContainer>
        <GachaTable
          handleOpenModal={handleOpenModal}
          handleEditModal={handleEditModal}
          editData={editData}
        />
        {/* banner */}
        <GridContainer>
          <GridItem4 flexCenter={true}>
            <InputLabel htmlFor={'banner_id'}>使用バナー</InputLabel>
          </GridItem4>
          <GridItem8>
            <SelectImage
              name={'banner_id'}
              labelName={''}
              showLabel={false}
              options={bannerOptions}
              numOfAddForm={2}
              handleOpenModal={handleOpenModal}
              defaultValue={editData.banners_id}
            />
          </GridItem8>
        </GridContainer>
        {/* modal */}
        <GridContainer>
          <GridItem4 flexCenter={true}>
            <InputLabel htmlFor={'modal_id'}>使用モーダル</InputLabel>
          </GridItem4>
          <GridItem8>
            <SelectImage
              name={'modal_id'}
              labelName={''}
              showLabel={false}
              options={modalOptions}
              numOfAddForm={3}
              handleOpenModal={handleOpenModal}
              defaultValue={editData.modals_id}
            />
          </GridItem8>
        </GridContainer>
        {/* point */}
        <GridContainer>
          <GridItem4 flexCenter={true}>
            <InputLabel htmlFor={'point_rule_id'}>
              使用ポイントルール
            </InputLabel>
          </GridItem4>
          <GridItem8>
            <Stack flexDirection={'row'} alignItems={'center'} columnGap={10}>
              <Box width={'40%'}>
                <SelectInput
                  name={'point_rule_id'}
                  labelName={''}
                  showLabel={false}
                  options={pointRuleOptions}
                  defaultValue={editData.point_rules_id}
                />
              </Box>
              <Box>
                <QuickAddButton onClick={() => handleOpenModal(4)} />
              </Box>
              <Box width={'30%'}>&nbsp;</Box>
            </Stack>
          </GridItem8>
        </GridContainer>
      </Stack>
    </LBox>
  );
};

export default GachaForm;

const GachaTable = ({ handleOpenModal, handleEditModal, editData }) => {
  const useGachaContext = useContext(EventContext);
  const data = useGachaContext.gachaForm;
  const { register, setValue } = useFormContext();
  const [mean, setMean] = useState(0);

  const deleteGacha = id => {
    const temp = [...data];
    const index = temp.findIndex((item, key) => key === id);
    if (index !== -1) {
      if (temp[index]?.gacha_details_id) {
        temp[index] = {
          ...temp[index],
          gacha_details_trash_flag: temp[index]?.gacha_details_trash_flag
            ? false
            : true,
        };
      } else {
        temp.splice(index, 1);
      }
    }
    useGachaContext.setGachaForm(temp);
  };

  /*
   * wait to last result then call api
   * time out 2s
   * */
  const [updateEvent, { isLoading: loading, isSuccess, isError, error }] =
    useUpdateEventMutation();
  const debouncedSave = useCallback(
    debounce(
      body =>
        (function () {
          updateEvent({
            id: editData.events_id,
            body: JSON.stringify({
              gacha_detail_records: body,
            }),
          });
        })(),
      2000,
    ),
    [],
  );

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>, index) => {
    const temp = data.map((item, key) =>
      index === key
        ? { ...item, gacha_details_lottery_flag: event.target.checked ? 1 : 0 }
        : item,
    );
    useGachaContext.setGachaForm(temp);
    const temp2 = temp.map(item => ({
      ...item,
      gacha_details_lottery_flag: item.gacha_details_lottery_flag === 1,
    }));
    debouncedSave(temp2);
  };

  useEffect(() => {
    if (data.length > 0) {
      const temp2 = data.reduce((a, b) =>  a + (b.gacha_details_point_amount * b.gacha_details_point_percent) / 100, 0)
      const temp = data.reduce(
        (a, b) =>
          !b.hasOwnProperty('gacha_details_trash_flag') ||
          b.gacha_details_trash_flag === false
            ? a + b['gacha_details_point_percent']
            : a,
        0,
      );
      setMean(temp2)
      setValue('total', temp);
    } else {
      setMean(0)
      setValue('total', 0);
    }
  }, [data]);


  return (
    <TableContainer
      sx={{
        maxHeight: 700,
        msOverflowStyle: 'none',
        '::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Typography mb={2}>当選確率</Typography>
      <Table sx={{ border: '1px solid #ddd' }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ minWidth: { md: 120, xs: '35vw' } }}>
              ポイント
            </TableCell>
            <TableCell sx={{ minWidth: { md: 120, xs: '35vw' } }}>
              等数
            </TableCell>
            <TableCell sx={{ minWidth: { md: 120, xs: '35vw' } }}>
              確率
            </TableCell>
            <TableCell sx={{ minWidth: { md: 250, xs: '60vw' } }}>
              ガチャ結果画像URL
            </TableCell>
            <TableCell sx={{ minWidth: { md: 250, xs: '65vw' } }}>
              <Chip label={`期待値: ${mean}ポイント`} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 &&
            data.map((item, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {item['gacha_details_point_amount']}
                </TableCell>
                <TableCell>{item['gacha_details_prize_num']}等</TableCell>
                <TableCell>{item['gacha_details_point_percent']}</TableCell>
                <TableCell>{item['gacha_details_result_image_url']}</TableCell>
                <TableCell>
                  <Switch
                    onChange={e => handleSwitch(e, index)}
                    checked={item['gacha_details_lottery_flag'] === 1}
                  />
                  <IconButton
                    disabled={
                      !(
                        !item?.hasOwnProperty('gacha_details_trash_flag') ||
                        item?.gacha_details_trash_flag !== true
                      )
                    }
                    onClick={() =>
                      handleEditModal(1, item['gacha_details_id'], item)
                    }
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => deleteGacha(index)}>
                    {!item?.hasOwnProperty('gacha_details_trash_flag') ||
                    item?.gacha_details_trash_flag !== true ? (
                      <DeleteIcon />
                    ) : (
                      <UndoIcon />
                    )}
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell colSpan={6}>
              <Typography component={'label'} htmlFor={'total'}>
                合計
              </Typography>
              <input
                {...register('total')}
                type={'text'}
                id={'total'}
                readOnly={true}
                style={{ outline: 'none', border: 'none', width: '30px' }}
              />
              %
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={6}>
              <QuickAddButton onClick={() => handleOpenModal(1)} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
