/**
 *
 * SurveyPage
 *
 */
import * as React from 'react';
import { Layout } from '../../components/Layout';
import SurveyRuleSample from './DataTemplate';
import { DataTable } from '../../components/DataTable';
import { useGetAllSurveyQuery } from 'services/surveyApi';
import useFilterQuery from '../../../utils/customHooks/useFilterQuery';
import template from '../PresentPage/template';
import { RouteModal } from '../../components/RouteModal';
import ImportForm from '../PresentPage/ImportForm';
import DeleteForm from '../../components/Form/DeleteForm';
import { useDeletePresentMutation } from '../../../services/presentApi';

export function SurveyPage() {
  const {
    fieldFilter,
    setFieldFilter,
    search,
    setSearch,
    timeStart,
    setTimeStart,
    timeEnd,
    setTimeEnd,
    query,
    order,
    setOrder,
    orderBy,
    setOrderBy,
  } = useFilterQuery(template, ['events_period_start'], ['events_period_end']);
  const { data = [], isFetching, error, isError } = useGetAllSurveyQuery(query);
  return (
    <>
      <Layout title={'フォーム管理'}>
        <DataTable
          data={data}
          loading={isFetching}
          error={error}
          isError={isError}
          template={SurveyRuleSample}
          title={'フォーム管理'}
          hasRoute={'/survey/add'}
          routeEdit={'/survey/edit/forms_id'}
          fieldFilter={fieldFilter}
          search={search}
          setFieldFilter={setFieldFilter}
          setSearch={setSearch}
          timeStart={timeStart}
          setTimeStart={setTimeStart}
          timeEnd={timeEnd}
          setTimeEnd={setTimeEnd}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
        />
        <RouteModal importFile={<ImportForm />} />
      </Layout>
    </>
  );
}
