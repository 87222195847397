import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './baseQuery';

export interface IForceUpdateMaintenanceApi {
  ios_min_version: string;
  ios_maintenance_flg: boolean;
  ios_maintenance_message: string;
  ios_is_for_review: boolean;
  ios_review_version: string,
  android_min_version: string;
  android_maintenance_flg: boolean;
  android_maintenance_message: string;
  android_is_for_review: boolean;
  android_review_version: string;
}

export const forceUpdateMaintenanceApi = createApi({
  reducerPath: 'forceUpdateMaintenance_api',
  tagTypes: ['forceUpdateMaintenance'],
  baseQuery: axiosBaseQuery(),
  endpoints: build => ({
    getForceUpdateMaintenance: build.query<IForceUpdateMaintenanceApi, string>({
      query: params => ({
        url: `/force_update_maintenance`,
        method: 'GET',
      }),
      providesTags: ['forceUpdateMaintenance'],
      transformResponse: (result: IForceUpdateMaintenanceApi) => {
        return result;
      },
    }),
    updateForceUpdateMaintenance: build.mutation<any, any>({
      query(body) {
        return {
          url: `/force_update_maintenance/update`,
          method: 'PUT',
          data: body,
        };
      },
      invalidatesTags: ['forceUpdateMaintenance'],
    }),
  }),
});

export const {
  useGetForceUpdateMaintenanceQuery,
  useUpdateForceUpdateMaintenanceMutation,
} = forceUpdateMaintenanceApi;
