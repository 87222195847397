import { DataTemplate } from '../types/dataTemplate';

interface IFieldsDate {
  start: string;
  end: string;
}

function UseHasDate(template: DataTemplate[]) {
  let fieldsDate: IFieldsDate = { start: '', end: '' };
  for (let i = 0; i < template.length; i++) {
    if (
      template[i]?.type?.includes('date') ||
      template[i]?.filterDate === true
    ) {
      if (
        template[i]?.type === 'date start' ||
        template[i]?.filterDate === true
      ) {
        fieldsDate.start = template[i].field;
      }
      if (
        template[i]?.type === 'date end' ||
        template[i]?.filterDate === true
      ) {
        fieldsDate.end = template[i].field;
      }
    }
  }
  return fieldsDate;
}

export default UseHasDate;
