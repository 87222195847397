import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as queryString from 'query-string';
import moment from 'moment';

const UseFilterQuery = (template, date_from?: string[], date_to?: string[]) => {
  const location = useLocation();
  const history = useHistory();

  // search by field
  const findFirstFilterField = template.find(
    item => item.filterField !== undefined,
  );
  const [fieldFilter, setFieldFilter] = useState<string | undefined>(
    findFirstFilterField ? findFirstFilterField.filterField : template[0].field,
  );
  const [search, setSearch] = useState('');
  const [query, setQuery] = useState('');

  // date range
  const [timeStart, setTimeStart] = React.useState<number | null>(null);
  const [timeEnd, setTimeEnd] = React.useState<number | null>(null);

  // sort
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = React.useState<any>(null);

  //flag to first time page loaded
  const [flag, setFlag] = useState<boolean>(false);

  useEffect(() => {
    if (location.search !== '') {
      const parse = queryString.parse(location.search);
      const stringFly = queryString.stringify(parse);
      setQuery(stringFly);
      if ('order_by' in parse) {
        setOrderBy(parse['order_by']);
      }
      if ('order' in parse) {
        setOrder(parse['order'] === 'DESC' ? 'desc' : 'asc');
      }
    } else {
      setFlag(true);
    }
  }, []);

  let obj = {};

  useEffect(() => {
    //search by field
    if (search !== '') {
      setFlag(true);
      if (fieldFilter) {
        obj[fieldFilter] = search;
      }
    }

    //date range
    if (timeStart !== null) {
      setFlag(true);
      if (date_from) {
        date_from.map(item => {
          obj[item] = moment(timeStart).format('YYYY-MM-DD');
        });
      } else {
        obj['start_at'] = moment(timeStart).format('YYYY-MM-DD');
      }
    }
    if (timeEnd !== null) {
      if (date_to) {
        date_to.map(item => {
          obj[item] = moment(timeEnd).format('YYYY-MM-DD');
        });
      } else {
        obj['end_at'] = moment(timeEnd).format('YYYY-MM-DD');
      }
    }

    //sort
    if (orderBy !== null) {
      setFlag(true);
      obj['order_by'] = orderBy;
      obj['order'] = order.toUpperCase();
    }

    if (flag) {
      history.push({
        pathname: location.pathname,
        search: queryString.stringify(obj),
      });
      setQuery(queryString.stringify(obj));
    }
  }, [search, fieldFilter, timeEnd, timeStart, order, orderBy]);

  return {
    search,
    setSearch,
    fieldFilter,
    setFieldFilter,
    timeStart,
    setTimeStart,
    timeEnd,
    setTimeEnd,
    query,
    order,
    setOrder,
    orderBy,
    setOrderBy,
  };
};

export default UseFilterQuery;
