import React, { useEffect } from 'react';

const UseFocusError = (errors, setFocus) => {
  useEffect(() => {
    const firstError = (
      Object.keys(errors) as Array<keyof typeof errors>
    ).reduce<keyof typeof errors | null>((field, a) => {
      const fieldKey = field as keyof typeof errors;
      return !!errors[fieldKey] ? fieldKey : a;
    }, null);

    if (firstError && firstError.hasOwnProperty('focus')) {
      setFocus(firstError);
    }
  }, [errors, setFocus]);
};

export default UseFocusError;
