/**
 *
 * Header
 *
 */
import React, { memo, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Menu } from './Menu';
import { Link, useHistory } from 'react-router-dom';
import IsAuth from '../../../utils/auth/IsAuth';

interface Props {}

export const Header = memo((props: Props) => {
  const [showMenu, setShowMenu] = useState(false);
  const history = useHistory();
  const handleShow = () => setShowMenu(!showMenu);
  const handleLogOut = () => {
    localStorage.removeItem('accessToken');
    history.push('/login');
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: '#3f51b5',
          height: { xs: '60px', md: '64px' },
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={handleShow}
          >
            <MenuIcon />
          </IconButton>
          <Menu show={showMenu} clickShow={handleShow} />
          <Typography
            fontSize={{ xs: '1rem' }}
            variant="h6"
            component="h1"
            sx={{ flexGrow: 1 }}
          >
            <a href="/" style={{ color: 'inherit' }}>
              Voice 管理ツール
            </a>
          </Typography>
          {IsAuth() !== null ? (
            <Typography
              fontWeight={'700'}
              sx={{
                fontSize: { md: '1rem' },
                color: '#fff',
                cursor: 'pointer',
              }}
              onClick={handleLogOut}
            >
              Sign Out
            </Typography>
          ) : (
            <Link to={'/login'}>
              <Typography
                fontWeight={'700'}
                sx={{
                  fontSize: { md: '1rem' },
                  color: '#fff',
                  cursor: 'pointer',
                }}
              >
                Login
              </Typography>
            </Link>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  );
});
