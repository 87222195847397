import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './baseQuery';

export interface IPresentResponse {
  presents_id: number;
  presents_category_id: number;
  presents_title: string;
  presents_image_url: string;
  presents_point_amount: number;
  presents_status: number;
  presents_start_at: string;
  presents_end_at: string;
  presents_exchange_limit: number;
  presents_recommend_flag: number;
  presents_recommend_sort_number: number;
  presents_sort_number: number;
  presents_giftee_token: string;
  presents_giftee_code: string;
  presents_description: string;
  presents_caution: string;
  present_hashtags_hashtag: string[];
}

export const presentApi = createApi({
  reducerPath: 'present_api',
  baseQuery: axiosBaseQuery(),
  tagTypes: ['presents'],
  endpoints: build => ({
    getPresents: build.query<IPresentResponse[], string>({
      query: params => ({
        url: `/presents${params && '?' + params}`,
        method: 'GET',
      }),
      providesTags: ['presents'],
      transformResponse: (result: { presents_response: IPresentResponse[] }) =>
        result.presents_response,
    }),
    createPresent: build.mutation({
      query: body => ({
        url: 'presents/manual',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['presents'],
    }),
    importPresent: build.mutation({
      query: body => ({
        url: 'presents/import',
        method: 'POST',
        data: body,
      }),
    }),
    updatePresent: build.mutation({
      query: ({ id, body }) => ({
        url: `/presents/${id}`,
        method: 'PUT',
        data: body,
      }),
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          const delayUpdate = setTimeout(() => {
            dispatch(
              presentApi.endpoints.getPresents.initiate('', {
                forceRefetch: true,
              }),
            );
            clearTimeout(delayUpdate);
          }, 2000);
        } catch (err) {
          console.log(err);
        }
      },
    }),
    deletePresent: build.mutation({
      query: id => ({
        url: `/presents/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['presents'],
    }),
  }),
});

export const {
  useCreatePresentMutation,
  useGetPresentsQuery,
  useUpdatePresentMutation,
  useDeletePresentMutation,
  useImportPresentMutation,
} = presentApi;
