/**
 *
 * DivWrap
 *
 */
import * as React from 'react';
import styled from '@emotion/styled';
import Box, { BoxProps } from '@mui/material/Box';

export function DivWrap(props: BoxProps) {
  return (
    <CustomContainer
      {...props}
      sx={{
        px: { md: 3, xs: 0 },
        mt: ['120px', '170px', '180px', null],
        paddingBottom: { md: '50px', xs: '8vw' },
        color: '#5B5A5A',
      }}
    >
      {props.children}
    </CustomContainer>
  );
}

const CustomContainer = styled(Box)`
  margin: 0 auto;
`;
