import React, { useState, CSSProperties, useEffect } from 'react';
import { useSubmitMutation, useSubmitDeleteUrlMutation, useSubmitSuspendMutation, useSubmitFlagMutation, useSubmitThresholdMutation, useGetAdsQuery} from '../../../services/gmoOfferwallApi';
import { Layout } from '../../components/Layout';
import { ToolBar } from '../../components/ToolBar';
import { DataTable } from '../../components/DataTable';
import template, { userData } from './DataTemplate';

const tableStyle: CSSProperties = {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '20px'
};

const thTdStyle: CSSProperties = {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'left'
};

const imageStyle: CSSProperties = {
    maxWidth: '150px',
    maxHeight: '300px'
};

export const OfferwallPage: React.FC = () => {
    const [url, setUrl] = useState<string>('');
    const [icon_url, setIcon_url] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [requirements, setRequirements] = useState<string>('');
    const [point, setPoint] = useState<string>('');
    const [os, setOs] = useState<string>('');
    const [expire_at, setExpire_at] = useState<string>('');
    const [delete_url, setDelete_url] = useState<string>('');

    // Use the mutation hook once
    const [submitData, { isLoading, isError }] = useSubmitMutation();
    const [submitDeleteUrlData, { isLoading: isDelLoading, isError: isDelError }] = useSubmitDeleteUrlMutation();
    const [submitSuspend] = useSubmitSuspendMutation();
    const [submitFlag] = useSubmitFlagMutation();
    const [submitThreshold] = useSubmitThresholdMutation();
    const [shouldRefetch, setShouldRefetch] = useState(false);

    const { data = [], refetch } = useGetAdsQuery();


    const getRowStyle = (report) => ({
        ...thTdStyle,
        opacity: report.deleted_at ? 0.5 : 1
    });

    const handleSubmit = async (reportId, suspend) => {
        // Get the input values for the current report
        
        // Construct the payload and send the request
        const payload = { reportId, suspend };
        await submitSuspend(payload);
        setShouldRefetch(true); // Signal to refetch data
        
    };

    const handleInputChange = async (reportId, field, value) => {

        if (field == "flag") {
            const payload = { reportId, flag: Number(value)};
            await submitFlag(payload);
        }else {
            const payload = { reportId, threshold: Number(value)};
            await submitThreshold(payload);
        }
        setShouldRefetch(true);
    };

    useEffect(() => {
        if (shouldRefetch) {
            refetch(); // This is the function to refetch data
            setShouldRefetch(false);
        }
    }, [shouldRefetch, refetch]);

    // Ensure reports is always an array
    const reports = data || [];

        // Fetch ads data on component mount
        const tableStyle: CSSProperties = {
            width: '100%',
            borderCollapse: 'collapse', // 'collapse' is a valid value for BorderCollapse
            marginBottom: '20px'
        };
        
        const thTdStyle: CSSProperties = {
            border: '1px solid #ddd',
            padding: '8px',
            textAlign: 'left' // 'left' is a valid value for TextAlign
        };
        
        const imageStyle: CSSProperties = {
            maxWidth: '60px',
            maxHeight: '60px'
        };
        

        return (
            <Layout title="オファーウォール">
                <ToolBar title="オファーウォール" noSearchPanel={true} noAddButton={true} noFilterPanel={true} />
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                flag: 1 インストール後起動 2 無料登録 4 おすすめ 5 インストール後起動+おすすめ 6 無料登録+ おすすめ 7 カード系案件 
                {reports.length > 0 ? (
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={thTdStyle}>ID</th>
                            <th style={thTdStyle}>Url</th>
                            <th style={thTdStyle}>IconUrl</th>
                            <th style={thTdStyle}>Title</th>
                            <th style={thTdStyle}>Requirement</th>
                            <th style={thTdStyle}>Point</th>
                            <th style={thTdStyle}>OS</th>
                            <th style={thTdStyle}>ExpireAt</th>
                            <th style={thTdStyle}>stages</th>
                            <th style={thTdStyle}>DeletedAt</th>
                            <th style={thTdStyle}>停止</th>
                            <th style={thTdStyle}>再開</th>
                            <th style={thTdStyle}>flag</th>
                            <th style={thTdStyle}>閾値</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reports.map((report) => (
                            <tr key={report.id} style={getRowStyle(report)}>
                                <td style={thTdStyle}>{report.id}</td>
                                <td style={thTdStyle}>{report.url}</td>
                                <td style={thTdStyle}><img src={report.icon_url} alt="Image" style={imageStyle} /></td>
                                <td style={thTdStyle}>{report.title}</td>
                                <td style={thTdStyle}>{report.requirements}</td>
                                <td style={thTdStyle}>{report.point}</td>
                                <td style={thTdStyle}>{report.os}</td>
                                <td style={thTdStyle}>{report.expire_at}</td>
                                <td style={thTdStyle}>{report.stages}</td>
                                <td style={thTdStyle}>{report.deleted_at}</td>
                                <td style={thTdStyle}>
                                    <button onClick={() => handleSubmit(report.id, 1)}>停止</button>
                                </td>
                                <td style={thTdStyle}>
                                    <button onClick={() => handleSubmit(report.id, 0)}>再開</button>
                                </td>
                                <td style={thTdStyle}>
                                    <input
                                        type="number"
                                        value={report.flag} // Set the value attribute to report.flag
                                        onChange={e => handleInputChange(report.id, 'flag', e.target.value)}
                                        style={{ width: '50px' }}
                                    />
                                </td>
                                <td style={thTdStyle}>
                                    <input
                                        type="number"
                                        value={report.threshold} // Set the value attribute to report.flag
                                        onChange={e => handleInputChange(report.id, 'threshold', e.target.value)}
                                        style={{ width: '50px' }}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                            ) : (
                                <div>No records found.</div> // Message when there are no records
                            )}
            </Layout>
        );
}

export default OfferwallPage;
