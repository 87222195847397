import React from 'react';
import { DataTemplate } from '../../../utils/types/dataTemplate';
import { TextUnderLine } from '../../components/Common/common';
import CellCustom from '../../components/DataTable/CellCustom';
import { useGetAllBannerQuery } from '../../../services/bannerApi';
import { useGetModalsQuery } from '../../../services/modalApi';
import { useGetAllSurveyQuery } from '../../../services/surveyApi';
import { useGetAllPointRuleQuery } from '../../../services/pointRuleApi';
import CellCustomID from '../../components/DataTable/CellCustomID';
import Typography from '@mui/material/Typography';

const EventSample: DataTemplate[] = [
  {
    field: 'events_id',
    headerName: 'イベントID',
    minWidth: { md: 150, xs: '45vw' },
    flex: 2,
    type: 'id',
    filterField: 'event_id',
    filterName: 'イベントID',
  },
  {
    field: 'events_title',
    headerName: 'イベント名',
    minWidth: { md: 150, xs: '40vw' },
    flex: 2,
    type: 'string',
    filterField: 'title',
    filterName: 'イベント名',
  },
  {
    field: 'age',
    headerName: '年齢',
    minWidth: { md: 150, xs: '40vw' },
    flex: 2,
    type: 'string',
    filterField: 'age',
    filterName: '年齢',
    hide: true,
  },
  {
    field: 'gender',
    headerName: '性別',
    minWidth: { md: 150, xs: '40vw' },
    flex: 2,
    type: 'string',
    filterField: 'gender',
    filterName: '性別',
    hide: true,
  },
  {
    field: 'events_type',
    headerName: 'イベント種類',
    minWidth: { md: 180, xs: '40vw' },
    flex: 2,
    type: 'custom',
    filterable: false,
    renderProps: props => {
      switch (props) {
        case 3:
          return <Typography>フォームイベント</Typography>;
        case 1:
          return <Typography>任意ポイントイベント</Typography>;
        case 2:
          return <Typography>ガチャイベント</Typography>;
        case 4:
          return <Typography>招待イベント</Typography>;
        case 5:
          return <Typography>バナー表示のみ</Typography>;
        case 6:
          return <Typography>モーダル表示のみ</Typography>;
        default:
          return <Typography>無効</Typography>;
      }
    },
  },
  {
    field: 'address',
    headerName: '住所',
    type: 'string',
    filterField: 'address',
    hide: true,
  },
  {
    field: 'job',
    headerName: '仕事',
    type: 'string',
    filterField: 'job',
    hide: true,
  },
  {
    field: 'hobby',
    headerName: '趣味',
    type: 'string',
    filterField: 'hobby',
    hide: true,
  },
  {
    field: 'point_rules_id',
    headerName: 'ポイントルール',
    minWidth: { md: 180, xs: '40vw' },
    flex: 1,
    filterable: false,
    type: 'custom',
    renderProps: props => (
      <CellCustomID
        field={props}
        fieldLink={'point_rules_title'}
        fieldRender={'point_rules_title'}
        fieldSearch={'point_rules_id'}
        linkRefer={'/point-rule?title='}
        query={useGetAllPointRuleQuery}
      />
    ),
  },
  {
    field: 'banners_id',
    headerName: 'バナー',
    minWidth: { md: 130, xs: '40vw' },
    flex: 1,
    filterable: false,
    type: 'custom',
    renderProps: props => (
      <CellCustom
        query={useGetAllBannerQuery}
        field={props}
        fieldName={'banner_title'}
        param={'banner_id='}
        hasLink={'/banner?banner_id='}
      />
    ),
  },
  {
    field: 'modals_id',
    headerName: 'モーダル',
    minWidth: { md: 180, xs: '40vw' },
    flex: 1,
    filterable: false,
    type: 'custom',
    renderProps: props => (
      <CellCustom
        query={useGetModalsQuery}
        field={props}
        fieldName={'modals_title'}
        param={'modal_id='}
        hasLink={'/modal?modal_id='}
      />
    ),
  },
  {
    field: 'forms_id',
    headerName: 'フォーム',
    minWidth: { md: 130, xs: '40vw' },
    flex: 1,
    filterable: false,
    type: 'custom',
    renderProps: props => (
      <CellCustomID
        field={props}
        fieldLink={'forms_title'}
        fieldRender={'forms_title'}
        fieldSearch={'forms_id'}
        linkRefer={'/survey?title='}
        query={useGetAllSurveyQuery}
      />
    ),
  },
  {
    field: 'events_member_type',
    headerName: '対象会員種別',
    minWidth: { md: 200, xs: '50vw' },
    flex: 1,
    type: 'custom',
    filterField: 'member_type',
    filterName: '会員種別',
    renderProps: props => {
      switch (props) {
        case 3:
          return <Typography>全会員</Typography>;
        case 1:
          return <Typography>端末会員</Typography>;
        case 2:
          return <Typography>本会員</Typography>;
        default:
          return <Typography>無効</Typography>;
      }
    },
  },
  {
    field: 'hobby',
    headerName: 'ハッシュタグ',
    minWidth: { md: 200, xs: '50vw' },
    flex: 1,
    type: 'string',
    filterField: 'hobby',
    filterName: 'ハッシュタグ',
    hide: true,
  },
  {
    field: 'events_show_cost',
    headerName: '表示コスト',
    minWidth: { md: 150, xs: '40vw' },
    flex: 1,
    filterable: false,
    type: 'string',
  },
  {
    field: 'events_period_start',
    headerName: '開始日',
    minWidth: { md: 150, xs: '40vw' },
    flex: 1,
    filterable: false,
    type: 'date start',
  },
  {
    field: 'events_period_end',
    headerName: '終了日',
    minWidth: { md: 150, xs: '40vw' },
    flex: 1,
    filterable: false,
    type: 'date end',
  },
  {
    field: 'events_suspended',
    headerName: 'ステータス',
    minWidth: { md: 150, xs: '40vw' },
    flex: 1,
    filterable: false,
    type: 'custom',
    renderProps: props => {
      return props === 0 ? (
        <TextUnderLine>有効</TextUnderLine>
      ) : (
        <TextUnderLine>無効</TextUnderLine>
      );
    },
  },
  {
    field: 'edit',
    headerName: '編集',
    minWidth: { md: 100, xs: '25vw' },
    flex: 1,
    type: 'Edit',
    filterable: false,
  },
  {
    field: 'delete',
    headerName: '削除',
    minWidth: { md: 100, xs: '25vw' },
    flex: 1,
    type: 'Delete',
    filterable: false,
  },
];
export default EventSample;
