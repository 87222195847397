import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import type { AxiosRequestConfig, AxiosError } from 'axios';
import axiosInstance from '../utils/axios/axios';
import { AnyResponseData } from '../utils/axios/types';
const axiosBaseQuery =
  (): BaseQueryFn<
    {
      url: string;
      method: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
    },
    unknown,
    unknown
  > =>
  async ({ url, method, data, params }) => {
    try {
      let result: AnyResponseData = {};
      switch (method?.toUpperCase()) {
        case 'GET':
          result = await axiosInstance.get(url, params);
          break;
        case 'POST':
          result = await axiosInstance.post(url, data, params);
          break;
        case 'DELETE':
          result = await axiosInstance.delete(url, params);
          break;
        case 'PUT':
          result = await axiosInstance.put(url, data, params);
      }
      return { data: result };
    } catch (axiosError) {
      let err = axiosError as AxiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };
export default axiosBaseQuery;
