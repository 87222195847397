import React, { useLayoutEffect, useMemo } from 'react';
import { CircularProgress, Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useGetAllEventQuery } from '../../../services/eventApi';

interface IProps {
  id: number;
  isLink?: boolean;
}

const CellCategory: React.FC<IProps> = ({ id, isLink, ...props }) => {
  const { data = [], isFetching, isError } = useGetAllEventQuery('');
  const result = useMemo(() => {
    return data.find(item => item.events_id === id);
  }, [isFetching]);

  if (!result) {
    return <CircularProgress />;
  }

  return (
    <>
      {isLink ? (
        <Link href={`/event?id=${result.events_id}`}>{result.events_id}</Link>
      ) : (
        <Typography>{result.events_id}</Typography>
      )}
    </>
  );
};

export default CellCategory;
