import { AxiosInstance } from './types';
import axios from 'axios';
import { isBoolean, isEmpty } from 'lodash';
import httpCodes from '../../configs/httpCodes';
import IsAuth from '../auth/IsAuth';

const baseURL = process.env.REACT_APP_BASE_URL;

//const baseUrl = "http://localhost:8000/api/admin/";

const onFulfilledRequest = async config => {
  if (IsAuth() === null) {
    window.location.href = '/login';
  }
  if (isEmpty(config.headers.authorization)) {
    config.headers.authorization = 'Bearer ' + IsAuth();
  }

  if (!isEmpty(config.params)) {
    Object.keys(config.params).map(key => {
      if (isBoolean(config.params[key])) {
        config.params[key] = Number(config.params[key]);
      }
    });
  }
  console.debug(
    config.method?.toUpperCase(),
    (config?.baseURL || '') + config.url,
    config.data,
  );
  return config;
};

const onRejectedRequest = error => {
  return Promise.reject(error);
};

const onFulfilledResponse = response => {
  console.debug(
    response.status,
    response.config.method?.toUpperCase(),
    (response.config.baseURL || '') + response.config.url,
    response.data,
  );
  return response.data;
};

const onRejectedResponse = error => {
  console.debug(
    error.response?.status,
    error.response?.config?.method?.toUpperCase(),
    error.response?.config?.url,
    error,
    error.response?.data || error.response,
  );

  // Timeout
  if (error.code == 'ECONNABORTED') {
    console.error('timeout');
    return { success: false };
  }

  // Couldn't connect to server
  if (error.message == 'Network Error') {
    console.error('networkError');
    return { success: false };
  }

  if (!error.response) {
    console.error('noResponseFromServer');
    return { success: false };
  }

  switch (error.response.status) {
    case httpCodes.UNAUTHORIZED:
      console.error(error.response?.data?.message || 'unauthorize');
      throw new Error(
        error.response?.data?.message || 'Custom message for unauthorize',
      );
    case httpCodes.FORBIDDEN:
      console.error(error.response?.data?.message || 'forbidden');
      throw new Error(
        error.response?.data?.message || 'Custom message for forbidden',
      );
    case httpCodes.BAD_REQUEST:
      throw new Error(
        error.response?.data?.message || 'Custom message for bad request',
      );
    case httpCodes.METHOD_NOT_ALLOW:
      throw new Error(
        error.response?.data?.message || 'Custom message for method not allow',
      );
    case httpCodes.CONFLICT:
      throw new Error(
        error.response?.data?.message || 'Custom message for conflict',
      );
    case httpCodes.NOT_FOUND:
      throw new Error(
        error.response?.data?.message || 'Custom message for Not Found',
      );
  }
};

const commonAxiosInstance = {
  timeout: 15000,
};

const axiosInstance: AxiosInstance = axios.create({
  baseURL: baseURL,
  ...commonAxiosInstance,
});

axiosInstance.interceptors.request.use(onFulfilledRequest, onRejectedRequest);
axiosInstance.interceptors.response.use(
  onFulfilledResponse,
  onRejectedResponse,
);

export default axiosInstance;
