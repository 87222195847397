/**
 *
 * Asynchronously loads the component for UserPointHistoryPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const UserPointHistoryPage = lazyLoad(
  () => import('./index'),
  module => module.UserPointHistoryPage,
);
