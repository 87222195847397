import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid, { GridProps } from '@mui/material/Grid';
import React from 'react';

export const LBox = styled(Box)`
  width: 1200px;
  border: 1px solid #ddd;
  margin-right: auto;
  margin-left: auto;
  @media (max-width: 1201px) {
    width: 90%;
  }
  &.noBorder {
    border: unset;
  }
`;

export const TitlePink = styled(Typography)`
  padding: 5px 10px;
  background-color: #f9f5f5;
`;

export const TextUnderLine = styled(Typography)`
  color: #1e1ef0;
  display: inline-block;
  position: relative;
  &::after {
    content: '';
    width: 100%;
    height: 1px;
    display: inline-block;
    position: absolute;
    bottom: 2px;
    left: 0;
    background-color: #001dff;
  }
`;

export const BoxCenter = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SelectBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 0 20px;
  padding: 50px;
  @media (max-width: 1201px) {
    padding: 5%;
  }
`;

export const Box50 = styled(Box)`
  width: 50%;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const Box35 = styled(Box)`
  width: 35%;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const Box70 = styled(Box)`
  width: 70%;
  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const GridContainer: React.FC<GridProps> = ({ children, ...props }) => {
  return (
    <Grid {...props} container alignItems={'stretch'}>
      {children}
    </Grid>
  );
};

interface IGridItem4 extends GridProps {
  flexCenter?: boolean;
}
export const GridItem4: React.FC<IGridItem4> = ({
  children,
  flexCenter,
  ...props
}) => {
  return (
    <Grid
      {...props}
      item
      md={4}
      xs={12}
      height={'auto'}
      alignItems={flexCenter ? 'center' : 'flex-end'}
      display={'flex'}
    >
      {children}
    </Grid>
  );
};

export const GridItem8: React.FC<GridProps> = ({ children, ...props }) => {
  return (
    <Grid {...props} item md={8} xs={12}>
      {children}
    </Grid>
  );
};
