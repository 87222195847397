/**
 *
 * DataTable
 *
 */
import * as React from 'react';
import { SetStateAction, useEffect, useMemo, useState } from 'react';
import TableContainer from '@mui/material/TableContainer';
import { ToolBar } from '../ToolBar';
import Box from '@mui/material/Box';
import { DivWrap } from '../DivWrap';
import useHasDate from '../../../utils/customHooks/useHasDate';
import { DataTemplate } from '../../../utils/types/dataTemplate';
import { LinearProgress, Table } from '@mui/material';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import LTableHead from './LTableHead';
import TableBody from '@mui/material/TableBody';
import LTableCell from './LTableCell';
import { v4 as uuidv4 } from 'uuid';
import TableCell from '@mui/material/TableCell';

interface Props {
  data: Array<any>;
  loading: boolean;
  error: unknown;
  isError?: boolean;
  template: DataTemplate[];
  title: string;
  fieldFilter?: string;
  setFieldFilter?: React.Dispatch<SetStateAction<string | undefined>>;
  search?: string;
  setSearch?: React.Dispatch<SetStateAction<string>>;
  timeEnd?: number | null;
  setTimeEnd?: React.Dispatch<SetStateAction<number | null>>;
  timeStart?: number | null;
  setTimeStart?: React.Dispatch<SetStateAction<number | null>>;
  order?: 'asc' | 'desc';
  setOrder?: React.Dispatch<SetStateAction<'asc' | 'desc'>>;
  orderBy?: any;
  setOrderBy?: React.Dispatch<SetStateAction<any>>;
  hasImport?: boolean;
  hasRoute?: string;
  noAddButton?: boolean;
  noSearchPanel?: boolean;
  noFilterPanel?: boolean;
  routeEdit?: string;
  totalNumber?: React.ReactNode;
  uuid?: React.ReactNode;
  handleSubmit?: (row: any) => Promise<void>;
}

export function DataTable({
  data,
  loading,
  error,
  isError,
  template,
  title,
  hasImport,
  hasRoute,
  routeEdit,
  fieldFilter,
  search,
  setSearch,
  setFieldFilter,
  timeStart,
  setTimeStart,
  timeEnd,
  setTimeEnd,
  order,
  setOrder,
  orderBy,
  setOrderBy,
  noAddButton,
  noSearchPanel,
  noFilterPanel,
  totalNumber,
  uuid,
  handleSubmit,
}: Props) {
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);

  const fieldsDate = useHasDate(template);
  const checkSortLocal = template
    .map(item => item.hasOwnProperty('sort'))
    .some(item => item);
  const checkSearchLocal = template
    .map(item => item.hasOwnProperty('filterField'))
    .some(item => item);

  // select filter and pagination
  useEffect(() => {
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  }, [search]);

  const result: Array<any> = useMemo(() => {
    if (data === null) {
      data = [];
    }

    let temp = [...data];

    if (timeEnd && timeStart && fieldsDate.start !== '' && !checkSearchLocal) {
      temp = data.filter(item => {
        const date = new Date(item[fieldsDate.start]).getTime();
        return timeStart <= date && timeEnd >= date;
      });
    }

    if (search && search !== '' && !checkSearchLocal) {
      temp = data.filter(item => {
        if (fieldFilter && search) {
          if (typeof item[fieldFilter] === 'number') {
            return item[fieldFilter] === +search;
          }
          return item[fieldFilter].toLowerCase().includes(search.toLowerCase());
        }
      });
    }

    if (orderBy !== null && !checkSortLocal) {
      temp.sort((a, b) => {
        if (b[orderBy] < a[orderBy]) {
          return order === 'asc' ? -1 : 1;
        }
        if (b[orderBy] > a[orderBy]) {
          return order === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }

    setTotalPage(temp.length);

    const firstPageIndex = (currentPage - 1) * rowsPerPage;
    const lastPageIndex = firstPageIndex + rowsPerPage;
    return temp.slice(firstPageIndex, lastPageIndex);
  }, [
    data,
    orderBy,
    order,
    currentPage,
    fieldFilter,
    search,
    timeEnd,
    timeStart,
  ]);

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    if (setOrder) {
      setOrder(isAsc ? 'desc' : 'asc');
    }
    if (setOrderBy) {
      setOrderBy(property);
    }
  };

  return (
    <Box sx={{ overflow: 'hidden' }}>
      <ToolBar
        title={title}
        totalPage={totalPage}
        rowPerPage={rowsPerPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        template={template}
        handleSelectField={setFieldFilter}
        handleSearch={setSearch}
        fieldFilter={fieldFilter}
        search={search}
        setTimeStart={setTimeStart}
        setTimeEnd={setTimeEnd}
        hasImport={hasImport}
        hasRoute={hasRoute}
        noAddButton={noAddButton}
        totalNumber= {`総数: ${totalPage}`}
        noSearchPanel={noSearchPanel}
      />
      <DivWrap>
        {/* user point history */ uuid && uuid}
        <TableContainer
          sx={{
            maxHeight: 700,
            ['msOverflowStyle']: 'none',
            ['::-webkit-scrollbar']: {
              display: 'none',
            },
          }}
        >
          {loading ? (
            <LinearProgress />
          ) : (
            <Table stickyHeader={true} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {template.map(
                    (item, index) =>
                      !item.hide && (
                        <LTableHead
                          key={uuidv4()}
                          sort={item?.sort}
                          sortName={item?.sortName}
                          checkSortLocal={checkSortLocal}
                          field={item.field}
                          headerName={item.headerName}
                          orderBy={orderBy}
                          order={order}
                          minWidth={item.minWidth}
                          createSortHandler={handleRequestSort}
                        />
                      ),
                  )}
                </TableRow>
              </TableHead>
              {result.length !== 0 && !isError ? (
                <TableBody sx={{ width: '100%' }}>
                  {result.map((row, key) => (
                    <TableRow key={key}>
                      {template.map(
                        (item, index) =>
                          !item.hide && (
                            <LTableCell
                              key={uuidv4()}
                              templates={template}
                              templateItem={item}
                              data={row}
                              routeEdit={routeEdit}
                            />
                          ),
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              ) : (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={1000}>データがありません。</TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          )}
        </TableContainer>
      </DivWrap>
    </Box>
  );
}
