import React, { useContext } from 'react';
import Box from '@mui/material/Box';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import TextInput from '../../../components/Form/TextInput';
import SelectInput from '../../../components/Form/SelectInput';
import Button from '@mui/material/Button';
import { DialogContent } from '@mui/material';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useCommonSlice } from '../../../../store/commonSlice';
import { useDispatch, useSelector } from 'react-redux';
import { EventContext } from './index';
import { SelectCommonStateDataAttach } from '../../../../store/commonSlice/selectors';
import ImageUploadV from '../../../components/Form/ImageUploadV';

const valid = yup.object().shape({
  gacha_details_point_amount: yup
    .number()
    .required('ポイント数が必須です')
    .integer('ポイント数が無効です')
    .typeError('ポイント数が無効です'),
  gacha_details_point_percent: yup
    .number()
    .required('ポイントパーセントが必須です')
    .positive('ポイントパーセントが無効です')
    .typeError('ポイントパーセントが無効です'),
  gacha_details_prize_num: yup
    .number()
    .required('ポイントパーセントが必須です')
    .integer('ポイントパーセントが無効です')
    .typeError('ポイントパーセントが無効です'),
  gacha_details_lottery_flag: yup
    .number()
    .min(0)
    .max(1)
    .required('循環フラグが必須です'),
});
const EditGacha = props => {
  const { actions } = useCommonSlice();
  const dispatch = useDispatch();
  const dataAttach = useSelector(SelectCommonStateDataAttach);
  const useGachaContext = useContext(EventContext);
  const methods = useForm<any>({ resolver: yupResolver(valid) });

  const onSubmit: SubmitHandler<any> = data => {
    const temp = [...useGachaContext.gachaForm];
    temp.map((item, index) => {
      if (item['gacha_details_id'] === dataAttach.id) {
        temp[index] = {
          gacha_details_id: temp[index]['gacha_details_id'],
          ...data,
        };
      }
    });
    useGachaContext.setGachaForm(temp);
    dispatch(actions.closeModal());
  };
  return (
    <DialogContent>
      <FormProvider {...methods}>
        <Box component={'form'} onSubmit={methods.handleSubmit(onSubmit)}>
          <Stack spacing={3}>
            <TextInput
              name={'gacha_details_point_amount'}
              labelName={'ガチャ当選ポイント'}
              defaultValue={dataAttach.data['gacha_details_point_amount']}
              showLabel={true}
            />
            <TextInput
              name={'gacha_details_point_percent'}
              labelName={'ポイント当選確率（コスト）'}
              defaultValue={dataAttach.data['gacha_details_point_percent']}
              showLabel={true}
            />
            <TextInput
              name={'gacha_details_prize_num'}
              labelName={'等級'}
              defaultValue={dataAttach.data['gacha_details_prize_num']}
              showLabel={true}
            />
            <ImageUploadV
              name={'gacha_details_result_image_url'}
              labelName={'ガチャ当選結果画像URL'}
              showLabel={true}
              limit={1}
              edit={dataAttach.data['gacha_details_result_image_url']}
              showLabelTextInput={false}
            />
            <SelectInput
              name={'gacha_details_lottery_flag'}
              labelName={'当選ポイント有効フラグ'}
              showLabel={true}
              defaultValue={dataAttach.data['gacha_details_lottery_flag']}
              options={[
                { value: 1, name: '運用中' },
                { value: 0, name: '停止' },
              ]}
            />
          </Stack>
          <Stack
            flexDirection={'row'}
            justifyContent={'flex-end'}
            columnGap={2}
            mt={4}
          >
            <Button
              type={'button'}
              sx={{ color: '#5B5A5A' }}
              onClick={() => dispatch(actions.closeModal())}
            >
              Cancel
            </Button>
            <Button type={'submit'}>Update</Button>
          </Stack>
        </Box>
      </FormProvider>
    </DialogContent>
  );
};

export default EditGacha;
