import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TextField, { StandardTextFieldProps } from '@mui/material/TextField';

interface IProps extends StandardTextFieldProps {
  name: string;
  labelName: string;
  showLabel: boolean;
  defaultValue?: any;
  disable?: boolean;
  multiline?: boolean;
}

const TextInput: React.FC<IProps> = ({
  labelName,
  showLabel,
  name,
  defaultValue = '',
  disable = false,
  multiline,
  ...otherProps
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      render={({ field: { ref, ...field } }) => (
        <TextField
          {...field}
          {...otherProps}
          inputRef={ref}
          multiline={multiline}
          disabled={disable && true}
          variant={'standard'}
          fullWidth
          label={showLabel && labelName}
          error={!!errors[name]}
          helperText={errors[name]?.message}
          sx={{ marginTop: 2 }}
        />
      )}
    />
  );
};

export default TextInput;
