import React, { useState, useEffect } from 'react';
import { useGetStatisticsMutation } from '../../../services/statisticsApi';
import { Layout } from '../../components/Layout';
import { ToolBar } from '../../components/ToolBar';

export interface IStatistics {
    whole_user: number;
    registered_user: number;
    whole_user_ios: number;
    registered_user_ios: number;
    whole_user_android: number;
    registered_user_android: number;
    sms_user: number;
    sms_user_ios: number;
    sms_user_android: number;
    deleted_suspended_user: number;
}

export function StatisticsPage(props) {
    const [startDate, setStartDate] = useState('2022-01-01');
    const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
    const [getStatistics, statisticsResult] = useGetStatisticsMutation();

    useEffect(() => {
        getStatistics({ startDate, endDate });
    }, [startDate, endDate]);

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value);
    };

    // Use type assertion to bypass the TypeScript error
    const statisticsData = statisticsResult.data as IStatistics | undefined;

    return (
        <Layout title="統計データ">
            
                <ToolBar title="統計データ" noSearchPanel={true} noAddButton={true} noFilterPanel={true} />
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>

                <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                <input type="date" value={endDate} onChange={handleEndDateChange} />
                {statisticsData && (
                    <div>
                        <p>全ユーザー: {statisticsData.whole_user}</p>
                        <p>全ユーザーiOS: {statisticsData.whole_user_ios}</p>
                        <p>全ユーザーAndroid: {statisticsData.whole_user_android}</p>
                        <p>-------------------------------------------------</p>
                        <p>以下の統計データは、退会済みまたは強制停止されたユーザー除く</p>
                        <p>本会員: {statisticsData.registered_user}</p>
                        <p>本会員iOS: {statisticsData.registered_user_ios}</p>
                        <p>本会員Android: {statisticsData.registered_user_android}</p>
                        <p>SMSユーザー: {statisticsData.sms_user}</p>
                        <p>SMSユーザーiOS: {statisticsData.sms_user_ios}</p>
                        <p>SMSユーザーAndroid: {statisticsData.sms_user_android}</p>
                        <p>-------------------------------------------------</p>
                        <p>退会済みまたは強制停止されたユーザー数: {statisticsData.deleted_suspended_user}</p>
                        {/* Add other statistics data as needed */}
                    </div>
                )}
            
        </Layout>
    );
}
