import React, { useState } from 'react';
import { useGetAssignHistoryQuery, useGetHistoryByTimeMutation } from '../../../services/importApi';
import { Layout } from '../../components/Layout';
import { ToolBar } from '../../components/ToolBar';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Typography, Button, Modal, Box } from '@mui/material';
import { IImportHistoryByTime } from '../../../services/importApi';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

// Define the interface for the row data
interface RowData {
    id: number;
    num: number;
    failed: number;
    time: string;
    name: string;
}

export function ImportHistoryPage(props) {
    const { data = [] } = useGetAssignHistoryQuery();
    const [getHistoryByTime] = useGetHistoryByTimeMutation();
    const [open, setOpen] = useState(false);
    const [details, setDetails] = useState<IImportHistoryByTime[]>([]);
    const [selectedRow, setSelectedRow] = useState<RowData | null>(null);

    const handleOpen = async (row: RowData) => {
        try {
            const response = await getHistoryByTime(row.time).unwrap();
            setDetails(response);
            setSelectedRow(row);
            setOpen(true);
        } catch (error) {
            console.error('Failed to fetch history by time:', error);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedRow(null);
    };

    return (
        <Layout title="ポイント付与履歴">
            <ToolBar title="ポイント付与履歴" noSearchPanel={true} noAddButton={true} noFilterPanel={true} />
            <br />
            <Paper>
                <Typography variant="h6" component="div" style={{ padding: '16px' }}>
                    ポイント付与履歴
                </Typography>
                <br/>
                <br/>
                <br/>
                <br/>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>データ件数</TableCell>
                            <TableCell>エラー件数</TableCell>
                            <TableCell>ポイント付与実施日</TableCell>
                            <TableCell>ファイル名</TableCell>
                            <TableCell>詳細</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={5} align="center">
                                    No data available
                                </TableCell>
                            </TableRow>
                        ) : (
                            data.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell>{row.num}</TableCell>
                                    <TableCell>{row.failed}</TableCell>
                                    <TableCell>{row.time}</TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleOpen(row)}
                                        >
                                            詳細
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </Paper>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-title" variant="h6" component="h2">
                        ポイント付与履歴詳細
                    </Typography>
                    {selectedRow && (
                        <div>
                            <Typography variant="body1"><strong>ID:</strong> {selectedRow.id}</Typography>
                            <Typography variant="body1"><strong>データ件数:</strong> {selectedRow.num}</Typography>
                            <Typography variant="body1"><strong>エラー件数:</strong> {selectedRow.failed}</Typography>
                            <Typography variant="body1"><strong>ポイント付与実施日:</strong> {selectedRow.time}</Typography>
                            <Typography variant="body1"><strong>ファイル名:</strong> {selectedRow.name}</Typography>
                        </div>
                    )}
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ユーザID</TableCell>
                                <TableCell>メール</TableCell>
                                <TableCell>ポイント</TableCell>
                                <TableCell>成否</TableCell>
                                <TableCell>失敗原因</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {details.length === 0 ? (
                                <TableRow>
                                    <TableCell colSpan={5} align="center">
                                        No data available
                                    </TableCell>
                                </TableRow>
                            ) : (
                                details.map((detail) => (
                                    <TableRow key={detail.user_id}>
                                        <TableCell>{detail.user_id}</TableCell>
                                        <TableCell>{detail.mail}</TableCell>
                                        <TableCell>{detail.point}</TableCell>
                                        <TableCell>
                                            <span style={{ color: detail.success == 1 ? 'inherit' : 'red' }}>
                                                {detail.success == 1 ? "成功" : "失敗"}
                                            </span>
                                        </TableCell>
                                        <TableCell>{detail.reason}</TableCell>
                                    </TableRow>
                                ))
                            )}
                        </TableBody>
                    </Table>
                </Box>
            </Modal>
        </Layout>
    );
}

export default ImportHistoryPage;
