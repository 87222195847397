import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.commonSlice || initialState;

export const SelectCommonStateActionModal = createSelector(
  [selectSlice],
  state => state.actionModal,
);
export const SelectCommonStateModalOpen = createSelector(
  [selectSlice],
  state => state.isOpen,
);
export const SelectCommonStateDataAttach = createSelector(
  [selectSlice],
  state => state.dataAttach,
);
export const SelectCommonStateDataAttachTemp = createSelector(
  [selectSlice],
  state => state.dataAttachTemp,
);
export const SelectQueryFilter = createSelector(
  [selectSlice],
  state => state.queryFilter,
);
export const SelectDataSearch = createSelector(
  [selectSlice],
  state => state.dataSearch,
);

export const SelectMenu = createSelector(
  [selectSlice],
  state => state.menuSelected,
);
export const SelectMenuExpanded = createSelector(
  [selectSlice],
  state => state.menuExpanded,
);

export const selectCommonState = createSelector([selectSlice], state => state);
