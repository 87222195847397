import React, { FormEvent, useCallback, useState, useEffect } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

interface IProps {
  handleTimeStart: (newValue: number | null) => void;
  handleTimeEnd: (newValue: number | null) => void;
}

interface FormElements extends HTMLFormControlsCollection {
  min_date: HTMLInputElement;
  max_date: HTMLInputElement;
}

interface dateInput extends HTMLFormElement {
  readonly elements: FormElements;
}

const DateRangeFilter: React.FC<IProps> = ({
  handleTimeEnd,
  handleTimeStart,
}) => {
  const [errorStart, setErrorStart] = useState<boolean | undefined>(undefined);
  const [errorEnd, setErrorEnd] = useState<boolean | undefined>(undefined);
  const [timeStart, setTimeStart] = useState<Date | null>(null);
  const [timeEnd, setTimeEnd] = useState<Date | null>(null);
  const [resetFilter, setResetFilter] = useState<boolean>(false);


   const adjustWeek = (days: number) => {
  // If timeStart is null, set newStart to 6 days before the current date.
  // Otherwise, use the provided timeStart value.
  const newStart = new Date(timeStart ? timeStart : new Date());
  if (!timeStart) {
    newStart.setDate(newStart.getDate());
  } else {
    newStart.setDate(newStart.getDate() + days);
  }

  // If timeEnd is null, set newEnd to 1 day after the current date.
  // Otherwise, use the provided timeEnd value.
  const newEnd = new Date(timeEnd ? timeEnd : new Date());
  if (!timeEnd) {
    newEnd.setDate(newEnd.getDate() + 1);
  } else {
    newEnd.setDate(newEnd.getDate() + days);
  }
  
    setTimeStart(newStart);
    setTimeEnd(newEnd);
    handleTimeStart(newStart.getTime());
    handleTimeEnd(newEnd.getTime());
   };
  
   const handlePreviousWeek = () => {
    adjustWeek(-1);
   };
  
   const handleNextWeek = () => {
    adjustWeek(1);
   };

  const onChangeTimeStart = (newValue: Date | null) => {
    setTimeStart(newValue);
  };

  const onChangeTimeEnd = (newValue: Date | null) => {
    setTimeEnd(newValue);
  };

  const handleReset = (e: FormEvent) => {
    e.preventDefault();
    setTimeStart(null);
    setTimeEnd(null);
    handleTimeStart(null);
    handleTimeEnd(null);
    setResetFilter(false);
  };

  const handleDateSubmit = useCallback(
    (e: FormEvent<dateInput>): void => {
      e.preventDefault();
      let flag = true;
      const min_date = new Date(
        e.currentTarget.elements.min_date.value,
      ).getTime();
      const max_date = new Date(
        e.currentTarget.elements.max_date.value,
      ).getTime();
      // if (isNaN(min_date) || min_date > max_date) {
      //   flag = false;
      //   setErrorStart(true);
      // }
      // if (isNaN(max_date) || max_date < min_date) {
      //   flag = false;
      //   setErrorEnd(true);
      // }
      if (flag) {
        // setErrorEnd(false);
        // setErrorStart(false);
        if (!isNaN(min_date)) {
          handleTimeStart(min_date);
        } else {
          setTimeStart(null);
          handleTimeStart(null);
        }
        if (!isNaN(max_date)) {
          handleTimeEnd(max_date);
        } else {
          setTimeEnd(null);
          handleTimeEnd(null);
        }
        setResetFilter(true);
      }
    },
    [timeStart, timeEnd, errorEnd, errorStart],
  );
  return (
    <Stack
      component={'form'}
      width={{ md: '500px', xs: '100%' }}
      direction={'row'}
      justifyContent={'center'}
      columnGap={3}
      alignItems={'center'}
      mr={{ xs: 0, md: 2 }}
      onSubmit={handleDateSubmit}
    >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          label="Min Date"
          inputFormat="yyyy/MM/dd"
          mask={'____/__/__'}
          clearable={true}
          renderInput={params => (
            <TextField
              {...params}
              sx={{ width: '100%', fontSize: '1rem' }}
              name={'min_date'}
              variant="standard"
              error={errorStart}
            />
          )}
          onChange={onChangeTimeStart}
          value={timeStart}
        />
        <MobileDatePicker
          label="Max Date"
          inputFormat="yyyy/MM/dd"
          mask={'____/__/__'}
          clearable={true}
          renderInput={params => (
            <TextField
              {...params}
              sx={{ width: '100%', fontSize: '1rem' }}
              name={'max_date'}
              variant="standard"
              error={errorEnd}
            />
          )}
          value={timeEnd}
          onChange={onChangeTimeEnd}
        />
      </LocalizationProvider>
      <Button color={'info'} variant={'contained'} type={'submit'}>
        反映
      </Button>
      <Button color={'secondary'} variant={'contained'} onClick={handlePreviousWeek}>
        前日
      </Button>
      <Button color={'primary'} variant={'contained'} onClick={handleNextWeek}>
        次日
      </Button>
    </Stack>
  );
};

export default DateRangeFilter;
