import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './baseQuery';

export interface IInvitationHistory {
  invitation_histories_id: number;
  invitation_histories_invite_user_id: number;
  invitation_histories_registed_user_id: number;
  invite_user_name: string;
  registed_user_name: string;
  users_active_at: string;
  invitation_histories_give_point_flag: number;
  invitation_histories_status: number;
}

export const invitationHistoryApi = createApi({
  reducerPath: 'invitationHistory_api',
  tagTypes: ['invitationHistory'],
  baseQuery: axiosBaseQuery(),
  endpoints: build => ({
    getInvitationHistory: build.query<IInvitationHistory[], string>({
      query: params => ({
        url: `/invitation_histories${params && '?' + params}`,
        method: 'GET',
      }),
      providesTags: ['invitationHistory'],
      transformResponse: (result: { data_response: IInvitationHistory[] }) =>
        result.data_response,
    }),
  }),
});

export const { useGetInvitationHistoryQuery } = invitationHistoryApi;
