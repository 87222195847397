/**
 *
 * Asynchronously loads the component for PresentEditPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const PresentEditPage = lazyLoad(
  () => import('./index'),
  module => module.PresentEditPage,
);
