/**
 *
 * Asynchronously loads the component for PointRulePage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const PointRulePage = lazyLoad(
  () => import('./index'),
  module => module.PointRulePage,
);
