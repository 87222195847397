import React, { useCallback, useEffect, useState } from 'react';
import { Link, Switch, TableCell } from '@mui/material';
import ActionButton from '../Button/ActionButton';
import { GridColDef } from '@mui/x-data-grid';
import { DataTemplate } from '../../../utils/types/dataTemplate';
import CellCategory from './CellCategory';
import CellEvent from './CellEvent';
import debounce from 'lodash.debounce';
import { useDispatch } from 'react-redux';

interface IProps {
  templateItem: DataTemplate;
  templates: DataTemplate[] | GridColDef[];
  data?: any;
  routeEdit?: string;
}

const LTableCell: React.FC<IProps> = ({
  templateItem,
  templates,
  data,
  routeEdit,
}) => {
  
  if (templateItem.type === 'Delete') {
    // @ts-ignore
    const result = templates.find(item => item.type === 'id');
    if (result) {
      data = data[result.field];
    }
  }

  let value = data[templateItem.field];
  const isValueArray = Array.isArray(value);

  //render options
  if ('renderOptions' in templateItem) {
    templateItem.renderOptions?.map(item => {
      if (item.value === value) {
        value = item.renderName;
      }
    });
  }

  /*
   * check if exit switchFeature
   * call api edit from switchFeature.func
   * switchFuncGetData Not currently in use
   * */
  let switchFuncUpdate;
  let switchFuncGet;
  let switchOptions;
  let switchFuncGetData = {};
  const dispatch = useDispatch();
  if (templateItem.switchFeature) {
    const [update] = templateItem.switchFeature.funcUpdate();
    switchFuncGet = templateItem.switchFeature.funcGet;
    const data2 = dispatch(templateItem.switchFeature.funcGet.initiate(''));
    switchOptions = templateItem.switchFeature.options;
    switchFuncUpdate = update;
    
    // if (Array.isArray(data2)) {
      // console.log("Available")
      const temp = data
      // .find(
      //   item => 
      //     item[switchOptions.id] === data[switchOptions.id],
      // );
      // convert field name to edit field name
      if (temp) {
        console.log(temp)
        templateItem.switchFeature.options.convert.map(item => {
          for (const tempKey in temp) {
            if (item.fieldGetName === tempKey) {
              switchFuncGetData[item.fieldEditName] = temp[tempKey];
            }
          }
        });
      }
    // }
  }

  /*
   * wait to last result then call api
   * time out 2s
   * */
  const debouncedSave = useCallback(
    debounce(
      event =>
        (function () {
          switchFuncUpdate({
            id: data[switchOptions.id],
            body: {
              "member_type" : data["member_type"],
            },
          });
          // refect api
          // dispatch(switchFuncGet.initiate('', { forceRefetch: true }));
        })(),
      2000,
    ),
    [switchFuncUpdate, switchOptions, switchFuncGetData],
  );
  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (switchFuncUpdate && switchOptions && switchFuncGetData) {
      debouncedSave(event);
    }
  };
  

  //link detail
  let resultLinkDetail =
    'linkDetail' in templateItem ? templateItem.linkDetail : '';
  if ('linkDetailParams' in templateItem) {
    templateItem.linkDetailParams?.map(item => {
      resultLinkDetail = resultLinkDetail?.replace(
        item.paramName,
        data[item.refer],
      );
    });
  }
  

  return (
    <TableCell
      sx={{
        flex: templateItem.flex,
        minWidth: templateItem.minWidth,
        wordBreak: 'break-word',
      }}
    >
      {templateItem.type === 'Edit' ? (
        <ActionButton
          data={data}
          type={templateItem.type}
          routeEdit={routeEdit}
        />
      ) : templateItem.type === 'Delete' ? (
        <ActionButton data={data} type={templateItem.type} />
      ) : templateItem.type === 'switch' ? (
        <Switch
          onChange={handleSwitch}
          defaultChecked={
            typeof data[templateItem.field] === 'boolean'
              ? data[templateItem.field]
              : data[templateItem.field] === 1
          }
          disabled={
            data["member_type"] == "deleted_user" ? 
            true : false
          }
        />
      ) : templateItem.type === 'img' ? (
        <img
          src={value}
          alt={value}
          style={{
            display: 'inline-block',
            height: '3rem',
            objectFit: 'contain',
          }}
        />
      ) : templateItem.type === 'link' ? (
        <>
          {isValueArray ? (
            <>
              {value.map((item, index) => (
                <Link
                  href={
                    templateItem?.renderLink
                      ? templateItem.renderLink + item
                      : '/'
                  }
                  sx={{
                    color: '#0014FF',
                    fontWeight: 'bold',
                    textDecorationColor: '#0014FF',
                    textUnderlinePosition: 'under',
                    marginLeft: 1 / 2,
                    marginRight: 1 / 2,
                  }}
                  key={index.toString()}
                >
                  {item}
                </Link>
              ))}
            </>
          ) : (
            <Link
              sx={{
                color: '#0014FF',
                fontWeight: 'bold',
                textDecorationColor: '#0014FF',
                textUnderlinePosition: 'under',
              }}
            >
              {value}
            </Link>
          )}
        </>
      ) : templateItem.type === 'detail' ? (
        <Link
          href={resultLinkDetail}
          sx={{
            color: '#0014FF',
            fontWeight: 'bold',
            textDecorationColor: '#0014FF',
            textUnderlinePosition: 'under',
          }}
        >
          {templateItem.field}
        </Link>
      ) : templateItem.type === 'referenceCategory' ? (
        <CellCategory id={data[templateItem.field]} />
      ) : templateItem.type === 'referenceEvent' ? (
        <CellEvent isLink={true} id={data[templateItem.field]} />
      ) : templateItem.type === 'custom' ? (
        templateItem.renderProps &&
        templateItem.renderProps(data[templateItem.field])
      ) : (
        value
      )}
    </TableCell>
  );
};

export default LTableCell;
