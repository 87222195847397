/**
 *
 * CustomModal
 *
 */
import React from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { Dialog, Slide } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import DialogTitle from '@mui/material/DialogTitle';
import {
  SelectCommonStateActionModal,
  SelectCommonStateModalOpen,
} from '../../../store/commonSlice/selectors';
import Stack from '@mui/material/Stack';
import { useCommonSlice } from '../../../store/commonSlice';

interface Props {
  onDelete?: any;
  addForm?: React.ReactNode;
  editForm?: React.ReactNode;
  deleteForm?: React.ReactNode;
  importFile?: React.ReactNode;
}

export function RouteModal(props: Props) {
  const { addForm, editForm, deleteForm, importFile } = props;
  const typeAction = useSelector(SelectCommonStateActionModal);
  const open = useSelector(SelectCommonStateModalOpen);
  const { actions } = useCommonSlice();
  const dispatch = useDispatch();
  return (
    <Dialog
      fullWidth={true}
      onClose={() => dispatch(actions.closeModal())}
      open={open}
      TransitionComponent={Transition}
      sx={{ zIndex: 1200 }}
    >
      <DialogTitle
        component={Stack}
        flexDirection={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        mb={2}
      >
        {typeAction}
      </DialogTitle>
      {typeAction === 'Delete' ? (
        <>{deleteForm}</>
      ) : typeAction === 'Add' ? (
        <>{addForm}</>
      ) : typeAction === 'Edit' ? (
        <>{editForm}</>
      ) : typeAction === 'Import' ? (
        <>{importFile}</>
      ) : (
        'something went wrong'
      )}
    </Dialog>
  );
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});
