import React from 'react';
import {
  GridContainer,
  GridItem4,
  GridItem8,
  LBox,
  TitlePink,
} from '../../../components/Common/common';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import SelectImage from '../../../components/Form/SelectImage';

const BannerForm = ({ bannerOptions, handleOpenModal, editData }) => {
  return (
    <LBox mt={5}>
      <TitlePink>バナーのみの表示</TitlePink>
      <Stack mt={4} p={4} rowGap={{ md: 2, xs: 5 }}>
        {/* banner */}
        <GridContainer>
          <GridItem4 flexCenter={true}>
            <InputLabel htmlFor={'banner_id'}>使用バナー</InputLabel>
          </GridItem4>
          <GridItem8>
            <SelectImage
              name={'banner_id'}
              labelName={''}
              showLabel={false}
              options={bannerOptions}
              defaultValue={editData.banners_id}
              numOfAddForm={2}
              handleOpenModal={handleOpenModal}
            />
          </GridItem8>
        </GridContainer>
      </Stack>
    </LBox>
  );
};

export default BannerForm;
