import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import isAuth from './IsAuth';

interface IProps extends RouteProps {
  children: React.ReactNode;
  path: string;
}

const PrivateRoute: React.FC<IProps> = ({ children, path, ...rest }) => {
  return (
    <Route
      path={path}
      exact={true}
      render={({ location }) => {
        return isAuth() !== null ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
