import React, { useState, useEffect } from 'react';
import { useSubmitMutation, useSubmitDeleteUrlMutation, useGetAdsQuery} from '../../../services/gmoOfferwallApi';
import { Layout } from '../../components/Layout';
import { ToolBar } from '../../components/ToolBar';
import { DataTable } from '../../components/DataTable';


export const OfferwallModifyPage: React.FC = () => {
    const [url, setUrl] = useState<string>('');
    const [icon_url, setIcon_url] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [requirements, setRequirements] = useState<string>('');
    const [point, setPoint] = useState<string>('');
    const [os, setOs] = useState<string>('');
    const [expire_at, setExpire_at] = useState<string>('2030-12-30 00:00:00');
    const [notes, setNotes] = useState<string>('');
    const [stages, setStages] = useState<string>('');
    const [event_id, setEvent_id] = useState<string>('');
    const [bonus_point, setBonus_point] = useState<string>('');
    const [max_point, setMax_point] = useState<string>('');
    const [delete_url, setDelete_url] = useState<string>('');

    // Use the mutation hook once
    const [submitData, { isLoading, isError }] = useSubmitMutation();
    const [submitDeleteUrlData, { isLoading: isDelLoading, isError: isDelError }] = useSubmitDeleteUrlMutation();
    const { data = [] } = useGetAdsQuery();

    const handleSubmit = async () => {
        if (url && icon_url && title && requirements && point && os && expire_at) {
            await submitData({ url: url, icon_url: icon_url, title: title, requirements: requirements, point: point, os: os, expire_at: expire_at, notes: notes, stages: stages, event_id: event_id, bonus_point: bonus_point, max_point: max_point});

            if (isError) {
                window.alert('更新失敗');
            } else {
                window.alert('更新リクエスト成功発送しました');
            }
        } else {
            window.alert('すべての情報を入力する必要がある!');
        }
    };

    const handleDeleteUrlSubmit = async () => {
        if (delete_url) {
            await submitDeleteUrlData({ delete_url: delete_url});

            if (isDelError) {
                window.alert('更新失敗');
            } else {
                window.alert('更新リクエスト成功発送しました！');
            }
        } else {
            window.alert('urlを入力する必要がある!');
        }
    };

        // Fetch ads data on component mount


    return (
        <Layout title="案件追加削除">
            <ToolBar title="案件追加削除" noSearchPanel={true} noAddButton={true} noFilterPanel={true} />

        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <a>案件登録</a>
            <a>osについて、iOSの場合0、Androidの場合1、webの場合かつ両OS URL共通の場合2、を入れてください。expire_atのフォーマット: 2030-12-30 00:00:00 (デフォルト入力済み)
            </a>
            <a>案件の情報は、以下から取得</a>
            <a>Android：https://wall.smaad.net/wall/468517210/?u=test</a><a>iOS：https://wall.smaad.net/wall/693803988/?u=test</a>
            <a>event_idは、基本入力しない、高単価案件にしたい場合のみ17を入力。stagesは、多段階の説明です、各段階達成条件とポイントを示す、多段階じゃない案件入力しない。notesは、案件ご注意事項です。必要な場合入力。</a>
            <a>bonus_pointは、基本入力しない、還元率50%超えって還元したい場合入力。bonus_pointを入力際、その値が、pointの値と一致しなければならない。</a>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '1px', width: '100vh' }}>
                <input 
                    type="text" 
                    placeholder="url" 
                    value={url} 
                    onChange={e => setUrl(e.target.value)}
                />
                <input 
                    type="text" 
                    placeholder="icon_url" 
                    value={icon_url} 
                    onChange={e => setIcon_url(e.target.value)}
                />
                <input 
                    type="text" 
                    placeholder="title" 
                    value={title} 
                    onChange={e => setTitle(e.target.value)}
                />
                <input 
                    type="text" 
                    placeholder="requirements" 
                    value={requirements} 
                    onChange={e => setRequirements(e.target.value)}
                />
                <input 
                    type="number" 
                    placeholder="point" 
                    value={point} 
                    onChange={e => setPoint(e.target.value)}
                />
                <input 
                    type="number" 
                    placeholder="os" 
                    value={os} 
                    onChange={e => setOs(e.target.value)}
                />
                <input 
                    type="text" 
                    placeholder="expire_at" 
                    value={expire_at} 
                    onChange={e => setExpire_at(e.target.value)}
                />
                <textarea
                placeholder="stages"
                value={stages}
                onChange={e => setStages(e.target.value)}
                style={{ resize: 'vertical', height: '100px' }} // Allows vertical resizing
                
                />
                <textarea 
                    placeholder="notes" 
                    value={notes} 
                    onChange={e => setNotes(e.target.value)}
                    style={{ resize: 'vertical', height: '100px' }} // Allows vertical resizing
                />
                <input 
                    type="number" 
                    placeholder="event_id" 
                    value={event_id} 
                    onChange={e => setEvent_id(e.target.value)}
                />
                <input 
                    type="number" 
                    placeholder="bonus_point" 
                    value={bonus_point} 
                    onChange={e => setBonus_point(e.target.value)}
                />
                <input 
                    type="number" 
                    placeholder="max_point" 
                    value={max_point} 
                    onChange={e => setMax_point(e.target.value)}
                />
                <button onClick={handleSubmit}>オファーウォール案件を登録</button>
            </div>

            <br/>
            <br/>

            <a>案件削除</a>
            <a>削除したい案件のurlを入力してください</a>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100vh'}}>
                <input 
                    type="text" 
                    placeholder="url" 
                    value={delete_url} 
                    onChange={e => setDelete_url(e.target.value)}
                />
                <button onClick={handleDeleteUrlSubmit}>案件削除</button>
            </div>

            <br/>
            <br/>

            {/* <h2>案件一覧</h2>
            {isLoadingAds ? (
              <p>スマホアプリを参照...</p>
            ) : isErrorAds ? (
              <p>Error fetching ads.</p>
            ) : (
              adsData && adsData.map((ad, index) => (
                <div key={index}>
                  <p>URL: {ad.url}</p>
                  <p>Icon URL: {ad.icon_url}</p>
                  <p>Title: {ad.title}</p>
                  <p>Requirements: {ad.requirements}</p>
                  <p>Point: {ad.point}</p>
                  <p>OS: {ad.os}</p>
                  <p>Expire At: {ad.expire_at}</p>
                </div>
              ))
            )} */}
            
        </div>
        </Layout>
    );
}

export default OfferwallModifyPage;
