import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

interface IProps {
  name: string;
  labelName: string;
  showLabel: boolean;
  defaultValue?: any;
  disable?: boolean;
  type?: 'date' | 'time' | 'dateTime';
  inputFormat?: string;
  mask?: string;
}

const DateInput: React.FC<IProps> = ({
  name,
  labelName,
  showLabel,
  defaultValue,
  type,
  inputFormat,
  mask,
  disable,
  ...otherProps
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  let Element;
  let views;
  switch (type) {
    case 'date':
      Element = MobileDatePicker;
      views = ['year', 'month', 'day'];
      break;
    case 'time':
      Element = MobileTimePicker;
      views = ['hours', 'minutes', 'seconds'];
      break;
    default:
      Element = MobileDateTimePicker;
      views = ['year', 'month', 'day', 'hours', 'minutes', 'seconds'];
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { ref, ...field } }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Element
            clearable
            value={field.value}
            onChange={field.onChange}
            views={views}
            label={showLabel && labelName}
            inputFormat={inputFormat ? inputFormat : 'yyyy/MM/dd HH:mm:ss'}
            mask={mask ? mask : '____/__/__ __:__:__'}
            renderInput={param => (
              <TextField
                {...param}
                {...otherProps}
                inputRef={ref}
                fullWidth={true}
                variant={'standard'}
                error={!!errors[name]}
                helperText={errors[name]?.message}
                sx={{ marginTop: 2 }}
              />
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default DateInput;
