// Define a service using a base URL and expected endpoints
import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './baseQuery';
import { eventApi } from './eventApi';

export interface IBannerResponse {
  banner_description: string;
  banner_id: number;
  banner_image_url: string;
  banner_title: string;
  banner_transition_url: string;
  event_id: number;
}

export const bannerApi = createApi({
  reducerPath: 'banner_api',
  tagTypes: ['banners'],
  baseQuery: axiosBaseQuery(),
  endpoints: build => ({
    getAllBanner: build.query<IBannerResponse[], string>({
      query: params => ({
        url: `/banners${params && '?' + params}`,
        method: 'GET',
      }),
      providesTags: ['banners'],
      transformResponse: (result: { data: IBannerResponse[] }) => result.data,
    }),
    createBanner: build.mutation({
      query: body => ({
        url: '/banners',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['banners'],
      async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            eventApi.endpoints.getFormModalBannerPoint.initiate(undefined, {
              forceRefetch: true,
            }),
          );
        } catch (err) {
          console.log(err);
        }
      },
    }),
    updateBanner: build.mutation({
      query: ({ id, body }) => ({
        url: `/banners/${id}`,
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: ['banners'],
    }),
    deleteBanner: build.mutation({
      query: id => ({
        url: `/banners/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['banners'],
    }),
  }),
});

export const {
  useCreateBannerMutation,
  useDeleteBannerMutation,
  useUpdateBannerMutation,
  useGetAllBannerQuery,
} = bannerApi;
