import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Controller, useFormContext } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import QuickAddButton from '../Button/QuickAddButton';
import Typography from '@mui/material/Typography';
import { BoxCenter } from '../Common/common';
import { CircularProgress } from '@mui/material';

type options = {
  value: string | number;
  name: string;
  url: string;
};

interface IProps {
  name: string;
  labelName: string;
  showLabel: boolean;
  options: Array<options>;
  defaultValue?: any;
  disable?: boolean;
  numOfAddForm: number;
  handleOpenModal?: (num: number) => void;
}

const SelectImage: React.FC<IProps> = ({
  labelName,
  showLabel,
  name,
  options,
  defaultValue,
  disable = false,
  numOfAddForm,
  handleOpenModal,
}) => {
  const [img, setImg] = useState<string>('');
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext();
  const watchField = watch(name);
  useEffect(() => {
    if (watchField) {
      options.map(item => {
        if (item.value === watchField) {
          setImg(item.url);
        }
      });
    }
  }, [watchField]);
  useEffect(() => {
    if (defaultValue !== '' && defaultValue !== undefined) {
      setValue(name, defaultValue);
      options.map(item => {
        if (item.value === defaultValue) {
          setImg(item.url);
        }
      });
    }
  }, []);
  return options.length > 0 ? (
    <Stack flexDirection={'row'} alignItems={'center'} columnGap={10}>
      <Box width={'40%'}>
        <Controller
          name={name}
          defaultValue={defaultValue ? defaultValue : ''}
          control={control}
          render={({ field: { ref, ...field } }) => (
            <TextField
              {...field}
              inputRef={ref}
              select
              variant={'standard'}
              fullWidth
              label={showLabel && labelName}
              error={!!errors[name]}
              helperText={errors[name]?.message}
            >
              {options.map((item, index) => (
                <MenuItem key={index} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </TextField>
          )}
        />
      </Box>
      <Box>
        <QuickAddButton
          onClick={() => handleOpenModal && handleOpenModal(numOfAddForm)}
        />
      </Box>
      <Box width={'30%'}>
        {img !== '' ? (
          <Box
            overflow={'hidden'}
            height={'65px'}
            display={'flex'}
            alignItems={'center'}
          >
            <img
              style={{ maxWidth: '100%', maxHeight: '100%' }}
              src={img}
              alt={'banner'}
            />
          </Box>
        ) : (
          <BoxCenter
            height={'65px'}
            sx={{ backgroundColor: '#ddd' }}
            border={'1px solid #333'}
          >
            <Typography>選択バナーの画像を表示</Typography>
          </BoxCenter>
        )}
      </Box>
    </Stack>
  ) : (
    <CircularProgress />
  );
};

export default SelectImage;
