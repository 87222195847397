import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './baseQuery';

export interface ICCategoryResponse {
  categories_id: number;
  categories_title: string;
  categories_sort: number;
  present_num: number;
}

export const categoryApi = createApi({
  reducerPath: 'category_api',
  tagTypes: ['category'],
  baseQuery: axiosBaseQuery(),
  endpoints: build => ({
    getCategories: build.query<ICCategoryResponse[], string>({
      query: params => ({
        url: `/categories${params && '?' + params}`,
        method: 'GET',
      }),
      providesTags: ['category'],
      transformResponse: (result: { data_response: ICCategoryResponse[] }) =>
        result.data_response,
    }),
    createCategory: build.mutation({
      query: body => ({
        url: '/categories',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['category'],
    }),
    updateCategory: build.mutation({
      query: ({ id, body }) => ({
        url: `/categories/${id}`,
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: ['category'],
    }),
    deleteCategories: build.mutation({
      query: id => ({
        url: `/categories/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['category'],
    }),
  }),
});

export const {
  useCreateCategoryMutation,
  useDeleteCategoriesMutation,
  useGetCategoriesQuery,
  useUpdateCategoryMutation,
} = categoryApi;
