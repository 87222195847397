/**
 *
 * EventAddPage
 *
 */
import * as React from 'react';
import { createContext, useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Layout } from '../../../components/Layout';
import { ToolBar } from '../../../components/ToolBar';
import Box from '@mui/material/Box';
import { DivWrap } from '../../../components/DivWrap';
import {
  Box35,
  Box70,
  GridContainer,
  GridItem4,
  GridItem8,
  LBox,
  TitlePink,
} from '../../../components/Common/common';
import InputLabel from '@mui/material/InputLabel';
import TextInput from '../../../components/Form/TextInput';
import SelectInput from '../../../components/Form/SelectInput';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DateInput from '../../../components/Form/DateInput';
import InputAdornment from '@mui/material/InputAdornment';
import GachaForm from './GachaForm';
import { RouteModal } from '../../../components/RouteModal';
import { useDispatch, useSelector } from 'react-redux';
import { useCommonSlice } from '../../../../store/commonSlice';
import { default as AddBanner } from '../../BannerPage/AddForm';
import { default as AddModal } from '../../ModalPage/AddForm';
import { default as AddPoint } from '../../PointRulePage/AddForm';
import PointForm from './PointForm';
import FormDetail from './FormDetail';
import InvitationForm from './InvitationForm';
import BannerForm from './BannerForm';
import ModalForm from './ModalForm';
import {
  useGetEventListQuery,
  useGetFormModalBannerPointQuery,
  useUpdateEventMutation,
} from 'services/eventApi';
import moment from 'moment';
import useMessage from '../../../../utils/customHooks/useMessage';
import {
  IGachaContext2,
  IGachaFormEdit,
  IInvitationForm,
} from '../EventAddPage/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useValidation } from '../EventAddPage/validation';
import useFocusError from '../../../../utils/customHooks/useFocusError';
import { toast } from 'react-toastify';
import AddInvitation from './AddInvitation';
import EditGacha from './EditGacha';
import EditInvitation from './EditInvitation';
import {
  SelectCommonStateDataAttach,
  SelectCommonStateDataAttachTemp,
} from '../../../../store/commonSlice/selectors';
import { LinearProgress } from '@mui/material';
import AddGacha from './AddGacha';
import CheckBoxInputV from '../../../components/Form/CheckBoxInputV';
import address from '../../../../utils/types/addressList';
import { CheckNull } from '../../../../utils/funcs/CheckNull';
import { useParams } from 'react-router-dom';

interface Props {}

export const EventContext = createContext({} as IGachaContext2);

export function EventEditPage(props: Props) {
  const { event_id } = useParams<{ event_id: string }>();
  const [eventForm, setEventForm] = useState<number>(1);
  const [addForm, setAddForm] = useState<number>(0);
  const [editForm, setEditForm] = useState<number>(0);
  const [gachaForm, setGachaForm] = useState([] as IGachaFormEdit[]);
  const [invitationForm, setInvitationForm] = useState([] as IInvitationForm[]);
  const dispatch = useDispatch();
  const { actions: commonActions } = useCommonSlice();
  const dataAttach = useSelector(SelectCommonStateDataAttach);
  const dataAttachTemp = useSelector(SelectCommonStateDataAttachTemp);
  const realValidation = useValidation(eventForm);
  const methods = useForm({
    mode: 'onBlur',
    resolver: yupResolver(realValidation),
  });
  const {
    watch,
    setFocus,
    formState: { errors },
  } = methods;
  const { data: formModalPointBannerData } = useGetFormModalBannerPointQuery();
  const {
    data: dataEventList,
    isFetching: fetchingEventList,
    isError: isErrorEventList,
    error: errorEventList,
  } = useGetEventListQuery({ id: event_id, params: '' });

  const [updateEvent, { isLoading: loading, isSuccess, isError, error }] =
    useUpdateEventMutation();

  useEffect(() => {
    if (dataEventList) {
      setEventForm(dataEventList.events_type);
    }
  }, [dataEventList]);

  const eventFormWatch = watch('event_type');
  useEffect(() => {
    if (eventFormWatch) {
      setEventForm(eventFormWatch);
    }
  }, [eventFormWatch, eventForm]);

  useMessage(loading, isError, isSuccess, error, false, '/event');

  const bannerOptions = useMemo(() => {
    if (
      formModalPointBannerData &&
      formModalPointBannerData.banners_id.length > 0
    ) {
      const nData: any = [];
      formModalPointBannerData.banners_id.map((item, index) => {
        nData.push({
          name: formModalPointBannerData.banners_title[index],
          value: item,
          url: formModalPointBannerData.banners_image_url[index],
        });
      });
      nData.push({
        name: "なし",
        value: null,
        url: null,
      })
      return nData;
    } else {
      return [({
        name: "なし",
        value: null,
        url: null,
      })];
    }
  }, [formModalPointBannerData]);

  const modalOptions = useMemo(() => {
    if (
      formModalPointBannerData &&
      formModalPointBannerData.modals_id.length > 0
    ) {
      const nData: any = [];
      formModalPointBannerData.modals_id.map((item, index) => {
        nData.push({
          name: formModalPointBannerData.modals_title[index],
          value: item,
          url: formModalPointBannerData.modals_image_url[index],
        });
      });
      nData.push({
        name: "なし",
        value: null,
        url: null,
      })
      return nData;
    } else {
      return [({
        name: "なし",
        value: null,
        url: null,
      })];
    }
  }, [formModalPointBannerData]);

  const pointRuleOptions = useMemo(() => {
    if (
      formModalPointBannerData &&
      formModalPointBannerData.point_rules_id.length > 0
    ) {
      const nData: any = [];
      formModalPointBannerData.point_rules_id.map((item, index) => {
        nData.push({
          name: formModalPointBannerData.point_rules_title[index],
          value: item,
        });
      });
      return nData;
    } else {
      return [];
    }
  }, [formModalPointBannerData]);

  const onSubmit: SubmitHandler<any> = (data: any) => {
    const {
      target_address,
      target_gender,
      point_expire_date,
      event_daily_time_start,
      event_daily_time_end,
      event_period_end,
      event_period_start,
      target_hobby,
      target_job,
      event_type,
    } = data;

    data.event_period_start = event_period_start
      ? moment(event_period_start).format('yyyy/MM/DD')
      : '';
    data.event_period_end = event_period_end
      ? moment(event_period_end).format('yyyy/MM/DD')
      : '';
    data.event_daily_time_start = event_daily_time_start
      ? moment(event_daily_time_start).format('HH:mm:ss')
      : '';
    data.event_daily_time_end = event_daily_time_end
      ? moment(event_daily_time_end).format('HH:mm:ss')
      : '';
    data.event_suspended = data.event_suspended === 1;
    data.point_expire_date = +point_expire_date;

    if (target_gender === 3) {
      data.target_gender = null;
    }

    if (target_address === 99) {
      data.target_address = null;
    }

    if (target_hobby.length > 0) {
      data.target_hobby = target_hobby.join();
    } else {
      data.target_hobby = null;
    }

    if (target_job.length > 0) {
      data.target_job = target_job.join();
    } else {
      data.target_job = null;
    }

    if (data.form_id === '') {
      const { form_id, ...rest } = data;
      data = rest;
    }
    if (data.modal_id === '') {
      const { modal_id, ...rest1 } = data;
      data = rest1;
    }
    if (data.banner_id === '') {
      const { banner_id, ...rest2 } = data;
      data = rest2;
    }
    if (data.point_rule_id === '') {
      const { point_rule_id, ...rest3 } = data;
      data = rest3;
    }

    let flagRequest = false;

    if (event_type === 2) {
      delete data.inv_need_days;
      delete data.invited_user_point;
      delete data.inv_number;
      delete data.inv_repeat_flag;
      if (gachaForm.length > 0) {
        if (data.total !== 100) {
          toast.error('合計ポイントパーセントが無効です', {
            position: 'bottom-left',
          });
        } else {
          data.gacha_detail_records = gachaForm.map(item => ({
            ...item,
            gacha_details_lottery_flag: item.gacha_details_lottery_flag === 1,
          }));
          updateEvent({
            id: dataAttachTemp.events_id,
            body: JSON.stringify(data),
          });
        }
      } else {
        toast.error('ポイント数が無効です', {
          position: 'bottom-left',
        });
      }
      flagRequest = true;
    }

    if (event_type === 4) {
      delete data.limit_lottery_all_user_per_hour;
      delete data.limit_lottery_point_one_user_per_hour;
      if (invitationForm.length > 0) {
        data.inv_repeat_flag = data.inv_repeat_flag === 0;
        data.invitation_detail_records = invitationForm.map(item => ({
          ...item,
        }));
        updateEvent({
          id: dataAttachTemp.events_id,
          body: JSON.stringify(data),
        });
      } else {
        toast.error('ポイント数が無効です', {
          position: 'bottom-left',
        });
      }
      flagRequest = true;
    }

    if (!flagRequest) {
      delete data.limit_lottery_all_user_per_hour;
      delete data.limit_lottery_point_one_user_per_hour;
      delete data.inv_need_days;
      delete data.invited_user_point;
      delete data.inv_number;
      delete data.inv_repeat_flag;
      updateEvent({
        id: dataAttachTemp.events_id,
        body: JSON.stringify(data),
      });
    }
  };

  useFocusError(errors, setFocus);

  let RealAddForm;
  switch (addForm) {
    case 1:
      RealAddForm = AddGacha;
      break;
    case 2:
      RealAddForm = AddBanner;
      break;
    case 3:
      RealAddForm = AddModal;
      break;
    case 4:
      RealAddForm = AddPoint;
      break;
    case 5:
      RealAddForm = AddInvitation;
      break;
    default:
      RealAddForm = AddGacha;
  }

  let RealEditForm;
  switch (editForm) {
    case 1:
      RealEditForm = EditGacha;
      break;
    case 2:
      RealEditForm = EditInvitation;
      break;
    default:
      RealEditForm = EditGacha;
  }

  const handleOpenModal = num => {
    setAddForm(num);
    dispatch(
      commonActions.toggleModal({
        actionModal: 'Add',
        dataAttach: null,
      }),
    );
  };

  const handleEditModal = (num, id, data) => {
    setEditForm(num);
    dispatch(
      commonActions.toggleModal({
        actionModal: 'Edit',
        dataAttach: { id, data },
      }),
    );
  };

  return (
    <EventContext.Provider
      value={{ setGachaForm, gachaForm, invitationForm, setInvitationForm }}
    >
      <Layout title={'イベント編集'}>
        <Box sx={{ overflow: 'hidden' }}>
          <ToolBar
            title={'イベント編集'}
            noSearchPanel={true}
            noFilterPanel={true}
            hasRoute={'/event'}
            btnBack={true}
          />
          <DivWrap>
            {fetchingEventList ? (
              <LinearProgress />
            ) : isErrorEventList ? (
              <Typography>データがありません。</Typography>
            ) : (
              dataEventList && (
                <FormProvider {...methods}>
                  <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <LBox>
                      <TitlePink>イベント内容設定</TitlePink>
                      <Stack
                        mt={4}
                        pl={4}
                        pb={4}
                        pr={{ md: 0, xs: 4 }}
                        rowGap={{ md: 2, xs: 5 }}
                      >
                        {/* title */}
                        <GridContainer>
                          <GridItem4>
                            <InputLabel htmlFor={'event_title'}>
                              イベント名
                            </InputLabel>
                          </GridItem4>
                          <GridItem8>
                            <Box35>
                              <TextInput
                                name={'event_title'}
                                labelName={'イベント名'}
                                defaultValue={dataEventList.events_title}
                                showLabel={false}
                              />
                            </Box35>
                          </GridItem8>
                        </GridContainer>
                        {/* description */}
                        <GridContainer>
                          <GridItem4>
                            <InputLabel htmlFor={'event_description'}>
                              イベント詳細
                            </InputLabel>
                          </GridItem4>
                          <GridItem8>
                            <Box70>
                              <TextInput
                                name={'event_description'}
                                labelName={'イベント詳細'}
                                defaultValue={dataEventList.events_description}
                                showLabel={false}
                                multiline={true}
                              />
                            </Box70>
                          </GridItem8>
                        </GridContainer>
                        {/* member_type */}
                        <GridContainer>
                          <GridItem4>
                            <InputLabel htmlFor={'event_member_type'}>
                              対象会員種別
                            </InputLabel>
                          </GridItem4>
                          <GridItem8>
                            <Box35>
                              <SelectInput
                                name={'event_member_type'}
                                labelName={''}
                                showLabel={false}
                                defaultValue={dataEventList.events_member_type}
                                options={[
                                  { name: '本会員', value: 2 },
                                  { name: '端末会員', value: 1 },
                                  { name: '全会員', value: 3 },
                                ]}
                              />
                            </Box35>
                          </GridItem8>
                        </GridContainer>
                        {/* target_birthday */}
                        <GridContainer>
                          <GridItem4>
                            <InputLabel htmlFor={'target_birthday_from'}>
                              対象生年月日
                            </InputLabel>
                          </GridItem4>
                          <GridItem8>
                            <Box35
                              display={'flex'}
                              alignItems={'flex-end'}
                              justifyContent={'space-between'}
                            >
                              <Box35 display={'flex'} alignItems={'flex-end'}>
                                <TextInput
                                  type={'number'}
                                  inputProps={{ min: 0, max: 100 }}
                                  name={'target_birthday_from'}
                                  defaultValue={CheckNull(
                                    dataEventList.events_target_birthday_from,
                                  )}
                                  labelName={''}
                                  showLabel={false}
                                />
                                <InputLabel htmlFor={'target_birthday_from'}>
                                  歳
                                </InputLabel>
                              </Box35>
                              <Typography>~</Typography>
                              <Box35 display={'flex'} alignItems={'flex-end'}>
                                <TextInput
                                  type={'number'}
                                  inputProps={{ min: 0, max: 100 }}
                                  name={'target_birthday_to'}
                                  defaultValue={CheckNull(
                                    dataEventList.events_target_birthday_to,
                                  )}
                                  labelName={''}
                                  showLabel={false}
                                />
                                <InputLabel htmlFor={'target_birthday_to'}>
                                  歳
                                </InputLabel>
                              </Box35>
                            </Box35>
                          </GridItem8>
                        </GridContainer>
                        {/* target_gender */}
                        <GridContainer>
                          <GridItem4>
                            <InputLabel htmlFor={'target_gender'}>
                              対象性別
                            </InputLabel>
                          </GridItem4>
                          <GridItem8>
                            <Box35>
                              <SelectInput
                                name={'target_gender'}
                                labelName={''}
                                showLabel={false}
                                defaultValue={
                                  dataEventList.events_target_gender === null
                                    ? 3
                                    : dataEventList.events_target_gender
                                }
                                options={[
                                  { name: '男性', value: 1 },
                                  { name: '女性', value: 2 },
                                  { name: '全て', value: 3 },
                                  { name: '無回答', value: 9 },
                                ]}
                              />
                            </Box35>
                          </GridItem8>
                        </GridContainer>
                        {/* target_address */}
                        <GridContainer>
                          <GridItem4>
                            <InputLabel htmlFor={'target_address'}>
                              対象居住地
                            </InputLabel>
                          </GridItem4>
                          <GridItem8>
                            <Box35>
                              <SelectInput
                                name={'target_address'}
                                labelName={''}
                                showLabel={false}
                                defaultValue={
                                  dataEventList.events_target_address === null
                                    ? 99
                                    : dataEventList.events_target_address
                                }
                                options={address.map((item, index) => {
                                  if (index + 1 === address.length) {
                                    return { value: 99, name: '全て' };
                                  }
                                  return { value: index + 1, name: item };
                                })}
                              />
                            </Box35>
                          </GridItem8>
                        </GridContainer>
                        {/* target_job */}
                        <GridContainer>
                          <GridItem4 flexCenter>
                            <InputLabel htmlFor={'target_job'}>
                              対象仕事
                            </InputLabel>
                          </GridItem4>
                          <GridItem8>
                            <Stack flexDirection={'row'} flexWrap={'wrap'}>
                              <CheckBoxInputV
                                defaultValue={
                                  dataEventList.events_target_job === null
                                    ? undefined
                                    : dataEventList.events_target_job
                                        .split(',')
                                        .map(Number)
                                }
                                name={'target_job'}
                                showLabel={true}
                                dataCheck={[
                                  { name: '会社員', value: 1 },
                                  { name: '自営業', value: 2 },
                                  { name: '公務員', value: 3 },
                                  { name: '学生', value: 4 },
                                  { name: '経営者', value: 5 },
                                  { name: '専業主婦', value: 6 },
                                  { name: 'その他', value: 99 },
                                ]}
                              />
                            </Stack>
                          </GridItem8>
                        </GridContainer>
                        {/* target_hobby */}
                        <GridContainer>
                          <GridItem4 flexCenter>
                            <InputLabel htmlFor={'target_hobby'}>
                              対象趣味
                            </InputLabel>
                          </GridItem4>
                          <GridItem8>
                            <CheckBoxInputV
                              defaultValue={
                                dataEventList.events_target_hobby === null
                                  ? undefined
                                  : dataEventList.events_target_hobby
                                      .split(',')
                                      .map(Number)
                              }
                              name={'target_hobby'}
                              showLabel={true}
                              dataCheck={[
                                { name: '読書', value: 1 },
                                { name: '鑑賞', value: 2 },
                                { name: '旅行', value: 3 },
                                { name: 'スポーツ', value: 4 },
                                {
                                  name: '自然・アウトドア',
                                  value: 5,
                                },
                                { name: 'ゲーム', value: 6 },
                                { name: 'DIY', value: 7 },
                                { name: 'ガーデニング', value: 8 },
                                { name: '飲食', value: 9 },
                                { name: 'アート', value: 10 },
                                { name: 'その他', value: 99 },
                              ]}
                            />
                          </GridItem8>
                        </GridContainer>
                        {/* event_hashtag */}
                        <GridContainer>
                          <GridItem4>
                            <InputLabel htmlFor={'event_hashtag'}>
                              ハッシュタグ
                            </InputLabel>
                          </GridItem4>
                          <GridItem8>
                            <Box70
                              flexDirection={'row'}
                              display={'flex'}
                              alignItems={'flex-end'}
                            >
                              <TextInput
                                name={'event_hashtag'}
                                defaultValue={dataEventList.events_hashtag}
                                labelName={'ハッシュタグ'}
                                showLabel={false}
                                inputProps={{ readOnly: true }}
                              />
                              <Typography
                                color={'red'}
                                width={'100%'}
                                marginLeft={1}
                              >
                                ※ 第１フェーズでは編集できない状態
                              </Typography>
                            </Box70>
                          </GridItem8>
                        </GridContainer>
                        {/* event_period_start */}
                        <GridContainer>
                          <GridItem4>
                            <InputLabel htmlFor={'event_period_start'}>
                              イベント開始日
                            </InputLabel>
                          </GridItem4>
                          <GridItem8>
                            <Box35>
                              <DateInput
                                name={'event_period_start'}
                                labelName={'イベント開始日'}
                                showLabel={false}
                                defaultValue={dataEventList.events_period_start}
                                type={'date'}
                                inputFormat={'yyyy/MM/dd'}
                                mask={'____/__/__'}
                              />
                            </Box35>
                          </GridItem8>
                        </GridContainer>
                        {/* event_period_end */}
                        <GridContainer>
                          <GridItem4>
                            <InputLabel htmlFor={'event_period_end'}>
                              イベント終了日
                            </InputLabel>
                          </GridItem4>
                          <GridItem8>
                            <Box35>
                              <DateInput
                                name={'event_period_end'}
                                labelName={'イベント終了日'}
                                showLabel={false}
                                defaultValue={dataEventList.events_period_end}
                                type={'date'}
                                inputFormat={'yyyy/MM/dd'}
                                mask={'____/__/__'}
                              />
                            </Box35>
                          </GridItem8>
                        </GridContainer>
                        {/* event_daily_time_start */}
                        <GridContainer>
                          <GridItem4>
                            <InputLabel htmlFor={'event_daily_time_start'}>
                              イベント間隔開始時間
                            </InputLabel>
                          </GridItem4>
                          <GridItem8>
                            <Box35>
                              <DateInput
                                name={'event_daily_time_start'}
                                labelName={'イベント間隔開始時間'}
                                showLabel={false}
                                defaultValue={
                                  dataEventList.events_period_start +
                                  ' ' +
                                  dataEventList.events_daily_time_start
                                }
                                type={'time'}
                                inputFormat={'HH:mm:ss'}
                                mask={'__:__:__'}
                              />
                            </Box35>
                          </GridItem8>
                        </GridContainer>
                        {/* event_daily_time_end */}
                        <GridContainer>
                          <GridItem4>
                            <InputLabel htmlFor={'event_daily_time_end'}>
                              イベント間隔終了時間
                            </InputLabel>
                          </GridItem4>
                          <GridItem8>
                            <Box35>
                              <DateInput
                                name={'event_daily_time_end'}
                                labelName={'イベント間隔終了時間'}
                                showLabel={false}
                                defaultValue={
                                  dataEventList.events_period_end +
                                  ' ' +
                                  dataEventList.events_daily_time_end
                                }
                                type={'time'}
                                inputFormat={'HH:mm:ss'}
                                mask={'__:__:__'}
                              />
                            </Box35>
                          </GridItem8>
                        </GridContainer>
                        {/* event_action_interval */}
                        <GridContainer>
                          <GridItem4>
                            <InputLabel htmlFor={'event_action_interval'}>
                              イベント間隔
                            </InputLabel>
                          </GridItem4>
                          <GridItem8>
                            <Box70
                              flexDirection={'row'}
                              display={'flex'}
                              alignItems={'flex-end'}
                            >
                              <SelectInput
                                name={'event_action_interval'}
                                labelName={''}
                                showLabel={false}
                                defaultValue={
                                  dataEventList.events_action_interval !== null
                                    ? dataEventList.events_action_interval
                                    : 3
                                }
                                options={[
                                  { name: '1回限り', value: 0 },
                                  { name: '時間毎', value: 1 },
                                  { name: '日毎', value: 2 },
                                  { name: '週間毎', value: 3 },
                                  { name: '月毎', value: 4 },
                                ]}
                              />
                              <Typography
                                color={'#0000FF'}
                                width={'100%'}
                                marginLeft={1}
                              >
                                {'1回限り/1時間毎/1日毎/1週間毎/1ヶ月毎'}
                              </Typography>
                            </Box70>
                          </GridItem8>
                        </GridContainer>
                        {/* event_suspended */}
                        <GridContainer>
                          <GridItem4>
                            <InputLabel htmlFor={'event_suspended'}>
                              イベント有効フラグ
                            </InputLabel>
                          </GridItem4>
                          <GridItem8>
                            <Box35>
                              <SelectInput
                                name={'event_suspended'}
                                labelName={''}
                                showLabel={false}
                                defaultValue={dataEventList.events_suspended}
                                options={[
                                  { name: '有効', value: 0 },
                                  { name: '無効', value: 1 },
                                ]}
                              />
                            </Box35>
                          </GridItem8>
                        </GridContainer>
                        {/* event_banner_prob */}
                        <GridContainer>
                          <GridItem4>
                            <InputLabel htmlFor={'event_banner_prob'}>
                            フィルター前表示確率
                            </InputLabel>
                          </GridItem4>
                          <GridItem8>
                            <Box35>
                              <TextInput
                                name={'event_banner_prob'}
                                type={'number'}
                                labelName={'ハッシュタグ'}
                                defaultValue={dataEventList.events_banner_prob}
                                showLabel={false}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      %
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Box35>
                          </GridItem8>
                        </GridContainer>
                        {/* show_cost */}
                        <GridContainer>
                          <GridItem4>
                            <InputLabel htmlFor={'event_show_cost'}>
                              イベント表示コスト
                              <Typography variant={'body2'}>
                                ※数字の大きいイベントが優先的に表示されます。
                              </Typography>
                            </InputLabel>
                          </GridItem4>
                          <GridItem8>
                            <Box35>
                              <TextInput
                                name={'event_show_cost'}
                                labelName={''}
                                defaultValue={dataEventList.events_show_cost}
                                showLabel={false}
                                type={'number'}
                              />
                            </Box35>
                          </GridItem8>
                        </GridContainer>
                        {/* point_expire_date_type */}
                        <GridContainer>
                          <GridItem4>
                            <InputLabel htmlFor={'expire_type'}>
                              ポイント有効期限種類
                            </InputLabel>
                          </GridItem4>
                          <GridItem8>
                            <Box35>
                              <SelectInput
                                name={'expire_type'}
                                labelName={''}
                                showLabel={false}
                                defaultValue={
                                  dataEventList.events_expire_type
                                    ? dataEventList.events_expire_type
                                    : 2
                                }
                                options={[
                                  { name: '取得日ベース', value: 1 },
                                  { name: '利用日ベース', value: 2 },
                                  { name: 'なし', value: 3 },
                                ]}
                              />
                            </Box35>
                          </GridItem8>
                        </GridContainer>
                        {/* point_expire_date */}
                        <GridContainer>
                          <GridItem4>
                            <InputLabel htmlFor={'point_expire_date'}>
                              ポイント有効期限
                            </InputLabel>
                          </GridItem4>
                          <GridItem8>
                            <Box35>
                              <TextInput
                                name={'point_expire_date'}
                                labelName={'ポイント有効期限'}
                                defaultValue={
                                  dataEventList.events_point_expire_date
                                }
                                showLabel={false}
                                type={'number'}
                                inputProps={{ min: 0 }}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      日
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Box35>
                          </GridItem8>
                        </GridContainer>
                        {/* event_type */}
                        <GridContainer>
                          <GridItem4>
                            <InputLabel htmlFor={'event_type'}>
                              イベント種類
                            </InputLabel>
                          </GridItem4>
                          <GridItem8>
                            <Box35>
                              <SelectInput
                                defaultValue={dataEventList.events_type}
                                labelName={''}
                                showLabel={false}
                                name={'event_type'}
                                options={[
                                  {
                                    name: '任意ポイントイベントであるか',
                                    value: 1,
                                  },
                                  { name: 'ガチャイベント', value: 2 },
                                  { name: 'フォームイベント', value: 3 },
                                  { name: '招待イベント', value: 4 },
                                  { name: 'バナー表示のみ', value: 5 },
                                  { name: 'モーダル表示のみ', value: 6 },
                                ]}
                              />
                            </Box35>
                          </GridItem8>
                        </GridContainer>
                      </Stack>
                    </LBox>
                    {eventForm === 1 ? (
                      <PointForm
                        handleOpenModal={handleOpenModal}
                        modalOptions={modalOptions}
                        pointRuleOptions={pointRuleOptions}
                        bannerOptions={bannerOptions}
                        editData={dataEventList}
                      />
                    ) : eventForm === 2 ? (
                      <GachaForm
                        handleOpenModal={handleOpenModal}
                        handleEditModal={handleEditModal}
                        modalOptions={modalOptions}
                        bannerOptions={bannerOptions}
                        pointRuleOptions={pointRuleOptions}
                        editData={dataEventList}
                      />
                    ) : eventForm === 3 ? (
                      <FormDetail
                        handleOpenModal={handleOpenModal}
                        modalOptions={modalOptions}
                        bannerOptions={bannerOptions}
                        pointRuleOptions={pointRuleOptions}
                        editData={dataEventList}
                      />
                    ) : eventForm === 4 ? (
                      <InvitationForm
                        handleOpenModal={handleOpenModal}
                        handleEditModal={handleEditModal}
                        editData={dataEventList}
                      />
                    ) : eventForm === 5 ? (
                      <BannerForm
                        bannerOptions={bannerOptions}
                        handleOpenModal={handleOpenModal}
                        editData={dataEventList}
                      />
                    ) : eventForm === 6 ? (
                      <ModalForm
                        modalOptions={modalOptions}
                        handleOpenModal={handleOpenModal}
                        editData={dataEventList}
                      />
                    ) : (
                      ''
                    )}
                    <Stack mt={4} alignItems={'center'}>
                      <Button type={'submit'} variant={'contained'}>
                        設定完了
                      </Button>
                    </Stack>
                  </form>
                </FormProvider>
              )
            )}
          </DivWrap>
        </Box>
        <RouteModal addForm={<RealAddForm />} editForm={<RealEditForm />} />
      </Layout>
    </EventContext.Provider>
  );
}
