import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from './baseQuery';

export interface IAdminUserResponse {
  id: number;
  name: string;
  mail: string;
  last_login_at?: string;
  deleted_flag: boolean;
  deleted_at?: string;
  created_at?: string;
}

export const adminUserApi = createApi({
  reducerPath: 'admin_user_api',
  tagTypes: ['adminUser'],
  baseQuery: axiosBaseQuery(),
  endpoints: build => ({
    getAdminUsers: build.query<IAdminUserResponse[], void>({
      query: () => ({
        url: `/admin_users`,
        method: 'GET',
      }),
      providesTags: ['adminUser'],
      transformResponse: (result: { data: IAdminUserResponse[] }) => {
        return result.data;
      },
    }),
    createAdminUser: build.mutation({
      query: body => ({
        url: '/admin_users/create',
        method: 'POST',
        data: body,
      }),
      invalidatesTags: ['adminUser'],
    }),
    updateAdminUser: build.mutation({
      query: ({ id, body }) => ({
        url: `/admin_users/${id}`,
        method: 'PUT',
        data: body,
      }),
      invalidatesTags: ['adminUser'],
    }),
    deleteAdminUser: build.mutation({
      query: id => ({
        url: `/admin_users/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['adminUser'],
    }),
  }),
});

export const {
  useGetAdminUsersQuery,
  useCreateAdminUserMutation,
  useDeleteAdminUserMutation,
  useUpdateAdminUserMutation,
} = adminUserApi;
