import React, { useEffect } from 'react';
import { DialogActions, DialogContent } from '@mui/material';
import Button from '@mui/material/Button';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { SelectCommonStateDataAttach } from '../../../store/commonSlice/selectors';
import { useCommonSlice } from '../../../store/commonSlice';

const DeleteForm = ({ action }) => {
  const [
    deleteAction,
    {
      isLoading: deleteLoading,
      isError: isDeleteError,
      isSuccess: isDeleteSuccess,
      error,
    },
  ] = action();
  const { actions } = useCommonSlice();
  const dispatch = useDispatch();
  const dataAttach = useSelector(SelectCommonStateDataAttach);
  useEffect(() => {
    if (isDeleteSuccess) {
      toast.success('Deleted successfully', {
        position: 'bottom-left',
      });
      dispatch(actions.closeModal());
    }
    if (isDeleteError) {
      toast.error((error as any).data, {
        position: 'bottom-left',
      });
      dispatch(actions.closeModal());
    }
  }, [deleteLoading, isDeleteError, isDeleteSuccess]);
  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        deleteAction(dataAttach);
      }}
    >
      <DialogContent dividers>Delete Item ID : {dataAttach}</DialogContent>
      <DialogActions>
        <Button
          autoFocus
          variant={'outlined'}
          onClick={() => dispatch(actions.closeModal())}
        >
          Cancel
        </Button>
        <Button color={'error'} variant={'contained'} type={'submit'}>
          Confirm
        </Button>
      </DialogActions>
    </form>
  );
};

export default DeleteForm;
