/**
 *
 * Asynchronously loads the component for ExportPage
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ExportPage = lazyLoad(
  () => import('./index'),
  module => module.ExportPage,
);
