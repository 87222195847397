import React, { useState } from 'react';
import { useSubmitMutation, useSubmitTelMutation } from '../../../services/smsauthApi'; 
import { Layout } from '../../components/Layout';
import { ToolBar } from '../../components/ToolBar';

export const ModifyTelInputCountPage: React.FC = () => {
    const [userId, setUserId] = useState<string>('');
    const [count, setCount] = useState<string>('');

    const [telUserId, setTelUserId] = useState<string>('');
    const [telNumber, setTelNumber] = useState<string>('');

    // Use the mutation hook once
    const [submitData, { isLoading, isError }] = useSubmitMutation();
    const [submitTelData, { isLoading: isTelLoading, isError: isTelError }] = useSubmitTelMutation();

    const handleSubmit = async () => {
        if (userId && count) {
            await submitData({ userId: userId, count});

            if (isError) {
                window.alert('更新失敗');
            } else {
                window.alert('更新リクエスト成功発送しました');
            }
        } else {
            window.alert('ユーザーIDと回数両方入力する必要がある!');
        }
    };

    const handleTelSubmit = async () => {
        if (telUserId && telNumber) {
            await submitTelData({ userId: telUserId, telNumber });

            if (isTelError) {
                window.alert('更新失敗');
            } else {
                window.alert('更新リクエスト成功発送しました！');
            }
        } else {
            window.alert('ユーザーIDと電話番号両方入力する必要がある!');
        }
    };


    return (
        <Layout title="SMS認証関連">
        <ToolBar
          title="SMS認証関連"
          noSearchPanel={true}
          noAddButton={true}
          noFilterPanel={true}
        />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
            <a>ユーザーIDわからない場合、http://a.omega-poikey.com/user-point　のページで、
                メールを選択し、ユーザーさんが登録したメールを入力して、UID/デバイスIDをユーザーIDとして下に入力。</a> 
            <h2>認証回数上限解除、または電話番号変更UI表示させ</h2>
                <a>SMS認証の回数上限は暗黙的に5回です。特定のユーザーの回数を変更したい場合は、ユーザーIDを入力し、10以外の数字（例えば9）を入力してください。</a> {}
                <a>特定のユーザーに対して、アプリ内の「会員情報変更」画面で電話番号変更UIを表示させたい場合は、ユーザーIDを入力し、回数を10として入力してください。この場合、ユーザーさんが、一回（一回のみ）の電話番号変更するチャンスが与えられる</a> {}
                <a>全て入力終わったら、確定ボタンを押してください。</a> {}
            
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <input 
                    type="number" 
                    placeholder="ユーザーID" 
                    value={userId} 
                    onChange={e => setUserId(e.target.value)}
                />
                <input 
                    type="number" 
                    placeholder="回数" 
                    value={count} 
                    onChange={e => setCount(e.target.value)}
                />
                <button onClick={handleSubmit}>確定</button>
            </div>

            <h2>管理者手動電話番号登録/更新</h2>
            <a>特定のユーザーさんに対して、電話番号を登録/更新したい場合以下を使う、フォーマット厳守：</a>
            <a>日本の電話番号は「+81」で始まり、プラス先頭の0を除いた10桁です。</a>
            <a>正しい例：＋8190XXXXXXXX</a>
            <a>ダメな例：＋81090XXXXXXXX</a>
            <a>ダメな例：8190XXXXXXXX</a>
            <a>ダメな例：090XXXXXXXX</a>
            <a>ダメな例：90XXXXXXXX</a>
            
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                <input 
                    type="number" 
                    placeholder="ユーザーID" 
                    value={telUserId} 
                    onChange={e => setTelUserId(e.target.value)}
                />
                <input 
                    type="tel" 
                    placeholder="電話番号" 
                    value={telNumber} 
                    onChange={e => setTelNumber(e.target.value)}
                />
                <button onClick={handleTelSubmit}>電話番号を登録/更新</button>
            </div>
        </div>
        </Layout>
    );
}



export default ModifyTelInputCountPage;
