import React, { useMemo } from 'react';
import {
  GridContainer,
  GridItem4,
  GridItem8,
  LBox,
  TitlePink,
} from '../../../components/Common/common';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import SelectImage from '../../../components/Form/SelectImage';
import { Box } from '@mui/material';
import SelectInput from '../../../components/Form/SelectInput';
import QuickAddButton from '../../../components/Button/QuickAddButton';
import { useGetFormModalBannerPointQuery } from '../../../../services/eventApi';

const FormDetail = ({
  handleOpenModal,
  modalOptions,
  pointRuleOptions,
  bannerOptions,
  editData,
}) => {
  const { data: formModalPointBannerData } = useGetFormModalBannerPointQuery();

  const formOptions = useMemo(() => {
    if (
      formModalPointBannerData &&
      formModalPointBannerData.forms_id.length > 0
    ) {
      const nData: any = [];
      formModalPointBannerData.forms_id.map((item, index) => {
        nData.push({
          name: formModalPointBannerData.forms_title[index],
          value: item,
        });
      });
      return nData;
    } else {
      return [];
    }
  }, [formModalPointBannerData]);

  return (
    <LBox mt={5}>
      <TitlePink>フォーム詳細設定</TitlePink>
      <Stack mt={4} p={4} rowGap={{ md: 2, xs: 5 }}>
        {/* form */}
        <GridContainer>
          <GridItem4 flexCenter={true}>
            <InputLabel htmlFor={'form_id'}>表示フォーム</InputLabel>
          </GridItem4>
          <GridItem8>
            <Stack flexDirection={'row'} alignItems={'center'} columnGap={10}>
              <Box width={'40%'}>
                <SelectInput
                  name={'form_id'}
                  labelName={''}
                  showLabel={false}
                  defaultValue={editData.forms_id}
                  options={formOptions}
                />
              </Box>
              <Box width={'30px'}>&nbsp;</Box>
              <Box width={'30%'}>&nbsp;</Box>
            </Stack>
          </GridItem8>
        </GridContainer>
        {/* banner */}
        <GridContainer>
          <GridItem4 flexCenter={true}>
            <InputLabel htmlFor={'banner_id'}>使用バナー</InputLabel>
          </GridItem4>
          <GridItem8>
            <SelectImage
              name={'banner_id'}
              labelName={''}
              showLabel={false}
              options={bannerOptions}
              defaultValue={editData.banners_id}
              numOfAddForm={2}
              handleOpenModal={handleOpenModal}
            />
          </GridItem8>
        </GridContainer>
        {/* modal */}
        <GridContainer>
          <GridItem4 flexCenter={true}>
            <InputLabel htmlFor={'modal_id'}>使用モーダル</InputLabel>
          </GridItem4>
          <GridItem8>
            <SelectImage
              name={'modal_id'}
              labelName={''}
              showLabel={false}
              defaultValue={editData.modals_id}
              options={modalOptions}
              numOfAddForm={3}
              handleOpenModal={handleOpenModal}
            />
          </GridItem8>
        </GridContainer>
        {/* point */}
        <GridContainer>
          <GridItem4 flexCenter={true}>
            <InputLabel htmlFor={'point'}>使用ポイントルール</InputLabel>
          </GridItem4>
          <GridItem8>
            <Stack flexDirection={'row'} alignItems={'center'} columnGap={10}>
              <Box width={'40%'} overflow={'hidden'}>
                <SelectInput
                  name={'point_rule_id'}
                  labelName={''}
                  showLabel={false}
                  defaultValue={editData.point_rules_id}
                  options={pointRuleOptions}
                />
              </Box>
              <Box>
                <QuickAddButton onClick={() => handleOpenModal(4)} />
              </Box>
              <Box width={'30%'}>&nbsp;</Box>
            </Stack>
          </GridItem8>
        </GridContainer>
      </Stack>
    </LBox>
  );
};

export default FormDetail;
